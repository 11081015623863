import { SideNavigation } from '@cloudscape-design/components';

export default function BaseSideNav( props ) {
    return (
        <SideNavigation
            header={props.header}
            items={props.items}
            activeHref={props.activeHref}
        />
    );
}
