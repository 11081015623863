import React, { useState } from 'react';
import AppLayout from '../../../components/layout/AppLayout';
import AppContentLayout from '../../../components/layout/ContentLayout';
import {Header, Button, Link, KeyValuePairs, FormField, Select, Container} from "@cloudscape-design/components";
import MonitoringSideNav from "../../../components/navigation/MonitoringSideNavigation";
import LineChart from "@cloudscape-design/components/line-chart";
import DateRangePicker from "@cloudscape-design/components/date-range-picker";
import Board from "@cloudscape-design/board-components/board";
import BoardItem from "@cloudscape-design/board-components/board-item";
import Box from "@cloudscape-design/components/box";
import SpaceBetween from "@cloudscape-design/components/space-between";

export default function Dashboards() {
    const pipelineOptions = [
        { label: 'youtube_evaluation_daily', value: 'youtube_evaluation_daily' },
        { label: 'hr_operation_pipeline_1', value: 'hr_operation_pipeline_1' },
        { label: 'hr_all_eval_ver1.2', value: 'hr_all_eval_ver1_2' },
    ];

    const [activeHref, setActiveHref] = useState('/evaluation/dashboards');
    const [dateRange, setDateRange] = useState({
        type: 'relative',
        key: 'previous-24-hours',
        amount: 24,
        unit: 'hour',
    });
    const [selectedPipeline, setSelectedPipeline] = useState(pipelineOptions[0]);
    const handleFollow = (event) => {
        if (!event.detail.external) {
            event.preventDefault();
            setActiveHref(event.detail.href);
        }
    };

    // Mock data for each pipeline
    const pipelineData = {
        youtube_evaluation_daily: {
            contextRecall: generateMockData(0.72, 0.75, 20),
            contextPrecision: generateMockData(0.82, 0.81, 20),
            factuality: generateMockData(0.82, 0.84, 20),
            ndcg5: generateMockData(0.78, 0.79, 20),
            ndcg10: generateMockData(0.79, 0.80, 20),
            ndcg15: generateMockData(0.80, 0.81, 20),
            recall5: generateMockData(0.81, 0.83, 20),
            recall10: generateMockData(0.83, 0.85, 20),
            recall15: generateMockData(0.85, 0.87, 20),
        },
        hr_operation_pipeline_1: {
            contextRecall: generateMockData(0.7, 0.8, 20),
            contextPrecision: generateMockData(0.8, 0.8, 20),
            factuality: generateMockData(0.8, 0.9, 20),
            ndcg5: generateMockData(0.78, 0.79, 20),
            ndcg10: generateMockData(0.79, 0.80, 20),
            ndcg15: generateMockData(0.80, 0.81, 20),
            recall5: generateMockData(0.81, 0.83, 20),
            recall10: generateMockData(0.83, 0.85, 20),
            recall15: generateMockData(0.85, 0.87, 20),
        },
        hr_all_eval_ver1_2: {
            contextRecall: generateMockData(0.84, 0.86, 20),
            contextPrecision: generateMockData(0.85, 0.85, 20),
            factuality: generateMockData(0.91, 0.93, 20),
            ndcg5: generateMockData(0.78, 0.79, 20),
            ndcg10: generateMockData(0.79, 0.80, 20),
            ndcg15: generateMockData(0.80, 0.81, 20),
            recall5: generateMockData(0.81, 0.83, 20),
            recall10: generateMockData(0.83, 0.85, 20),
            recall15: generateMockData(0.85, 0.87, 20),
        },
    };

    // Function to generate mock data
    function generateMockData(minValue, maxValue, count) {
        const data = [];
        const startDate = new Date(1722172376000);
        const interval = 3600000; // 15 minutes in milliseconds

        for (let i = 0; i < count; i++) {
            const value = minValue + (maxValue - minValue) * (i / (count - 1));
            const date = new Date(startDate.getTime() + i * interval);
            data.push({ x: date, y: parseFloat(value.toFixed(2)) });
        }

        return data;
    }

    // Function to calculate the average of an array
    function calculateAverage(data) {
        const sum = data.reduce((acc, item) => acc + item.y, 0);
        return (sum / data.length).toFixed(2);
    }

    // Get the data for the selected pipeline
    const { contextRecall, contextPrecision, factuality, ndcg5, ndcg10, ndcg15, recall5, recall10, recall15 } = pipelineData[selectedPipeline.value];

    // Calculate the average values for each metric
    const contextRecallAvg = calculateAverage(contextRecall);
    const contextPrecisionAvg = calculateAverage(contextPrecision);
    const factualityAvg = calculateAverage(factuality);
    const ndcg5Avg = calculateAverage(ndcg5);
    const ndcg10Avg = calculateAverage(ndcg10);
    const ndcg15Avg = calculateAverage(ndcg15);
    const recall5Avg = calculateAverage(recall5);
    const recall10Avg = calculateAverage(recall10);
    const recall15Avg = calculateAverage(recall15);

    const boardItems = [
        {
            id: "2",
            rowSpan: 2,
            columnSpan: 4,
            data: {
                title: "Average Performances (Context Recall, Context Precision, Factuality)",
                content: (
                    <KeyValuePairs
                        columns={3}
                        items={[
                            {
                                label: 'Context Recall',
                                value: (
                                    <Link variant="awsui-value-large" href="#" ariaLabel={`Context Recall (${contextRecallAvg})`}>
                                        {contextRecallAvg}
                                    </Link>
                                ),
                            },
                            {
                                label: 'Context Precision',
                                value: (
                                    <Link variant="awsui-value-large" href="#" ariaLabel={`Context Precision (${contextPrecisionAvg})`}>
                                        {contextPrecisionAvg}
                                    </Link>
                                ),
                            },
                            {
                                label: 'Factuality',
                                value: (
                                    <Link variant="awsui-value-large" href="#" ariaLabel={`Factuality (${factualityAvg})`}>
                                        {factualityAvg}
                                    </Link>
                                ),
                            },
                        ]}
                    />
                )
            }
        },
        {
            id: "3",
            rowSpan: 5,
            columnSpan: 4,
            data: {
                title: "Performance Trends (Context Recall, Context Precision, Factuality)",
                content: (
                    <LineChart
                        series={[
                            {
                                title: "Context Recall",
                                type: "line",
                                data: contextRecall,
                            },
                            {
                                title: "Context Precision",
                                type: "line",
                                data: contextPrecision,
                            },
                            {
                                title: "Factuality",
                                type: "line",
                                data: factuality,
                            },
                        ]}
                        xDomain={[
                            new Date(1722172376000),
                            new Date(1722172376000 + (20 * 60 * 60 * 1000))
                        ]}
                        yDomain={[0, 1]}
                        i18nStrings={{
                            xTickFormatter: e =>
                                e
                                    .toLocaleDateString("en-US", {
                                        month: "short",
                                        day: "numeric",
                                        hour: "numeric",
                                        minute: "numeric",
                                        hour12: !1
                                    })
                                    .split(",")
                                    .join("\n"),
                            yTickFormatter: e => e.toFixed(2)
                        }}
                        detailPopoverSeriesContent={({ series, x, y }) => ({
                            key: (
                                <Link external="true" href="#">
                                    {series.title}
                                </Link>
                            ),
                            value: y.toFixed(2)
                        })}
                        ariaLabel="Chatbot Evaluation Metrics"
                        height={300}
                        hideFilter
                        xScaleType="time"
                        xTitle="Time"
                        yTitle="Score"
                        empty={
                            <Box textAlign="center" color="inherit">
                                <b>No data available</b>
                                <Box variant="p" color="inherit">
                                    There is no data available
                                </Box>
                            </Box>
                        }
                        noMatch={
                            <Box textAlign="center" color="inherit">
                                <b>No matching data</b>
                                <Box variant="p" color="inherit">
                                    There is no matching data to display
                                </Box>
                                <Button>Clear filter</Button>
                            </Box>
                        }
                    />
                )
            }
        },
        {
            id: "4",
            rowSpan: 3,
            columnSpan: 4,
            data: {
                title: "Ranking Metric : NDCG",
                content: (
                    <KeyValuePairs
                        columns={3}
                        items={[
                            {
                                label: 'NDCG@5',
                                value: (
                                    <Link variant="awsui-value-large" href="#" ariaLabel={`NDCG@5 (${ndcg5Avg})`}>
                                        {ndcg5Avg}
                                    </Link>
                                ),
                            },
                            {
                                label: 'NDCG@10',
                                value: (
                                    <Link variant="awsui-value-large" href="#" ariaLabel={`NDCG@10 (${ndcg10Avg})`}>
                                        {ndcg10Avg}
                                    </Link>
                                ),
                            },
                            {
                                label: 'NDCG@15',
                                value: (
                                    <Link variant="awsui-value-large" href="#" ariaLabel={`NDCG@15 (${ndcg15Avg})`}>
                                        {ndcg15Avg}
                                    </Link>
                                ),
                            },
                            {
                                label: 'Recall@5',
                                value: (
                                    <Link variant="awsui-value-large" href="#" ariaLabel={`Recall@5 (${recall5Avg})`}>
                                        {recall5Avg}
                                    </Link>
                                ),
                            },
                            {
                                label: 'Recall@10',
                                value: (
                                    <Link variant="awsui-value-large" href="#" ariaLabel={`Recall@10 (${recall10Avg})`}>
                                        {recall10Avg}
                                    </Link>
                                ),
                            },
                            {
                                label: 'Recall@15',
                                value: (
                                    <Link variant="awsui-value-large" href="#" ariaLabel={`Recall@15 (${recall15Avg})`}>
                                        {recall15Avg}
                                    </Link>
                                ),
                            },
                        ]}
                    />
                )
            }
        },
        {
            id: "5",
            rowSpan: 5,
            columnSpan: 2,
            data: {
                title: "Ranking Metric : NDCG Trends",
                content: (
                    <LineChart
                        series={[
                            {
                                title: "NDCG@5",
                                type: "line",
                                data: ndcg5,
                            },
                            {
                                title: "NDCG@10",
                                type: "line",
                                data: ndcg10,
                            },
                            {
                                title: "NDCG@15",
                                type: "line",
                                data: ndcg15,
                            },
                        ]}
                        xDomain={[
                            new Date(1722172376000),
                            new Date(1722172376000 + (20 * 60 * 60 * 1000))
                        ]}
                        yDomain={[0, 1]}
                        i18nStrings={{
                            xTickFormatter: e =>
                                e
                                    .toLocaleDateString("en-US", {
                                        month: "short",
                                        day: "numeric",
                                        hour: "numeric",
                                        minute: "numeric",
                                        hour12: !1
                                    })
                                    .split(",")
                                    .join("\n"),
                            yTickFormatter: e => e.toFixed(2)
                        }}
                        detailPopoverSeriesContent={({ series, x, y }) => ({
                            key: (
                                <Link external="true" href="#">
                                    {series.title}
                                </Link>
                            ),
                            value: y.toFixed(2)
                        })}
                        ariaLabel="Chatbot Evaluation Metrics"
                        height={300}
                        hideFilter
                        xScaleType="time"
                        xTitle="Time"
                        yTitle="Score"
                        empty={
                            <Box textAlign="center" color="inherit">
                                <b>No data available</b>
                                <Box variant="p" color="inherit">
                                    There is no data available
                                </Box>
                            </Box>
                        }
                        noMatch={
                            <Box textAlign="center" color="inherit">
                                <b>No matching data</b>
                                <Box variant="p" color="inherit">
                                    There is no matching data to display
                                </Box>
                                <Button>Clear filter</Button>
                            </Box>
                        }
                    />
                )
            }
        },
        {
            id: "7",
            rowSpan: 5,
            columnSpan: 2,
            data: {
                title: "Ranking Metric : Recall Trends",
                content: (
                    <LineChart
                        series={[
                            {
                                title: "Recall@5",
                                type: "line",
                                data: recall5,
                            },
                            {
                                title: "Recall@10",
                                type: "line",
                                data: recall10,
                            },
                            {
                                title: "Recall@15",
                                type: "line",
                                data: recall15,
                            },
                        ]}
                        xDomain={[
                            new Date(1722172376000),
                            new Date(1722172376000 + (20 * 60 * 60 * 1000))
                        ]}
                        yDomain={[0, 1]}
                        i18nStrings={{
                            xTickFormatter: e =>
                                e
                                    .toLocaleDateString("en-US", {
                                        month: "short",
                                        day: "numeric",
                                        hour: "numeric",
                                        minute: "numeric",
                                        hour12: !1
                                    })
                                    .split(",")
                                    .join("\n"),
                            yTickFormatter: e => e.toFixed(2)
                        }}
                        detailPopoverSeriesContent={({ series, x, y }) => ({
                            key: (
                                <Link external="true" href="#">
                                    {series.title}
                                </Link>
                            ),
                            value: y.toFixed(2)
                        })}
                        ariaLabel="Chatbot Evaluation Metrics"
                        height={300}
                        hideFilter
                        xScaleType="time"
                        xTitle="Time"
                        yTitle="Score"
                        empty={
                            <Box textAlign="center" color="inherit">
                                <b>No data available</b>
                                <Box variant="p" color="inherit">
                                    There is no data available
                                </Box>
                            </Box>
                        }
                        noMatch={
                            <Box textAlign="center" color="inherit">
                                <b>No matching data</b>
                                <Box variant="p" color="inherit">
                                    There is no matching data to display
                                </Box>
                                <Button>Clear filter</Button>
                            </Box>
                        }
                    />
                )
            }
        },
    ];

    return (
        <AppLayout
            breadcrumbs_props={{
                items: [
                    { text: 'Evaluation', href: '/evaluation/chatbot' },
                    { text: 'Dashboards', href: '/evaluation/dashboards' },
                ]
            }}
            side_nav={
                <MonitoringSideNav
                    activeHref={activeHref}
                    onFollow={handleFollow}>
                </MonitoringSideNav>}
        >
            <AppContentLayout
                header_layout_props={{
                    text: "Dashboards",
                    description: "Comprehensive overview of chatbot performance metrics and analytics."
                }}
            >
                <SpaceBetween size="l">
                    <Container
                        header={
                            <Header variant="h2" description="Configure and optimize the chatbot's processing and response generation components.">
                                Pipeline Settings
                            </Header>
                        }
                    >
                        <SpaceBetween size="l">
                            <FormField label="Select Pipeline">
                                <Select
                                    options={pipelineOptions}
                                    selectedOption={selectedPipeline}
                                    onChange={({ detail }) => setSelectedPipeline(detail.selectedOption)}
                                />
                            </FormField>
                            <FormField label="Date Range">
                                <DateRangePicker
                                    onChange={({ detail }) => setDateRange(detail.value)}
                                    value={dateRange}
                                    relativeOptions={[
                                        {
                                            key: "previous-5-minutes",
                                            amount: 5,
                                            unit: "minute",
                                            type: "relative"
                                        },
                                        {
                                            key: "previous-30-minutes",
                                            amount: 30,
                                            unit: "minute",
                                            type: "relative"
                                        },
                                        {
                                            key: "previous-1-hour",
                                            amount: 1,
                                            unit: "hour",
                                            type: "relative"
                                        },
                                        {
                                            key: "previous-6-hours",
                                            amount: 6,
                                            unit: "hour",
                                            type: "relative"
                                        },
                                        {
                                            key: "previous-6-hours",
                                            amount: 12,
                                            unit: "hour",
                                            type: "relative"
                                        },
                                        {
                                            key: "previous-24-hours",
                                            amount: 24,
                                            unit: "hour",
                                            type: "relative"
                                        }
                                    ]}
                                    isValidRange={range => {
                                        if (range.type === "absolute") {
                                            const [
                                                startDateWithoutTime
                                            ] = range.startDate.split("T");
                                            const [
                                                endDateWithoutTime
                                            ] = range.endDate.split("T");
                                            if (
                                                !startDateWithoutTime ||
                                                !endDateWithoutTime
                                            ) {
                                                return {
                                                    valid: false,
                                                    errorMessage:
                                                        "The selected date range is incomplete. Select a start and end date for the date range."
                                                };
                                            }
                                            if (
                                                new Date(range.startDate) -
                                                new Date(range.endDate) >
                                                0
                                            ) {
                                                return {
                                                    valid: false,
                                                    errorMessage:
                                                        "The selected date range is invalid. The start date must be before the end date."
                                                };
                                            }
                                        }
                                        return { valid: true };
                                    }}
                                    i18nStrings={{}}
                                    placeholder="Filter by a date and time range"
                                />
                            </FormField>
                        </SpaceBetween>
                    </Container>

                    <Board
                        renderItem={item => (
                            <BoardItem
                                header={<Header>{item.data.title}</Header>}
                                i18nStrings={{
                                    dragHandleAriaLabel: "Drag handle",
                                    dragHandleAriaDescription:
                                        "Use Space or Enter to activate drag, arrow keys to move, Space or Enter to submit, or Escape to discard.",
                                    resizeHandleAriaLabel: "Resize handle",
                                    resizeHandleAriaDescription:
                                        "Use Space or Enter to activate resize, arrow keys to move, Space or Enter to submit, or Escape to discard."
                                }}
                            >
                                {item.data.content}
                            </BoardItem>
                        )}
                        onItemsChange={event => console.log(event.detail.items)}
                        items={boardItems}
                        i18nStrings={(() => {
                            function createAnnouncement(
                                operationAnnouncement,
                                conflicts,
                                disturbed
                            ) {
                                const conflictsAnnouncement =
                                    conflicts.length > 0
                                        ? `Conflicts with ${conflicts
                                            .map(c => c.data.title)
                                            .join(", ")}.`
                                        : "";
                                const disturbedAnnouncement =
                                    disturbed.length > 0
                                        ? `Disturbed ${disturbed.length} items.`
                                        : "";
                                return [
                                    operationAnnouncement,
                                    conflictsAnnouncement,
                                    disturbedAnnouncement
                                ]
                                    .filter(Boolean)
                                    .join(" ");
                            }
                            return {
                                liveAnnouncementDndStarted: operationType =>
                                    operationType === "resize"
                                        ? "Resizing"
                                        : "Dragging",
                                liveAnnouncementDndItemReordered: operation => {
                                    const columns = `column ${operation.placement
                                        .x + 1}`;
                                    const rows = `row ${operation.placement.y +
                                    1}`;
                                    return createAnnouncement(
                                        `Item moved to ${
                                            operation.direction === "horizontal"
                                                ? columns
                                                : rows
                                        }.`,
                                        operation.conflicts,
                                        operation.disturbed
                                    );
                                },
                                liveAnnouncementDndItemResized: operation => {
                                    const columnsConstraint = operation.isMinimalColumnsReached
                                        ? " (minimal)"
                                        : "";
                                    const rowsConstraint = operation.isMinimalRowsReached
                                        ? " (minimal)"
                                        : "";
                                    const sizeAnnouncement =
                                        operation.direction === "horizontal"
                                            ? `columns ${operation.placement.width}${columnsConstraint}`
                                            : `rows ${operation.placement.height}${rowsConstraint}`;
                                    return createAnnouncement(
                                        `Item resized to ${sizeAnnouncement}.`,
                                        operation.conflicts,
                                        operation.disturbed
                                    );
                                },
                                liveAnnouncementDndItemInserted: operation => {
                                    const columns = `column ${operation.placement
                                        .x + 1}`;
                                    const rows = `row ${operation.placement.y +
                                    1}`;
                                    return createAnnouncement(
                                        `Item inserted to ${columns}, ${rows}.`,
                                        operation.conflicts,
                                        operation.disturbed
                                    );
                                },
                                liveAnnouncementDndCommitted: operationType =>
                                    `${operationType} committed`,
                                liveAnnouncementDndDiscarded: operationType =>
                                    `${operationType} discarded`,
                                liveAnnouncementItemRemoved: op =>
                                    createAnnouncement(
                                        `Removed item ${op.item.data.title}.`,
                                        [],
                                        op.disturbed
                                    ),
                                navigationAriaLabel: "Board navigation",
                                navigationAriaDescription:
                                    "Click on non-empty item to move focus over",
                                navigationItemAriaLabel: item =>
                                    item ? item.data.title : "Empty"
                            };
                        })()}
                    />
                </SpaceBetween>
            </AppContentLayout>
        </AppLayout>
    );
}