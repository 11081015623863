import BaseSideNav from "./BaseSideNavigation";
import {Box, Popover} from "@cloudscape-design/components";

export default function MonitoringSideNav( props ) {
    return (
        <BaseSideNav
            activeHref={props.activeHref}
            header={{
                href: '/evaluation/chatbot',
                text: 'Monitoring',
            }}
            items={[
                {
                    type: "section",
                    text: "Evaluation Pipeline",
                    items: [
                        {
                            type: "link", text: "Dashboards", href: "/evaluation/dashboards"
                        },
                        {
                            type: "link", text: "Pipeline Settings", href: "/evaluation/pipelinesettings"
                        },
                    ]
                },
                {
                    type: "section",
                    text: "Evaluation Dataset",
                    items: [
                        {
                            type: "link", text: "User Defined Dataset", href: "/evaluation/userdataset"
                        },
                        {
                            type: "link",
                            text: "AI Generated Dataset",
                            href: "/evaluation/aidataset",
                            info: (
                                <Box color="text-status-info" variant="span">
                                    <Popover
                                        header="Introducing AI Ranking Model"
                                        size="medium"
                                        triggerType="text"
                                        content={
                                            <>
                                                AWS can schedule events for your instances, such as reboot, stop/start, or retirement.{' '}
                                            </>
                                        }
                                        renderWithPortal={true}
                                        dismissAriaLabel="Close"
                                    >
                                        <Box
                                            variant="span"
                                            color="text-status-info"
                                            fontSize="body-s"
                                            fontWeight="bold"
                                            data-testid="new-feature-announcement-trigger"
                                        >
                                            PRO
                                        </Box>
                                    </Popover>
                                </Box>
                            ),
                        },
                    ]
                },
                {
                    type: "section",
                    text: "Search",
                    items: [
                        {
                            type: "link", text: "Search Queries", href: "https://phoenix-demo.arize.com/projects/UHJvamVjdDoy?selectedSpanNodeId=U3BhbjoyOTg3Mw%3D%3D", external: true
                        }
                    ]
                },
                {
                    type: "section",
                    text: "Observerbility",
                    items: [
                        {
                            type: "link", text: "User Feedbacks", href: "/application/observerbility/userfeedbacks"
                        },
                        {
                            type: "link", text: "Billing and Cost", href: "/application/observerbility/biilingcost"
                        },
                        {
                            type: "link", text: "LangChain Observerbility", href: "https://phoenix-demo.arize.com/projects/UHJvamVjdDoy/traces/2d75e11d78b3f9c8276e042ab2f6e614?selectedSpanNodeId=U3BhbjoyOTg3Mw==", external: true
                        }
                    ]
                },
            ]}
        />
    );
}
