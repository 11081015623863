import { ContentLayout, Header, Link } from '@cloudscape-design/components';

export default function AppContentLayout({ header_layout_props, children }) {
    if (!header_layout_props) {
        return <div>{children}</div>;
    }

    return (
        <ContentLayout
            header={
                <Header
                    variant="h1"
                    description={header_layout_props.description}
                    info={<Link variant="info">Info</Link>}
                >
                    {header_layout_props.text}
                </Header>
            }
        >
            {children}
        </ContentLayout>
    );
}