import AppLayout from '../../../components/layout/AppLayout';
import AppContentLayout from '../../../components/layout/ContentLayout';
import { useState } from 'react';
import {
    Header,
    PieChart,
    Box,
    Button,
    FormField,
    DateRangePicker,
    Table,
    Container,
    BarChart
} from "@cloudscape-design/components";
import Board from "@cloudscape-design/board-components/board";
import BoardItem from "@cloudscape-design/board-components/board-item";
import SpaceBetween from "@cloudscape-design/components/space-between";
import ServiceSideNavigation from "../../../components/navigation/ServiceSideNavigation";
import MonitoringSideNav from "../../../components/navigation/MonitoringSideNavigation";

export default function UserFeedbacks() {
    const [activeHref, setActiveHref] = useState('/application/observerbility/userfeedbacks');
    const [dateRange, setDateRange] = useState({
        type: 'relative',
        key: 'previous-24-hours',
        amount: 24,
        unit: 'hour',
    });

    const handleFollow = (event) => {
        if (!event.detail.external) {
            event.preventDefault();
            setActiveHref(event.detail.href);
        }
    };

    // Mock data for the pie chart
    const feedbackData = [
        {
            title: "Likes",
            value: 128,
            lastUpdate: "2023-06-01"
        },
        {
            title: "Dislikes",
            value: 24,
            lastUpdate: "2023-06-01"
        }
    ];

    const likesData = [
        { fileName: '2023_개정_취업규칙.docx', likes: 25 },
        { fileName: '2022_급여체계안내서.pdf', likes: 20 },
        { fileName: '연차휴가신청서_v2.xlsx', likes: 15 },
        { fileName: '2023_상반기_성과평가지침.pptx', likes: 7 },
        { fileName: '2023_개정_복리후생안내.pdf', likes: 3 },
    ];

    const dislikesData = [
        { fileName: '2023_상반기_성과평가지침.pptx', dislikes: 15 },
        { fileName: '2023_개정_복리후생안내.pdf', dislikes: 10 },
        { fileName: '2022_출장비정산규정.docx', dislikes: 5 },
        { fileName: '직무기술서양식_v3.xlsx', dislikes: 3 },
        { fileName: '2023_인사고과평가표.pdf', dislikes: 1 },
    ];

    const qcData = [
        { query: '휴무 신청 기간', qc: 402, qc_ratio: "8.2%" },
        { query: '정산하는 방법', qc: 281, qc_ratio: "3.8%" },
        { query: '사건 사고 절차', qc: 138, qc_ratio: "1.2%" },
        { query: '약어 질문', qc: 134, qc_ratio: "1.1%" },
        { query: '인사 평가 기준', qc: 89, qc_ratio: "0.9%" },
    ]
    const categoryData = [
        { date: "2024-07-21", HR: 1372, 운영지원: 1121, 시스템문의: 50, 기타: 1168 },
        { date: "2024-07-22", HR: 1329, 운영지원: 1313, 시스템문의: 61, 기타: 172 },
        { date: "2024-07-23", HR: 1331, 운영지원: 1149, 시스템문의: 30, 기타: 1169 },
        { date: "2024-07-24", HR: 1393, 운영지원: 1142, 시스템문의: 52, 기타: 1171 },
        { date: "2024-07-25", HR: 1528, 운영지원: 180, 시스템문의: 111, 기타: 1173 },
        { date: "2024-07-26", HR: 1330, 운영지원: 1152, 시스템문의: 110, 기타: 1167 },
        { date: "2024-07-27", HR: 1452, 운영지원: 1151, 시스템문의: 91, 기타: 1170 },
    ];

    const [items, setItems] = useState([
        {
            id: "11",
            rowSpan: 3,
            columnSpan: 2,
            data: {
                title: "Weekly QC",
                content: (
                    <Table
                        columnDefinitions={[
                            {
                                id: 'query',
                                header: 'Query',
                                cell: item => item.query,
                            },
                            {
                                id: 'qc',
                                header: 'Query Count',
                                cell: item => item.qc,
                            },
                            {
                                id: 'qc_ratio',
                                header: 'Query Ratio',
                                cell: item => item.qc_ratio,
                            },
                        ]}
                        items={qcData}
                    />
                )
            }
        },
        {
            id: "12",
            rowSpan: 6,
            columnSpan: 2,
            data: {
                title: "Weekly Query Categories ",
                content: (
                    <BarChart
                        series={[
                            {
                                title: "HR관련",
                                type: "bar",
                                data: categoryData.map(item => ({ x: item.date, y: item.HR }))
                            },
                            {
                                title: "운영지원",
                                type: "bar",
                                data: categoryData.map(item => ({ x: item.date, y: item.운영지원 }))
                            },
                            {
                                title: "시스템문의",
                                type: "bar",
                                data: categoryData.map(item => ({ x: item.date, y: item.시스템문의 }))
                            },
                            {
                                title: "기타",
                                type: "bar",
                                data: categoryData.map(item => ({ x: item.date, y: item.기타 }))
                            }
                        ]}
                        xDomain={categoryData.map(item => item.date)}
                        yDomain={[0, 5000]}
                        detailPopoverSeriesContent={({ series, x, y }) => ({
                            key: series.title,
                            value: `${y.toFixed(1)}%`
                        })}
                        ariaLabel="Weekly category distribution"
                        height={300}
                        stackedBars
                        xTickFormatter={d =>
                            new Date(d).toLocaleDateString("en-US", {
                                month: "short",
                                day: "numeric"
                            })
                        }
                        xTitle="Date"
                        yTitle="QC"
                        empty={
                            <Box textAlign="center" color="inherit">
                                <b>No data available</b>
                                <Box variant="p" color="inherit">
                                    There is no data available
                                </Box>
                            </Box>
                        }
                        noMatch={
                            <Box textAlign="center" color="inherit">
                                <b>No matching data</b>
                                <Box variant="p" color="inherit">
                                    There is no matching data to display
                                </Box>
                                <Button>Clear filter</Button>
                            </Box>
                        }
                    />
                )
            }
        },
        {
            id: "2",
            rowSpan: 6,
            columnSpan: 2,
            data: {
                title: "User Feedback",
                content: (
                    <PieChart
                        data={feedbackData}
                        detailPopoverContent={(datum, sum) => [
                            { key: "Feedback count", value: datum.value },
                            {
                                key: "Percentage",
                                value: `${((datum.value / sum) * 100).toFixed(0)}%`
                            },
                            { key: "Last update on", value: datum.lastUpdate }
                        ]}
                        segmentDescription={(datum, sum) =>
                            `${datum.value} users, ${((datum.value / sum) * 100).toFixed(0)}%`
                        }
                        ariaDescription="Pie chart showing user feedback distribution."
                        ariaLabel="User Feedback Pie Chart"
                        empty={
                            <Box textAlign="center" color="inherit">
                                <b>No data available</b>
                                <Box variant="p" color="inherit">
                                    There is no data available
                                </Box>
                            </Box>
                        }
                        noMatch={
                            <Box textAlign="center" color="inherit">
                                <b>No matching data</b>
                                <Box variant="p" color="inherit">
                                    There is no matching data to display
                                </Box>
                                <Button>Clear filter</Button>
                            </Box>
                        }
                    />
                )
            }
        },
        {
            id: "3",
            rowSpan: 3,
            columnSpan: 2,
            data: {
                title: "Top Liked Documents",
                content: (
                    <Table
                        columnDefinitions={[
                            {
                                id: 'fileName',
                                header: 'File Name',
                                cell: item => item.fileName,
                            },
                            {
                                id: 'likes',
                                header: 'Likes',
                                cell: item => item.likes,
                            },
                        ]}
                        items={likesData}
                    />
                )
            }
        },
        {
            id: "4",
            rowSpan: 3,
            columnSpan: 2,
            data: {
                title: "Top Disliked Documents",
                content: (
                    <Table
                        columnDefinitions={[
                            {
                                id: 'fileName',
                                header: 'File Name',
                                cell: item => item.fileName,
                            },
                            {
                                id: 'dislikes',
                                header: 'Dislikes',
                                cell: item => item.dislikes,
                            },
                        ]}
                        items={dislikesData}
                    />
                )
            }
        }
    ]);

    return (
        <AppLayout
            breadcrumbs_props={{
                items:[
                    { text: 'Application', href: '/application/observerbility/userfeedbacks' },
                    { text: 'Observerbility', href: '/application/observerbility/userfeedbacks' },
                    { text: 'User Feedbacks', href: '/application/observerbility/userfeedbacks' },
                ]
            }}
            side_nav={
                <MonitoringSideNav
                    activeHref={activeHref}
                    onFollow={handleFollow}>
                </MonitoringSideNav>}
        >
            <AppContentLayout
                header_layout_props={{
                    text: "User Feedbacks",
                    description: "Overview of user feedback and analytics for our services"
                }}
            >
                <SpaceBetween size="l">
                    <Container
                        header={
                            <Header variant="h2" description="Configure feedback settings">
                                Feedback Settings
                            </Header>
                        }
                    >
                        <FormField label="Date Range">
                            <DateRangePicker
                                onChange={({ detail }) => setDateRange(detail.value)}
                                value={dateRange}
                                relativeOptions={[
                                    {
                                        key: "previous-24-hours",
                                        amount: 24,
                                        unit: "hour",
                                        type: "relative"
                                    },
                                    {
                                        key: "previous-7-days",
                                        amount: 7,
                                        unit: "day",
                                        type: "relative"
                                    },
                                    {
                                        key: "previous-30-days",
                                        amount: 30,
                                        unit: "day",
                                        type: "relative"
                                    }
                                ]}
                                i18nStrings={{}}
                                placeholder="Filter by a date and time range"
                            />
                        </FormField>
                    </Container>

                    <Board
                        renderItem={item => (
                            <BoardItem
                                header={<Header variant="h2" description={item.data.description}>{item.data.title}</Header>}
                                i18nStrings={{
                                    dragHandleAriaLabel: "Drag handle",
                                    dragHandleAriaDescription:
                                        "Use Space or Enter to activate drag, arrow keys to move, Space or Enter to submit, or Escape to discard.",
                                    resizeHandleAriaLabel: "Resize handle",
                                    resizeHandleAriaDescription:
                                        "Use Space or Enter to activate resize, arrow keys to move, Space or Enter to submit, or Escape to discard."
                                }}
                            >
                                {item.data.content}
                            </BoardItem>
                        )}
                        onItemsChange={event => setItems(event.detail.items)}
                        items={items}
                        i18nStrings={{
                            liveAnnouncementDndStarted: operationType =>
                                operationType === "resize"
                                    ? "Resizing"
                                    : "Dragging",
                            liveAnnouncementDndItemReordered: operation => {
                                const columns = `column ${operation.placement.x + 1}`;
                                const rows = `row ${operation.placement.y + 1}`;
                                return createAnnouncement(
                                    `Item moved to ${
                                        operation.direction === "horizontal"
                                            ? columns
                                            : rows
                                    }.`,
                                    operation.conflicts,
                                    operation.disturbed
                                );
                            },
                            liveAnnouncementDndItemResized: operation => {
                                const columnsConstraint = operation.isMinimalColumnsReached
                                    ? " (minimal)"
                                    : "";
                                const rowsConstraint = operation.isMinimalRowsReached
                                    ? " (minimal)"
                                    : "";
                                const sizeAnnouncement =
                                    operation.direction === "horizontal"
                                        ? `columns ${operation.placement.width}${columnsConstraint}`
                                        : `rows ${operation.placement.height}${rowsConstraint}`;
                                return createAnnouncement(
                                    `Item resized to ${sizeAnnouncement}.`,
                                    operation.conflicts,
                                    operation.disturbed
                                );
                            },
                            liveAnnouncementDndItemInserted: operation => {
                                const columns = `column ${operation.placement.x + 1}`;
                                const rows = `row ${operation.placement.y + 1}`;
                                return createAnnouncement(
                                    `Item inserted to ${columns}, ${rows}.`,
                                    operation.conflicts,
                                    operation.disturbed
                                );
                            },
                            liveAnnouncementDndCommitted: operationType =>
                                `${operationType} committed`,
                            liveAnnouncementDndDiscarded: operationType =>
                                `${operationType} discarded`,
                            liveAnnouncementItemRemoved: op =>
                                createAnnouncement(
                                    `Removed item ${op.item.data.title}.`,
                                    [],
                                    op.disturbed
                                ),
                            navigationAriaLabel: "Board navigation",
                            navigationAriaDescription:
                                "Click on non-empty item to move focus over",
                            navigationItemAriaLabel: item =>
                                item ? item.data.title : "Empty"
                        }}
                    />
                </SpaceBetween>
            </AppContentLayout>
        </AppLayout>
    );
}

function createAnnouncement(operationAnnouncement, conflicts, disturbed) {
    const conflictsAnnouncement =
        conflicts.length > 0
            ? `Conflicts with ${conflicts.map(c => c.data.title).join(", ")}.`
            : "";
    const disturbedAnnouncement =
        disturbed.length > 0
            ? `Disturbed ${disturbed.length} items.`
            : "";
    return [operationAnnouncement, conflictsAnnouncement, disturbedAnnouncement]
        .filter(Boolean)
        .join(" ");
}