import AppLayout from '../../../components/layout/AppLayout';
import AppContentLayout from '../../../components/layout/ContentLayout';
import * as React from 'react';
import {useState, useEffect} from 'react';
import SearchSideNav from "../../../components/navigation/SearchSideNavigation";
import {Container, FormField, Header, Input, Select, Textarea, Link, Multiselect, Modal, Box} from "@cloudscape-design/components";
import * as PropTypes from "prop-types";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import {useLocation, useNavigate} from 'react-router-dom';

function InfoLink(props) {
    return null;
}

InfoLink.propTypes = {
    onFollow: PropTypes.func,
    id: PropTypes.string
};

export default function AIDictionaryCreate() {
    const [activeHref, setActiveHref] = useState('/search/dictionary/ai');
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);

    const [name, setName] = useState(queryParams.get('name') || "");
    const [compoundNouns, setCompoundNouns] = useState(queryParams.get('compounds') || "");
    const [synonyms, setSynonyms] = useState(queryParams.get('synonyms') || "");
    const [compoundWords, setCompoundWords] = useState(queryParams.get('compound_words') || "");
    const [synonymWords, setSynonymWords] = useState(queryParams.get('synonym_words') || "");

    const [dataSources, setDataSources] = useState(queryParams.get('datasource') ? queryParams.get('datasource').split(',').map(ds => ({label: ds, value: ds})) : []);
    const [selectedLLMSetting, setSelectedLLMSetting] = useState(queryParams.get('llm') ? { label: queryParams.get('llm'), value: queryParams.get('llm') } : null);
    const [selectedEmbeddingSetting, setSelectedEmbeddingSetting] = useState(queryParams.get('embedding') ? { label: queryParams.get('embedding'), value: queryParams.get('embedding') } : null);

    const [visible, setVisible] = useState(false);

    const handleFollow = (event) => {
        if (!event.detail.external) {
            event.preventDefault();
            setActiveHref(event.detail.href);
        }
    };

    const handleSave = () => {
        setVisible(true);
    };

    const handleCancel = () => {
        navigate('/search/dictionary/ai');
    };

    return (
        <AppLayout
            breadcrumbs_props={{
                items: [
                    {text: 'Search', href: '/search/index/indices'},
                    {text: 'Dictionary', href: '/search/dictionary/user'},
                    {text: 'AI Dictionary', href: '/search/dictionary/ai'},
                    {text: queryParams.get('name') ? 'Edit' : 'Create', href: '/search/dictionary/ai/create'},
                ]
            }}
            side_nav={
                <SearchSideNav
                    activeHref={activeHref}
                    onFollow={handleFollow}>
                </SearchSideNav>}
        >
            <AppContentLayout
                header_layout_props={{
                    text: "AI Dictionary",
                    description: "Modify the details of the AI-generated dictionary."
                }}
            >
                <SpaceBetween size="l">
                    <Container
                        header={
                            <Header
                                variant="h2"
                                actions={
                                    <SpaceBetween
                                        direction="horizontal"
                                        size="xs"
                                    >
                                        <Button onClick={handleSave} variant="primary">Save</Button>
                                        <Button onClick={handleCancel}>Cancel</Button>
                                    </SpaceBetween>
                                }>
                                {queryParams.get('name') ? "Edit AI Dictionary" : "Create AI Dictionary"}
                            </Header>
                        }>
                        <SpaceBetween size="l">
                            <FormField label="Version Name">
                                <Input
                                    value={name}
                                    onChange={({detail}) => setName(detail.value)}
                                />
                            </FormField>
                            {queryParams.get('name') &&
                                <FormField
                                    label={
                                        <>
                                            Compound Nouns
                                        </>
                                    }
                                    info={<Link variant="info">Info</Link>}
                                    description="Enter compound nouns that should be treated as a single entity. This helps improve the accuracy of word segmentation and entity recognition."
                                    constraintText={(compoundWords.length) + " / 1000 token written."}
                                >
                                    <Textarea
                                        rows={10}
                                        value={compoundWords}
                                        onChange={({ detail }) => setCompoundWords(detail.value)}
                                    />
                                </FormField>
                            }

                            {queryParams.get('name') &&
                                <FormField
                                    label={
                                        <>
                                            Synonyms
                                        </>
                                    }
                                    info={<Link variant="info">Info</Link>}
                                    description="List synonyms or compoundsernative names for entities. This enhances search capabilities and ensures consistent entity recognition across various terms."
                                    constraintText={(synonymWords.length) + " / 1000 token written."}
                                >
                                    <Textarea
                                        rows={10}
                                        value={synonymWords}
                                        onChange={({ detail }) => setSynonymWords(detail.value)}
                                    />
                                </FormField>
                            }
                            <FormField
                                label="LLM Settings"
                                description="Select the LLM setting to use for the AI dictionary."
                            >
                                <Select
                                    selectedOption={selectedLLMSetting}
                                    onChange={({ detail }) =>
                                        setSelectedLLMSetting(detail.selectedOption)
                                    }
                                    options={[
                                        { label: "gpt-4o-long-output-ver1.2", value: "gpt-4o-long-output-ver1.2" },
                                        { label: "gpt-4o-mini-ver2.0", value: "gpt-4o-mini-ver2.0" },
                                        { label: "GPT-4o-mini", value: "GPT-4o-mini" },
                                        { label: "Claude-sonnet-v3.5", value: "Claude-sonnet-v3.5" }
                                    ]}
                                    placeholder="Select LLM setting"
                                />
                            </FormField>
                            <FormField
                                label="Embedding Settings"
                                description="Select the embedding setting to use for the AI dictionary."
                            >
                                <Select
                                    selectedOption={selectedEmbeddingSetting}
                                    onChange={({ detail }) =>
                                        setSelectedEmbeddingSetting(detail.selectedOption)
                                    }
                                    options={[
                                        { label: "ada-002-embed-v1.5", value: "ada-002-embed-v1.5" },
                                        { label: "large-embed-v3.2", value: "large-embed-v3.2" },
                                        { label: "titan-text-embed-v2.1", value: "titan-text-embed-v2.1" }
                                    ]}
                                    placeholder="Select embedding setting"
                                />
                            </FormField>
                            <FormField label="Data Sources">
                                <Multiselect
                                    selectedOptions={dataSources}
                                    onChange={({ detail }) => setDataSources(detail.selectedOptions)}
                                    options={[
                                        { label: "HR Dataset, Jul2028", value: "hr-dataset-jul2028" },
                                        { label: "Youtube Crawl Data", value: "youtube-crawl-data" },
                                        { label: "HR Operation dataset", value: "hr-operation-dataset" }
                                    ]}
                                    placeholder="Choose data sources"
                                />
                            </FormField>
                        </SpaceBetween>
                    </Container>
                </SpaceBetween>
                <Modal
                    onDismiss={() => setVisible(false)}
                    visible={visible}
                    header="Your request has been submitted."
                    footer={
                        <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button onClick={() => setVisible(false)} variant="primary">OK</Button>
                            </SpaceBetween>
                        </Box>
                    }
                >
                    This feature will be supported in the future.
                    Thank you for your patience.
                </Modal>
            </AppContentLayout>
        </AppLayout>
    );
}