import AppLayout from '../../../components/layout/AppLayout';
import AppContentLayout from '../../../components/layout/ContentLayout';
import { useState } from 'react';
import MonitoringSideNav from "../../../components/navigation/MonitoringSideNavigation";
import {
    Container, ContentLayout,
    FormField, Grid,
    Header, Input,
    KeyValuePairs,
    Link,
    StatusIndicator,
    Textarea,
    Modal
} from "@cloudscape-design/components";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import Box from "@cloudscape-design/components/box";
import TextFilter from "@cloudscape-design/components/text-filter";
import ButtonDropdown from "@cloudscape-design/components/button-dropdown";
import Pagination from "@cloudscape-design/components/pagination";
import CollectionPreferences from "@cloudscape-design/components/collection-preferences";
import Table from "@cloudscape-design/components/table";
import * as React from "react";
import Select from "@cloudscape-design/components/select";

export default function AIEvaluationDataset() {
    const [activeHref, setActiveHref] = useState('/evaluation/aidataset');
    const [selectedOption, setSelectedOption] = React.useState({ label: "HR Dataset, Jul2028", value: "1" });
    const [selectedItems, setSelectedItems] = React.useState([]);
    const [visible, setVisible] = React.useState(false);
    const [generateModalVisible, setGenerateModalVisible] = React.useState(false);
    const [items, setItems] = React.useState([
        {
            datasource: "HR Dataset, Jul2028",
            name: "hr_jul2028_evaluation_v1",
            size: "782 rows",
            created: "2028-07-26 13:48:50",
            status: <StatusIndicator type="in-progress">In-progress</StatusIndicator>,
            downloadLink: "/download/hr_jul2028_evaluation_v1.csv"
        },
        {
            datasource: "HR Operation dataset",
            name: "evalset_hr_operation",
            size: "334 rows",
            created: "2028-07-25 11:01:02",
            status: <StatusIndicator type="success">Ready</StatusIndicator>,
            downloadLink: "/download/evalset_hr_operation.csv"
        },
    ]);

    const handleFollow = (event) => {
        if (!event.detail.external) {
            event.preventDefault();
            setActiveHref(event.detail.href);
        }
    };

    const handleDelete = () => {
        setVisible(true);
    };

    const confirmDelete = () => {
        const newItems = items.filter(item => !selectedItems.includes(item));
        setItems(newItems);
        setSelectedItems([]);
        setVisible(false);
    };

    const handleGenerateDataset = () => {
        setGenerateModalVisible(true);
    };

    return (
        <AppLayout
            disableContentPaddings={true}
            breadcrumbs_props={{
                items:[
                    { text: 'Evaluation', href: '/evaluation/chatbot' },
                    { text: 'Dataset', href: '/evaluation/userdataset' },
                    { text: 'AI Generated Dataset', href: '/evaluation/aidataset' },
                ]
            }}
            side_nav={
                <MonitoringSideNav
                    activeHref={activeHref}
                    onFollow={handleFollow}>
                </MonitoringSideNav>
            }
        >
            <ContentLayout
                headerVariant='high-contrast'
                header={
                    <Box padding={{ top: 'xs', bottom: 'l' }} data-testid="evaluation-dataset-header">
                        <Grid gridDefinition={[{ colspan: { default: 12, xs: 8, s: 9 } }, { colspan: { default: 12, xs: 4, s: 3 } }]}>
                            <div>
                                <Box variant="h1">Optimize AI Evaluation with Highly Human-Correlated Datasets</Box>
                                <Box variant="p" color="text-body-secondary" margin={{ top: 'xxs', bottom: 's' }}>
                                    Elevate your AI model assessment using our intelligently generated, exceptionally reliable datasets. Our advanced AI tool creates test data that closely aligns with real-world scenarios and human judgment. Select your specific domain and parameters, then generate customized datasets that provide unmatched accuracy in measuring your AI's true performance.
                                </Box>
                            </div>

                            <Box margin={{ top: 'l' }}>
                                <SpaceBetween size="s">
                                    <Button variant="primary" fullWidth={true} onClick={handleGenerateDataset}>
                                        Learn More
                                    </Button>
                                </SpaceBetween>
                            </Box>
                        </Grid>
                    </Box>
                }
                defaultPadding={true}
                disableOverlap={true}
            >
                <Box margin={{ top: 'l' }}>
                    <AppContentLayout
                        header_layout_props={{
                            text: "AI Generated Dataset",
                            description: "Generate evaluation datasets for chatbot performance testing."
                        }}
                    >
                        <SpaceBetween size="l">
                            <Container
                                header={
                                    <Header
                                        variant="h2"
                                        actions={
                                            <SpaceBetween
                                                direction="horizontal"
                                                size="xs"
                                            >
                                                <Button variant="primary" onClick={handleGenerateDataset}>Generate Dataset</Button>
                                            </SpaceBetween>
                                        }>
                                        Generation Setting
                                    </Header>
                                }>
                                <SpaceBetween size="l">
                                    <KeyValuePairs
                                        columns={2}
                                        items={[
                                            {
                                                label: 'Generation Status',
                                                value: <StatusIndicator type="success">Ready to generate</StatusIndicator>,
                                            }
                                        ]}
                                    />
                                    <FormField
                                        label="Base Data Source"
                                        secondaryControl={<Button iconName="refresh" />}
                                    >
                                        <Select
                                            selectedOption={selectedOption}
                                            onChange={({ detail }) =>
                                                setSelectedOption(detail.selectedOption)
                                            }
                                            options={[
                                                { label: "HR Dataset, Jul2028", id: "1" },
                                                { label: "Youtube Crawl Data", id: "2" },
                                                { label: "HR Operation dataset", id: "3" },
                                            ]}
                                        />
                                    </FormField>
                                </SpaceBetween>
                            </Container>

                            <Table
                                renderAriaLive={({
                                                     firstIndex,
                                                     lastIndex,
                                                     totalItemsCount
                                                 }) =>
                                    `Displaying items ${firstIndex} to ${lastIndex} of ${totalItemsCount}`
                                }
                                columnDefinitions={[
                                    {
                                        id: "datasource",
                                        header: "Data Source",
                                        cell: e => e.datasource,
                                        sortingField: "datasource",
                                        isRowHeader: true
                                    },
                                    {
                                        id: "name",
                                        header: "Dataset Name",
                                        cell: e => e.name,
                                        sortingField: "name",
                                        isRowHeader: true
                                    },
                                    {
                                        id: "size",
                                        header: "Dataset Size",
                                        cell: e => e.size,
                                        sortingField: "size"
                                    },
                                    {
                                        id: "created",
                                        header: "Created",
                                        cell: e => e.created
                                    },
                                    {
                                        id: "status",
                                        header: "Status",
                                        cell: e => e.status
                                    },
                                    {
                                        id: "download",
                                        header: "Download",
                                        cell: e => <Link href={e.downloadLink}>Click</Link>
                                    }
                                ]}
                                columnDisplay={[
                                    { id: "name", visible: true },
                                    { id: "datasource", visible: true },
                                    { id: "status", visible: true },
                                    { id: "size", visible: true },
                                    { id: "created", visible: true },
                                    { id: "download", visible: true }
                                ]}
                                enableKeyboardNavigation
                                items={items}
                                loadingText="Loading datasets"
                                selectionType="multi"
                                selectedItems={selectedItems}
                                onSelectionChange={({ detail }) => setSelectedItems(detail.selectedItems)}
                                trackBy="name"
                                empty={
                                    <Box
                                        margin={{ vertical: "xs" }}
                                        textAlign="center"
                                        color="inherit"
                                    >
                                        <SpaceBetween size="m">
                                            <b>No datasets</b>
                                            <Button>Generate dataset</Button>
                                        </SpaceBetween>
                                    </Box>
                                }
                                filter={
                                    <TextFilter
                                        filteringPlaceholder="Find datasets"
                                        filteringText=""
                                    />
                                }
                                header={
                                    <Header
                                        counter={
                                            `(${items.length})`
                                        }
                                        actions={
                                            <SpaceBetween
                                                direction="horizontal"
                                                size="xs"
                                            >
                                                <ButtonDropdown
                                                    items={[
                                                        {
                                                            text: "Delete",
                                                            id: "rm",
                                                            disabled: selectedItems.length === 0
                                                        },
                                                    ]}
                                                    onItemClick={({ detail }) => {
                                                        if (detail.id === 'rm') {
                                                            handleDelete();
                                                        }
                                                    }}
                                                >
                                                    Actions
                                                </ButtonDropdown>
                                            </SpaceBetween>
                                        }
                                    >
                                        AI Generated Dataset
                                    </Header>
                                }
                                pagination={
                                    <Pagination currentPageIndex={1} pagesCount={1} />
                                }
                                preferences={
                                    <CollectionPreferences
                                        title="Preferences"
                                        confirmLabel="Confirm"
                                        cancelLabel="Cancel"
                                        preferences={{
                                            pageSize: 10,
                                            contentDisplay: [
                                                { id: "datasource", visible: true },
                                                { id: "name", visible: true },
                                                { id: "size", visible: true },
                                                { id: "created", visible: true },
                                                { id: "download", visible: true }
                                            ]
                                        }}
                                        pageSizePreference={{
                                            title: "Page size",
                                            options: [
                                                { value: 10, label: "10 datasets" },
                                                { value: 20, label: "20 datasets" }
                                            ]
                                        }}
                                        wrapLinesPreference={{}}
                                        stripedRowsPreference={{}}
                                        contentDensityPreference={{}}
                                        contentDisplayPreference={{
                                            options: [
                                                {
                                                    id: "datasource",
                                                    label: "Data Source",
                                                    alwaysVisible: true
                                                },
                                                { id: "name", label: "Dataset Name" },
                                                { id: "size", label: "Dataset Size" },
                                                { id: "created", label: "Created" },
                                                { id: "status", label: "Status" },
                                                { id: "download", label: "Download" }
                                            ]
                                        }}
                                        stickyColumnsPreference={{
                                            firstColumns: {
                                                title: "Stick first column(s)",
                                                description:
                                                    "Keep the first column(s) visible while horizontally scrolling the table content.",
                                                options: [
                                                    { label: "None", value: 0 },
                                                    { label: "First column", value: 1 },
                                                    { label: "First two columns", value: 2 }
                                                ]
                                            },
                                            lastColumns: {
                                                title: "Stick last column",
                                                description:
                                                    "Keep the last column visible while horizontally scrolling the table content.",
                                                options: [
                                                    { label: "None", value: 0 },
                                                    { label: "Last column", value: 1 }
                                                ]
                                            }
                                        }}
                                    />
                                }
                                onRowClick={({ detail }) => {
                                    const clickedItem = detail.item;
                                    const newSelectedItems = selectedItems.includes(clickedItem)
                                        ? selectedItems.filter(item => item !== clickedItem)
                                        : [...selectedItems, clickedItem];
                                    setSelectedItems(newSelectedItems);
                                }}
                            />
                        </SpaceBetween>
                    </AppContentLayout>
                </Box>
            </ContentLayout>
            <Modal
                onDismiss={() => setVisible(false)}
                visible={visible}
                header="Confirm deletion"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button onClick={() => setVisible(false)} variant="link">Cancel</Button>
                            <Button onClick={confirmDelete} variant="primary">Delete</Button>
                        </SpaceBetween>
                    </Box>
                }
            >
                Are you sure you want to delete {selectedItems.length} item(s)?
            </Modal>
            <Modal
                onDismiss={() => setGenerateModalVisible(false)}
                visible={generateModalVisible}
                header="Your request has been submitted."
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button onClick={() => setGenerateModalVisible(false)} variant="primary">OK</Button>
                        </SpaceBetween>
                    </Box>
                }
            >
                This feature will be supported in the future.
                Thank you for your patience.
            </Modal>
        </AppLayout>
    );
}