import AppLayout from '../../../../components/layout/AppLayout';
import AppContentLayout from '../../../../components/layout/ContentLayout';
import { useState } from 'react';
import SearchSideNav from "../../../../components/navigation/SearchSideNavigation";
import {
    BreadcrumbGroup,
    Container, ContentLayout,
    FormField, Grid,
    Header, Input,
    KeyValuePairs,
    Link,
    StatusIndicator,
    Textarea,
    Modal
} from "@cloudscape-design/components";
import * as PropTypes from "prop-types";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import Box from "@cloudscape-design/components/box";
import TextFilter from "@cloudscape-design/components/text-filter";
import ButtonDropdown from "@cloudscape-design/components/button-dropdown";
import Pagination from "@cloudscape-design/components/pagination";
import CollectionPreferences from "@cloudscape-design/components/collection-preferences";
import Table from "@cloudscape-design/components/table";
import * as React from "react";
import { useNavigate } from 'react-router-dom';

function InfoLink(props) {
    return null;
}

InfoLink.propTypes = {
    onFollow: PropTypes.func,
    id: PropTypes.string
};

export default function AIModel() {
    const navigate = useNavigate();
    const [activeHref, setActiveHref] = useState('/search/rankingmodel/ai');

    const [compoundNouns, setCompoundNouns] = useState(`
Stockbroker
Cashflow
Bankruptcy
Spreadsheet
Stakeholder
...
`.trim());

    const [synonyms, setSynonyms] = useState(`
Revenue, Income, Earnings
Investment, Venture, Outlay, Expenditure
Return, Yield
...
`.trim());

    const [selectedItem, setSelectedItem] = React.useState(null);
    const [visible, setVisible] = React.useState(false);
    const [learnMoreVisible, setLearnMoreVisible] = React.useState(false);

    const [
        selectedOption,
        setSelectedOption
    ] = React.useState({ label: "HR Dataset, Jul2028", value: "1" });

    const [disclaimerDismissed, dismissDisclaimer] = useState(false);

    const [items, setItems] = React.useState([
        {
            name: "ltr_hr_reranker_v3",
            datasource: "SEARCHDOC-HR-V1.1",
            status: "Training",
            created: "2024-07-28 22:52:14",
            modelType: "Customize Model",
            textFeatures: ["Chunk Text (mandatory)", "Section Title", "Topic Modeling", "Sentiment Analysis"],
            embeddingFeatures: ["Chunk Text Embedding (mandatory)", "Topic Modeling Embedding", "Sentiment Analysis Embedding"]
        },
        {
            name: "ltr_hr_reranker_v4",
            datasource: "SEARCHDOC-COMMON-V1.0",
            status: "Ready",
            created: "2024-07-26 13:48:50",
            modelType: "Provided Models",
            textFeatures: ["Chunk Text (mandatory)", "Chunk Text English Translation", "Keywords"],
            embeddingFeatures: ["Chunk Text Embedding (mandatory)", "Chunk Text Summary Embedding"]
        },
        {
            name: "ltr_ops_ver0.1",
            datasource: "SEARCHDOC-HR-V1.0",
            status: "Ready",
            created: "2024-07-25 11:01:02",
            modelType: "Customize Model",
            textFeatures: ["Chunk Text (mandatory)", "Entity Recognition", "Product Name Recognition", "Price Recognition"],
            embeddingFeatures: ["Chunk Text Embedding (mandatory)", "Entity Recognition Embedding"]
        },
    ]);

    const handleFollow = (event) => {
        if (!event.detail.external) {
            event.preventDefault();
            setActiveHref(event.detail.href);
        }
    };

    const handleDelete = () => {
        setVisible(true);
    };

    const confirmDelete = () => {
        setItems(items.filter(item => item !== selectedItem));
        setSelectedItem(null);
        setVisible(false);
    };

    const handleEdit = () => {
        if (selectedItem) {
            navigate(`/search/rankingmodel/ai/create?name=${selectedItem.name}&datasource=${selectedItem.datasource}&modelType=${selectedItem.modelType}&textFeatures=${selectedItem.textFeatures}&embeddingFeatures=${selectedItem.embeddingFeatures}&status=${selectedItem.status}&created=${selectedItem.created}`);
        }
    };

    return (
        <AppLayout
            disableContentPaddings={true}
            breadcrumbs_props={{
                items:[
                    { text: 'Search', href: '/search/index/indices' },
                    { text: 'Ranking Model', href: '/search/rankingmodel/models' },
                    { text: 'AI Model', href: '/search/rankingmodel/ai' },
                ]
            }}
            side_nav={
                <SearchSideNav
                    activeHref={activeHref}
                    onFollow={handleFollow}>
                </SearchSideNav>
            }
        >
            <ContentLayout
                headerVariant='high-contrast'
                header={
                    <Box padding={{ top: 'xs', bottom: 'l' }} data-testid="ai-ranking-model-hero-header">
                        <Grid gridDefinition={[{colspan: {default: 12, xs: 8, s: 9}},
                            {colspan: {default: 12, xs: 4, s: 3}}]}>
                            <div><Box variant="h1">Boost Your Search with AI Ranking Models</Box> <Box variant="p"
                                                                                                       color="text-body-secondary"
                                                                                                       margin={{
                                                                                                           top: 'xxs',
                                                                                                           bottom: 's'
                                                                                                       }}> Automatically
                                generate AI ranking models powered by Learning To Rank. Select your target index and
                                model, and our pipeline handles the rest - from data generation to optimization. Get
                                production-ready models tailored to your search needs, enhancing relevance with
                                ease. </Box> <SpaceBetween size="xs">
                                <div>Supported models: <Link variant="primary" href="#"
                                                             external={true}> SEARCHDOC-COMMON </Link> | <Link
                                    variant="primary" href="#" external={true}> SEARCHDOC-HR </Link> | <Link
                                    variant="primary" href="#" external={true}> SEARCHDOC-E-COMMERCE </Link></div>
                                <div>Supported features: <Link variant="primary" href="#" external={true}> Basic Text
                                    Features </Link> | <Link variant="primary" href="#" external={true}> Document
                                    Structure </Link> | <Link variant="primary" href="#"
                                                              external={true}> E-Commerce </Link> | <Link
                                    variant="primary" href="#" external={true}> Image Related Analysis </Link></div>
                            </SpaceBetween></div>

                            <Box margin={{top: 'l'}}>
                                <SpaceBetween size="s">
                                    <Button variant="primary" fullWidth={true}
                                            onClick={() => setLearnMoreVisible(true)}>
                                        Learn More
                                    </Button>
                                </SpaceBetween>
                            </Box>
                        </Grid>
                    </Box>
                }
                defaultPadding={true}
                disableOverlap={true}
            >
                <Box margin={{top: 'l'}}>
                    <AppContentLayout
                        header_layout_props={{
                            text: "AI Model",
                            description: "Powered by Learning To Rank, which allows you to train and utilize AI ranking models."
                        }}
                    >
                        <SpaceBetween size="l">
                            <Table
                                renderAriaLive={({
                                                     firstIndex,
                                                     lastIndex,
                                                     totalItemsCount
                                                 }) =>
                                    `Displaying items ${firstIndex} to ${lastIndex} of ${totalItemsCount}`
                                }
                                selectedItems={selectedItem ? [selectedItem] : []}
                                onSelectionChange={({ detail }) => {
                                    setSelectedItem(detail.selectedItems[0]);
                                }}
                                onRowClick={({ detail }) =>
                                    setSelectedItem(detail.item)
                                }
                                selectionType="single"
                                columnDefinitions={[
                                    {
                                        id: "modelType",
                                        header: "Model Type",
                                        cell: e => e.modelType === "Provided Models" ? e.datasource : "Customize Model",
                                        sortingField: "modelType",
                                        isRowHeader: true
                                    },
                                    {
                                        id: "modelName",
                                        header: "Model Name",
                                        cell: e => (
                                            <Link
                                                href={`/search/rankingmodel/ai/create?name=${e.name}&datasource=${e.datasource}&modelType=${e.modelType}&textFeatures=${e.textFeatures}&embeddingFeatures=${e.embeddingFeatures}&status=${e.status}&created=${e.created}`}
                                            >
                                                {e.name}
                                            </Link>
                                        ),
                                        sortingField: "name",
                                        isRowHeader: true
                                    },
                                    {
                                        id: "created",
                                        header: "Created",
                                        cell: e => e.created
                                    },
                                    {
                                        id: "status",
                                        header: "Model Status",
                                        cell: e => (
                                            <StatusIndicator type={e.status === "Ready" ? "success" : "in-progress"}>
                                                {e.status}
                                            </StatusIndicator>
                                        )
                                    },
                                    {
                                        id: "textFeatures",
                                        header: "Text Features",
                                        cell: e => e.modelType === "Customize Model" ? (
                                            <SpaceBetween size="xs">
                                                {e.textFeatures.map((feature, index) => (
                                                    <div key={index}>{feature}</div>
                                                ))}
                                            </SpaceBetween>
                                        ) : <box>-</box>
                                    },
                                    {
                                        id: "embeddingFeatures",
                                        header: "Embedding Features",
                                        cell: e => e.modelType === "Customize Model" ? (
                                            <SpaceBetween size="xs">
                                                {e.embeddingFeatures.map((feature, index) => (
                                                    <div key={index}>{feature}</div>
                                                ))}
                                            </SpaceBetween>
                                        ) : <box>-</box>
                                    }
                                ]}
                                columnDisplay={[
                                    { id: "modelName", visible: true },
                                    { id: "modelType", visible: true },
                                    { id: "status", visible: true },
                                    { id: "value", visible: true },
                                    { id: "textFeatures", visible: true },
                                    { id: "embeddingFeatures", visible: true },
                                    { id: "created", visible: true },
                                ]}
                                enableKeyboardNavigation
                                items={items}
                                loadingText="Loading resources"
                                trackBy="name"
                                empty={
                                    <Box
                                        margin={{ vertical: "xs" }}
                                        textAlign="center"
                                        color="inherit"
                                    >
                                        <SpaceBetween size="m">
                                            <b>No resources</b>
                                            <Button>Create resource</Button>
                                        </SpaceBetween>
                                    </Box>
                                }
                                filter={
                                    <TextFilter
                                        filteringPlaceholder="Find resources"
                                        filteringText=""
                                    />
                                }
                                header={
                                    <Header
                                        counter={
                                            `(${items.length})`
                                        }
                                        actions={
                                            <SpaceBetween
                                                direction="horizontal"
                                                size="xs"
                                            >
                                                <ButtonDropdown
                                                    items={[
                                                        {
                                                            text: "Edit",
                                                            id: "edit",
                                                            disabled: !selectedItem
                                                        },
                                                        {
                                                            text: "Delete",
                                                            id: "rm",
                                                            disabled: !selectedItem
                                                        },
                                                    ]}
                                                    onItemClick={({ detail }) => {
                                                        if (detail.id === "rm") {
                                                            handleDelete();
                                                        } else if (detail.id === "edit") {
                                                            handleEdit();
                                                        }
                                                    }}
                                                >
                                                    Actions
                                                </ButtonDropdown>
                                                <Button variant="primary" href="/search/rankingmodel/ai/create">Create Model</Button>
                                            </SpaceBetween>
                                        }
                                    >
                                        AI Ranking Models
                                    </Header>
                                }
                                pagination={
                                    <Pagination currentPageIndex={1} pagesCount={1} />
                                }
                                preferences={
                                    <CollectionPreferences
                                        title="Preferences"
                                        confirmLabel="Confirm"
                                        cancelLabel="Cancel"
                                        preferences={{
                                            pageSize: 10,
                                            contentDisplay: [
                                                { id: "modelType", visible: true },
                                                { id: "modelName", visible: true },
                                                { id: "value", visible: true },
                                                { id: "created", visible: true },
                                                { id: "updated", visible: true },
                                                { id: "textFeatures", visible: true },
                                                { id: "embeddingFeatures", visible: true }
                                            ]
                                        }}
                                        pageSizePreference={{
                                            title: "Page size",
                                            options: [
                                                { value: 10, label: "10 resources" },
                                                { value: 20, label: "20 resources" }
                                            ]
                                        }}
                                        wrapLinesPreference={{}}
                                        stripedRowsPreference={{}}
                                        contentDensityPreference={{}}
                                        contentDisplayPreference={{
                                            options: [
                                                {
                                                    id: "modelType",
                                                    label: "Model Type",
                                                    alwaysVisible: true
                                                },
                                                { id: "modelName", label: "modelName name" },
                                                { id: "value", label: "Text value" },
                                                { id: "created", label: "Created" },
                                                { id: "updated", label: "Updated" },
                                                { id: "textFeatures", label: "Text Features" },
                                                { id: "embeddingFeatures", label: "Embedding Features" },
                                            ]
                                        }}
                                        stickyColumnsPreference={{
                                            firstColumns: {
                                                title: "Stick first column(s)",
                                                description:
                                                    "Keep the first column(s) visible while horizontally scrolling the table content.",
                                                options: [
                                                    { label: "None", value: 0 },
                                                    { label: "First column", value: 1 },
                                                    { label: "First two columns", value: 2 }
                                                ]
                                            },
                                            lastColumns: {
                                                title: "Stick last column",
                                                description:
                                                    "Keep the last column visible while horizontally scrolling the table content.",
                                                options: [
                                                    { label: "None", value: 0 },
                                                    { label: "Last column", value: 1 }
                                                ]
                                            }
                                        }}
                                    />
                                }
                            />
                        </SpaceBetween>
                    </AppContentLayout>
                </Box>
            </ContentLayout>
            <Modal
                onDismiss={() => setVisible(false)}
                visible={visible}
                header="Confirm deletion"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button onClick={() => setVisible(false)} variant="link">Cancel</Button>
                            <Button onClick={confirmDelete} variant="primary">Delete</Button>
                        </SpaceBetween>
                    </Box>
                }
            >
                Are you sure you want to delete the selected item?
            </Modal>
            <Modal
                onDismiss={() => setLearnMoreVisible(false)}
                visible={learnMoreVisible}
                header="Your request has been submitted."
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button onClick={() => setLearnMoreVisible(false)} variant="primary">OK</Button>
                        </SpaceBetween>
                    </Box>
                }
            >
                This feature will be supported in the future.
                Thank you for your patience.
            </Modal>
        </AppLayout>
    );
}