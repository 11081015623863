import AppLayout from '../../../components/layout/AppLayout';
import AppContentLayout from '../../../components/layout/ContentLayout';
import { useState, useEffect } from 'react';
import ServiceSideNavigation from "../../../components/navigation/ServiceSideNavigation";
import {
    Container,
    FormField,
    Header, Input,
    Select, SpaceBetween,
} from "@cloudscape-design/components";
import Button from "@cloudscape-design/components/button";
import { useLocation } from 'react-router-dom';

export default function AppManagementEdit() {
    const [activeHref, setActiveHref] = useState('/application/app/management/edit');
    const [nameValue, setNameValue] = useState("");
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [selectedSystemPrompt, setSelectedSystemPrompt] = useState(null);
    const [selectedNoticeMessage, setSelectedNoticeMessage] = useState(null);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    useEffect(() => {
        setNameValue(queryParams.get('appName') || "");
        const index = queryParams.get('index');
        if (index) {
            setSelectedIndex({ label: index, value: index });
        }
        const systemPrompt = queryParams.get('systemPrompt');
        if (systemPrompt) {
            setSelectedSystemPrompt({ label: systemPrompt, value: systemPrompt });
        }
        const noticeMessage = queryParams.get('noticeMessage');
        if (noticeMessage) {
            setSelectedNoticeMessage({ label: noticeMessage, value: noticeMessage });
        }
    }, []);

    const handleFollow = (event) => {
        if (!event.detail.external) {
            event.preventDefault();
            setActiveHref(event.detail.href);
        }
    };

    const systemPromptOptions = [
        {
            label: "Default Prompt",
            value: "This is a default system prompt for general use. It can be used in various scenarios and provides a good starting point for many tasks.",
            description: "This is a default system prompt for general use. It can be used in various scenarios and provides a good starting point for many tasks."
        },
        {
            label: "Specialized Prompt for Contract",
            value: "This prompt is designed for specific tasks that require more focused instructions. It's tailored for particular use cases and may include domain-specific language.",
            description: "This prompt is designed for specific tasks that require more focused instructions. It's tailored for particular use cases and may include domain-specific language."
        },
        {
            label: "Custom Prompt - Ver1",
            value: "A custom prompt created by the user for their unique requirements. This prompt can be edited and fine-tuned as needed to achieve desired results.",
            description: "A custom prompt created by the user for their unique requirements. This prompt can be edited and fine-tuned as needed to achieve desired results."
        }
    ];

    const noticeMessageOptions = [
        {
            label: "Welcome Message",
            value: "Welcome to our application! We hope you enjoy your experience with our services and find everything you need. If you have any questions, please don't hesitate to contact our support team.",
            description: "Welcome to our application! We hope you enjoy your experience with our services and find everything you need. If you have any questions, please don't hesitate to contact our support team."
        },
        {
            label: "Maintenance Notice",
            value: "Our system will be undergoing maintenance on July 25th from 2 AM to 4 AM. During this time, some services may be unavailable. We apologize for any inconvenience this may cause.",
            description: "Our system will be undergoing maintenance on July 25th from 2 AM to 4 AM. During this time, some services may be unavailable. We apologize for any inconvenience this may cause."
        },
        {
            label: "New Feature Announcement",
            value: "We're excited to announce our latest feature: real-time collaboration! Now you can work together with your team members in real-time, boosting productivity and teamwork.",
            description: "We're excited to announce our latest feature: real-time collaboration! Now you can work together with your team members in real-time, boosting productivity and teamwork."
        }
    ];

    return (
        <AppLayout
            breadcrumbs_props={{
                items:[
                    { text: 'Application', href: '/application/observerbility/userfeedbacks' },
                    { text: 'App Management', href: '/application/app/management' },
                    { text: 'Edit', href: '/application/app/management/edit' },
                ]
            }}
            side_nav={
                <ServiceSideNavigation
                    activeHref={activeHref}
                    onFollow={handleFollow}>
                </ServiceSideNavigation>}
        >
            <AppContentLayout
                header_layout_props={{
                    text: "App Management",
                    description: "Configure and manage your chatbot application settings."
                }}
            >
                <SpaceBetween size="l">
                    <Container
                        header={
                            <Header
                                variant="h2"
                                actions={
                                    <SpaceBetween
                                        direction="horizontal"
                                        size="xs"
                                    >
                                        <Button variant="primary">Save Changes</Button>
                                        <Button href="/application/app/management">Cancel</Button>
                                    </SpaceBetween>
                                }>
                                Edit Version
                            </Header>
                        }>
                        <SpaceBetween size="xl">
                            <FormField
                                label={
                                    <>
                                        App Name
                                    </>
                                }
                            >
                                <Input
                                    onChange={({ detail }) => setNameValue(detail.value)}
                                    value={nameValue}
                                    placeholder="Enter Version Name"
                                />
                            </FormField>
                            <FormField
                                label="Base Index"
                            >
                                <Select
                                    selectedOption={selectedIndex}
                                    onChange={({ detail }) =>
                                        setSelectedIndex(detail.selectedOption)
                                    }
                                    options={[
                                        {
                                            label: "schdc-2024072001",
                                            value: "schdc-2024072001"
                                        },
                                        {
                                            label: "schdc-2024071814",
                                            value: "schdc-2024071814"
                                        },
                                        {
                                            label: "schdc-2024071101",
                                            value: "schdc-2024071101"
                                        },
                                        {
                                            label: "schdc-2024062802",
                                            value: "schdc-2024062802"
                                        },
                                        {
                                            label: "schdc-2024062801",
                                            value: "schdc-2024062801"
                                        }
                                    ]}
                                    selectedAriaLabel="Selected"
                                    placeholder="Select Index"
                                />
                            </FormField>
                            <FormField
                                label="System Prompt"
                            >
                                <Select
                                    selectedOption={selectedSystemPrompt}
                                    onChange={({ detail }) =>
                                        setSelectedSystemPrompt(detail.selectedOption)
                                    }
                                    options={systemPromptOptions}
                                    selectedAriaLabel="Selected"
                                    placeholder="Select System Prompt"
                                />
                            </FormField>
                            <FormField
                                label="Notice Board"
                            >
                                <Select
                                    selectedOption={selectedNoticeMessage}
                                    onChange={({ detail }) =>
                                        setSelectedNoticeMessage(detail.selectedOption)
                                    }
                                    options={noticeMessageOptions}
                                    selectedAriaLabel="Selected"
                                    placeholder="Select Notice Message"
                                />
                            </FormField>
                        </SpaceBetween>
                    </Container>
                </SpaceBetween>
            </AppContentLayout>
        </AppLayout>
    );
}