import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AppLayout from '../../components/layout/AppLayout';
import AppContentLayout from '../../components/layout/ContentLayout';
import SearchSideNav from "../../components/navigation/SearchSideNavigation";
import {
    Container,
    FormField,
    Header,
    Input,
    Select,
    SpaceBetween,
    Button,
    Modal,
    Box
} from "@cloudscape-design/components";
import LanguageModelSideNav from "../../components/navigation/LanguageModelSideNavigation";

export default function LLMSettingCreate() {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);

    const [activeHref, setActiveHref] = useState('/languagemodel/llm');
    const [name, setName] = useState(queryParams.get('modelSettingName') || "");
    const [hostingEnvironment, setHostingEnvironment] = useState(queryParams.get('hostingEnvironment') || "");
    const [modelVendor, setModelVendor] = useState(queryParams.get('modelVendor') || "");
    const [modelName, setModelName] = useState(queryParams.get('modelName') || "");
    const [created, setCreated] = useState(queryParams.get('created') || "");
    const [apiKey, setApiKey] = useState(queryParams.get('modelSettingName') ? "************" : "");
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (hostingEnvironment && !modelVendor) {
            handleHostingEnvironmentChange({ value: hostingEnvironment });
        }
    }, [hostingEnvironment, modelVendor]);

    const handleFollow = (event) => {
        if (!event.detail.external) {
            event.preventDefault();
            setActiveHref(event.detail.href);
        }
    };

    const handleCreateConfiguration = () => {
        setVisible(true);
    };

    const handleHostingEnvironmentChange = (selectedOption) => {
        setHostingEnvironment(selectedOption.value);
        switch(selectedOption.value) {
            case "OpenAI API":
                setModelVendor("OpenAI");
                break;
            case "Anthropic API":
            case "AWS Hosted":
                setModelVendor("Anthropic");
                break;
            default:
                setModelVendor("");
        }
        setModelName("");
    };

    const getModelOptions = () => {
        switch(modelVendor) {
            case "Anthropic":
                return [
                    { label: "Claude-sonnet-v3.5", value: "Claude-sonnet-v3.5" },
                    { label: "Claude-haiku-v3.0", value: "Claude-haiku-v3.0" }
                ];
            case "OpenAI":
                return [
                    { label: "GPT-4o", value: "GPT-4o" },
                    { label: "GPT-4o-mini", value: "GPT-4o-mini" }
                ];
            case "Google":
                return [
                    { label: "Gemini-1.5-flash", value: "Gemini-1.5-flash" }
                ];
            default:
                return [];
        }
    };

    return (
        <AppLayout
            breadcrumbs_props={{
                items:[
                    { text: 'Language Model', href: '/languagemodel' },
                    { text: 'LLM Setting', href: '/languagemodel/llm' },
                    { text: 'Create', href: '/languagemodel/llm/create' },
                ]
            }}
            side_nav={
                <LanguageModelSideNav
                    activeHref={activeHref}
                    onFollow={handleFollow}>
                </LanguageModelSideNav>}
        >
            <AppContentLayout
                header_layout_props={{
                    text: "LLM Setting",
                    description: "Settings for language models"
                }}
            >
                <SpaceBetween size="l">
                    <Container
                        header={
                            <Header
                                variant="h2"
                                actions={
                                    <SpaceBetween
                                        direction="horizontal"
                                        size="xs"
                                    >
                                        <Button variant="primary" onClick={handleCreateConfiguration}>
                                            Save
                                        </Button>
                                        <Button onClick={() => navigate('/languagemodel/llm')}>Cancel</Button>
                                    </SpaceBetween>
                                }>
                                Settings
                            </Header>
                        }>
                        <SpaceBetween size="xl">
                            <FormField label="Name">
                                <Input
                                    onChange={({ detail }) => setName(detail.value)}
                                    value={name}
                                    placeholder="Enter setting name"
                                />
                            </FormField>
                            <FormField label="Hosting Environment">
                                <Select
                                    onChange={({ detail }) => handleHostingEnvironmentChange(detail.selectedOption)}
                                    selectedOption={hostingEnvironment ? { label: hostingEnvironment, value: hostingEnvironment } : null}
                                    options={[
                                        { label: "OpenAI API", value: "OpenAI API" },
                                        { label: "AWS Hosted", value: "AWS Hosted" },
                                        { label: "Anthropic API", value: "Anthropic API" },
                                    ]}
                                    placeholder="Choose a hosting environment"
                                />
                            </FormField>
                            <FormField label="Model Vendor">
                                <div>{hostingEnvironment ? modelVendor : '-'}</div>
                            </FormField>
                            <FormField label="Model Name">
                                <Select
                                    onChange={({ detail }) => setModelName(detail.selectedOption.value)}
                                    selectedOption={{ label: modelName, value: modelName }}
                                    options={getModelOptions()}
                                    placeholder="Choose a model"
                                    disabled={!modelVendor}
                                />
                            </FormField>
                            <FormField label="API Key">
                                <Input
                                    type="password"
                                    onChange={({ detail }) => setApiKey(detail.value)}
                                    value={apiKey}
                                    placeholder="Enter API Key"
                                />
                            </FormField>
                            {created && (
                                <FormField label="Created">
                                    <Input value={created} disabled />
                                </FormField>
                            )}
                        </SpaceBetween>
                    </Container>
                </SpaceBetween>
                <Modal
                    onDismiss={() => setVisible(false)}
                    visible={visible}
                    header="Your configuration has been saved."
                    footer={
                        <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button onClick={() => {
                                    setVisible(false);
                                    navigate('/languagemodel/llm');
                                }} variant="primary">OK</Button>
                            </SpaceBetween>
                        </Box>
                    }
                >
                    This feature will be supported in the future.
                    Thank you for your patience.
                </Modal>
            </AppContentLayout>
        </AppLayout>
    );
}