import AppLayout from '../../../components/layout/AppLayout';
import AppContentLayout from '../../../components/layout/ContentLayout';
import { useState, useEffect } from 'react';
import SearchSideNav from "../../../components/navigation/SearchSideNavigation";
import * as React from "react";
import {
    Container,
    Header,
    Table,
    Box,
    SpaceBetween,
    Button,
    Pagination,
    Input,
    ColumnLayout,
    Select,
    ExpandableSection,
    Modal,
    Link, TextContent
} from "@cloudscape-design/components";
import { useNavigate } from 'react-router-dom';
import Icon from "@cloudscape-design/components/icon";

export default function SearchTest() {
    const navigate = useNavigate();
    const [activeHref, setActiveHref] = useState('/search/index/search');
    const [items, setItems] = useState([]);
    const [currentPageIndex, setCurrentPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [searchQuery, setSearchQuery] = useState("");
    const [isSearched, setIsSearched] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [showMore, setShowMore] = useState({});
    const [visible, setVisible] = useState(false);

    const mockData = {
        id: "3",
        label: "schdc-2024071101",
        documents: [
            {
                id: "file-1",
                fileName: "NDA_Template.docx",
                pageCount: 3,
                chunkCount: 8,
                pages: [
                    {
                        id: "1",
                        chunks: [
                            {
                                id: "1",
                                text: "1. Definition of Confidential Information 'Confidential Information' means any information disclosed by one party (the 'Disclosing Party') to the other party (the 'Receiving Party'), either directly or indirectly, in writing, orally or by inspection of tangible objects, which is designated as 'Confidential,' 'Proprietary' or some similar designation.",
                                featureCount: 3,
                                features: [
                                    {
                                        name: "Chunk Text (mandatory)",
                                        value: "1. Definition of Confidential Information 'Confidential Information' means any information disclosed by one party (the 'Disclosing Party') to the other party (the 'Receiving Party'), either directly or indirectly, in writing, orally or by inspection of tangible objects, which is designated as 'Confidential,' 'Proprietary' or some similar designation."
                                    },
                                    {
                                        name: "Section Title",
                                        value: "Definition of Confidential Information"
                                    },
                                    {
                                        name: "Keywords",
                                        value: "confidential information, disclosing party, receiving party, proprietary"
                                    }
                                ]
                            },
                            {
                                id: "2",
                                text: "4. Term The obligations and restrictions of this Agreement shall be effective for a period of five (5) years from the Effective Date, regardless of any earlier termination of the business relationship between the parties.",
                                featureCount: 2,
                                features: [
                                    {
                                        name: "Chunk Text (mandatory)",
                                        value: "4. Term The obligations and restrictions of this Agreement shall be effective for a period of five (5) years from the Effective Date, regardless of any earlier termination of the business relationship between the parties."
                                    },
                                    {
                                        name: "Section Title",
                                        value: "Term"
                                    }
                                ]
                            },
                            {
                                id: "3",
                                text: "2. Non-Disclosure The Receiving Party agrees not to use any Confidential Information for any purpose except to evaluate and engage in discussions concerning a potential business relationship between the parties.",
                                featureCount: 2,
                                features: [
                                    {
                                        name: "Chunk Text (mandatory)",
                                        value: "2. Non-Disclosure The Receiving Party agrees not to use any Confidential Information for any purpose except to evaluate and engage in discussions concerning a potential business relationship between the parties."
                                    },
                                    {
                                        name: "Section Title",
                                        value: "Non-Disclosure"
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        id: "2",
                        chunks: [
                            {
                                id: "4",
                                text: "3. Exceptions The Receiving Party shall not be obligated under this Agreement with respect to information that: (a) was already known to the Receiving Party at the time of disclosure; (b) becomes publicly known through no wrongful act of the Receiving Party.",
                                featureCount: 3,
                                features: [
                                    {
                                        name: "Chunk Text (mandatory)",
                                        value: "3. Exceptions The Receiving Party shall not be obligated under this Agreement with respect to information that: (a) was already known to the Receiving Party at the time of disclosure; (b) becomes publicly known through no wrongful act of the Receiving Party."
                                    },
                                    {
                                        name: "Section Title",
                                        value: "Exceptions"
                                    },
                                    {
                                        name: "Chunk Text Summary",
                                        value: "The agreement doesn't apply to information already known to the receiving party or that becomes public without their fault."
                                    }
                                ]
                            },
                            {
                                id: "5",
                                text: "5. Return of Materials All documents and other tangible objects containing or representing Confidential Information and all copies thereof which are in the possession of the Receiving Party shall be and remain the property of the Disclosing Party and shall be promptly returned to the Disclosing Party upon request.",
                                featureCount: 3,
                                features: [
                                    {
                                        name: "Chunk Text (mandatory)",
                                        value: "5. Return of Materials All documents and other tangible objects containing or representing Confidential Information and all copies thereof which are in the possession of the Receiving Party shall be and remain the property of the Disclosing Party and shall be promptly returned to the Disclosing Party upon request."
                                    },
                                    {
                                        name: "Section Title",
                                        value: "Return of Materials"
                                    },
                                    {
                                        name: "Keywords",
                                        value: "documents, tangible objects, confidential information, property, return"
                                    }
                                ]
                            },
                            {
                                id: "6",
                                text: "6. No Rights Granted Nothing in this Agreement shall be construed as granting any rights under any patent, copyright or other intellectual property right of the Disclosing Party, nor shall this Agreement grant the Receiving Party any rights in or to the Disclosing Party's Confidential Information.",
                                featureCount: 3,
                                features: [
                                    {
                                        name: "Chunk Text (mandatory)",
                                        value: "6. No Rights Granted Nothing in this Agreement shall be construed as granting any rights under any patent, copyright or other intellectual property right of the Disclosing Party, nor shall this Agreement grant the Receiving Party any rights in or to the Disclosing Party's Confidential Information."
                                    },
                                    {
                                        name: "Section Title",
                                        value: "No Rights Granted"
                                    },
                                    {
                                        name: "Keywords",
                                        value: "rights, patent, copyright, intellectual property, confidential information"
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        id: "3",
                        chunks: [
                            {
                                id: "7",
                                text: "7. Governing Law This Agreement shall be governed by and construed in accordance with the laws of [State/Country], without giving effect to any choice of law or conflict of law provisions.",
                                featureCount: 2,
                                features: [
                                    {
                                        name: "Chunk Text (mandatory)",
                                        value: "7. Governing Law This Agreement shall be governed by and construed in accordance with the laws of [State/Country], without giving effect to any choice of law or conflict of law provisions."
                                    },
                                    {
                                        name: "Section Title",
                                        value: "Governing Law"
                                    }
                                ]
                            },
                            {
                                id: "8",
                                text: "8. Entire Agreement This Agreement constitutes the entire agreement between the parties with respect to the subject matter hereof and supersedes all prior agreements, understandings and negotiations, both written and oral, between the parties with respect to the subject matter hereof.",
                                featureCount: 3,
                                features: [
                                    {
                                        name: "Chunk Text (mandatory)",
                                        value: "8. Entire Agreement This Agreement constitutes the entire agreement between the parties with respect to the subject matter hereof and supersedes all prior agreements, understandings and negotiations, both written and oral, between the parties with respect to the subject matter hereof."
                                    },
                                    {
                                        name: "Section Title",
                                        value: "Entire Agreement"
                                    },
                                    {
                                        name: "Chunk Text Summary",
                                        value: "This agreement is the complete understanding between parties and replaces all previous agreements on this subject."
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    };

    const indices = [
        { label: "schdc-2024072001", value: "1" },
        { label: "schdc-2024071814", value: "2" },
        { label: "schdc-2024071101", value: "3" }
    ];

    useEffect(() => {
        if (isSearched) {
            const allChunks = mockData.documents[0].pages.flatMap(page => page.chunks);
            const shuffledChunks = allChunks.sort(() => 0.5 - Math.random());
            const searchResults = shuffledChunks.map((chunk, index) => ({
                ranking: index + 1,
                score: (1 - index / shuffledChunks.length).toFixed(2),
                chunkText: chunk.text,
                features: chunk.features,
                fileName: mockData.documents[0].fileName
            }));
            setItems(searchResults);
        }
    }, [isSearched]);

    const handleFollow = (event) => {
        if (!event.detail.external) {
            event.preventDefault();
            setActiveHref(event.detail.href);
        }
    };

    const handleSearch = () => {
        setIsSearched(true);
        setCurrentPageIndex(1);
    };

    const handleClear = () => {
        setIsSearched(false);
    };

    const handleClearSearch = () => {
        setSearchQuery("");
        setIsSearched(false);
        setItems([]);
    };

    const handleIndexChange = ({detail}) => {
        setSelectedIndex(detail.selectedOption);
        setIsSearched(false);
        setItems([]);
    };

    return (
        <AppLayout
            breadcrumbs_props={{
                items: [
                    { text: 'Search', href: '/search/index/indices' },
                    { text: 'Search Tool', href: '/search/index/document'},
                    { text: 'Search Test', href: '/search/index/query' },
                ]
            }}
            side_nav={
                <SearchSideNav
                    activeHref={activeHref}
                    onFollow={handleFollow}>
                </SearchSideNav>}
        >
            <AppContentLayout
                header_layout_props={{
                    text: "Search Test",
                    description: "Test your search queries and view the results."
                }}
            >
                <Container>
                    <SpaceBetween size="l">
                        <Header variant="h2">Select Index</Header>
                        <Select
                            selectedOption={selectedIndex}
                            onChange={handleIndexChange}
                            options={indices}
                            placeholder="Choose an index"
                        />
                        {!selectedIndex && !isSearched &&
                            <TextContent>
                                <small>
                                    Select an index, then enter your query.
                                </small>
                            </TextContent>
                        }
                        {selectedIndex && (
                            <ColumnLayout borders="horizontal" columns={2}>
                                <Input
                                    onChange={({ detail }) => setSearchQuery(detail.value)}
                                    value={searchQuery}
                                    placeholder="Enter your search query"
                                    type="search"
                                    clearable
                                    onClear={handleClearSearch}
                                />
                                <SpaceBetween direction="horizontal" size="xs">
                                    <Button variant={"primary"} disabled={!searchQuery} onClick={handleSearch}>Search</Button>
                                    <Button onClick={handleClear}>Clear</Button>
                                </SpaceBetween>
                            </ColumnLayout>
                        )}
                        {isSearched && (
                            <Table
                                enableKeyboardNavigation
                                columnDefinitions={[
                                    {
                                        id: "ranking",
                                        header: "Ranking",
                                        cell: item => item.ranking,
                                    },
                                    {
                                        id: "score",
                                        header: "Score",
                                        cell: item => item.score,
                                    },
                                    {
                                        id: "fileName",
                                        header: "File Name",
                                        cell: item => item.fileName,
                                    },
                                    {
                                        id: "features",
                                        header: "Features",

                                        cell: item => (
                                            <Box>
                                                <div style={{whiteSpace: 'pre-wrap', wordWrap: 'break-word'}}>
                                                    <SpaceBetween size={"xs"}>
                                                        {item.features.length > 0 && (
                                                            <div>
                                                                <strong>{item.features[0].name}:</strong> {item.features[0].value}
                                                            </div>
                                                        )}
                                                        {item.features.length > 1 && (
                                                            <>
                                                                <Link
                                                                    href={""}
                                                                    onClick={() => setShowMore(prev => ({...prev, [item.ranking]: !prev[item.ranking]}))}
                                                                >
                                                                    {showMore[item.ranking] ? "" : "See more features"}
                                                                </Link>
                                                                {showMore[item.ranking] && (
                                                                    <div>
                                                                        {item.features.slice(1).map((feature, index) => (
                                                                            <div key={index}>
                                                                                <strong>{feature.name}:</strong> {feature.value}
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                    </SpaceBetween>
                                                </div>
                                            </Box>
                                        ),
                                    },
                                    {
                                        id: "anchor-text",
                                        header: "Anchor Text",
                                        cell: () => (
                                            <Link href={"#"} onClick={() => setVisible(true)}>
                                                Modify <Icon name="external" />
                                            </Link>
                                        ),
                                    },
                                ]}
                                items={items.slice((currentPageIndex - 1) * pageSize, currentPageIndex * pageSize)}
                                loadingText="Loading search results"
                                sortingDisabled
                                variant="full-page"
                                empty={

                                    <Box textAlign="center" color="inherit">
                                        <b>No results found</b>
                                        <Box
                                            padding={{ bottom: "s" }}
                                            variant="p"
                                            color="inherit"
                                        >
                                            No results match your search criteria.
                                        </Box>
                                    </Box>
                                }
                                header={
                                    <Header
                                        counter={`(${items.length})`}
                                    >
                                        Search Results
                                    </Header>
                                }
                                footer={
                                    <Box textAlign="center">
                                        <Link href="" onClick={() => setVisible(true)}>View more</Link>
                                    </Box>
                                }
                            />
                        )}
                    </SpaceBetween>
                </Container>
                <Modal
                    onDismiss={() => setVisible(false)}
                    visible={visible}
                    header="Your request has been submitted."
                    footer={
                        <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button onClick={() => setVisible(false)} variant="primary">OK</Button>
                            </SpaceBetween>
                        </Box>
                    }
                >
                    This feature will be supported in the future.
                    Thank you for your patience.
                </Modal>
            </AppContentLayout>
        </AppLayout>
    );
}