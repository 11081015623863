import AppLayout from '../../../components/layout/AppLayout';
import AppContentLayout from '../../../components/layout/ContentLayout';
import { useState } from 'react';
import AccessControlSideNav from "../../../components/navigation/AccessControlSideNavigation";
import {
    Container,
    FormField,
    Header, Input,
    KeyValuePairs,
    Link,
    RadioGroup, Slider, SplitPanel,
    StatusIndicator,
    Textarea,
    Modal
} from "@cloudscape-design/components";
import * as PropTypes from "prop-types";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import Box from "@cloudscape-design/components/box";
import TextFilter from "@cloudscape-design/components/text-filter";
import ButtonDropdown from "@cloudscape-design/components/button-dropdown";
import Pagination from "@cloudscape-design/components/pagination";
import CollectionPreferences from "@cloudscape-design/components/collection-preferences";
import Table from "@cloudscape-design/components/table";
import * as React from "react";

function InfoLink(props) {
    return null;
}

InfoLink.propTypes = {
    onFollow: PropTypes.func,
    id: PropTypes.string
};

export default function AccessGroups() {
    const [activeHref, setActiveHref] = useState('/access/accessgroups');
    const [selectedItems, setSelectedItems] = React.useState([]);
    const [selectedItem, setSelectedItem] = React.useState(null);
    const [splitPanelOpen, setSplitPanelOpen] = useState(false);
    const [visible, setVisible] = React.useState(false);
    const [editModalVisible, setEditModalVisible] = React.useState(false);
    const [items, setItems] = React.useState([
        {
            name: "ADMIN GROUP",
            description: "Administrators group",
            users: [
                { name: "sarahlee1234", email: "sarahlee1234@navercorp.com" },
            ],
            created: "2024-07-20 11:32:06",
            indices: ["schdc-2024072001", "schdc-2024071814"],
            dataSources: ["hr-dataset-jul2028", "hr-operation-dataset", "youtube-crawl-data"],
        },
        {
            name: "BD ENG 1",
            description: "Business Development Engineering Team 1",
            users: [
                { name: "m.jung", email: "m.jung@navercorp.com" },
                { name: "jennifer5678", email: "jennifer5678@navercorp.com" },
            ],
            created: "2024-07-18 12:45:24",
            indices: ["schdc-2024071101", "schdc-2024062802"],
            dataSources: ["youtube-crawl-data"],
        },
        {
            name: "DevOps",
            description: "DevOps team group",
            users: [
                { name: "dyoon", email: "dyoon@navercorp.com" },
                { name: "olivia_han90", email: "olivia_han90@navercorp.com" },
                { name: "jskim", email: "jskim@navercorp.com" },
                { name: "minjun.park", email: "minjun.park@navercorp.com" },
                { name: "sohyun_kim", email: "sohyun_kim@navercorp.com" },
                { name: "joonlee87", email: "joonlee87@navercorp.com" },
                { name: "hyojin.choi", email: "hyojin.choi@navercorp.com" }
            ],
            created: "2024-07-15 00:20:30",
            indices: ["schdc-2024062801"],
            dataSources: ["hr-dataset-jul2028", "hr-operation-dataset"],
        },
    ]);

    const handleFollow = (event) => {
        if (!event.detail.external) {
            event.preventDefault();
            setActiveHref(event.detail.href);
        }
    };

    const handleDelete = () => {
        setVisible(true);
    };

    const confirmDelete = () => {
        const newItems = items.filter(item => !selectedItems.includes(item));
        setItems(newItems);
        setSelectedItems([]);
        setVisible(false);
    };

    const handleEdit = () => {
        setEditModalVisible(true);
    };

    return (
        <AppLayout
            breadcrumbs_props={{
                items:[
                    { text: 'Access Management', href: '/access/accessgroups' },
                    { text: 'Access Groups', href: '/access/groups' },
                ]
            }}
            side_nav={
                <AccessControlSideNav
                    activeHref={activeHref}
                    onFollow={handleFollow}>
                </AccessControlSideNav>}

            splitPanelOpen={splitPanelOpen}
            setSplitPanelOpen={setSplitPanelOpen}
            splitPanel={
                <SplitPanel header={
                    selectedItem ?
                        "'" + selectedItem.name + "' selected" :
                        "No access group selected"}>
                    <Container
                        header={
                            <Header
                                variant="h2"
                                actions={
                                    <SpaceBetween direction="horizontal" size="xs">
                                        <Button variant="primary" onClick={handleEdit}>Edit Group</Button>
                                    </SpaceBetween>
                                }
                            >
                                Access Group Details
                            </Header>
                        }>
                        <SpaceBetween size="l">
                            <KeyValuePairs
                                columns={2}
                                items={[
                                    {
                                        label: 'Name',
                                        value: selectedItem ? selectedItem.name : "-"
                                    },
                                    {
                                        label: 'Description',
                                        value: selectedItem ? selectedItem.description : "-"
                                    },
                                    {
                                        label: 'Created Time',
                                        value: selectedItem ? selectedItem.created : "-"
                                    },
                                    {
                                        label: 'Users',
                                        value: selectedItem ? (
                                            <box>
                                                {selectedItem.users.map((user, index) => (
                                                    <span key={index}>
                                                        <Link variant="info">{user.name}</Link>
                                                        {index !== selectedItem.users.length - 1 && ', '}
                                                        </span>
                                                ))}
                                            </box>
                                        ) : "-"
                                    },
                                    {
                                        label: 'Indices',
                                        value: selectedItem ? (
                                            <SpaceBetween size="xsm">
                                                {selectedItem.indices.map((index, i) => (
                                                    <Link key={i} variant="info">{index}</Link>
                                                ))}
                                            </SpaceBetween>
                                        ) : "-"
                                    },
                                    {
                                        label: 'Data Sources',
                                        value: selectedItem ? (
                                            <SpaceBetween size="xsm">
                                                {selectedItem.dataSources.map((source, i) => (
                                                    <Link key={i} variant="info">{source}</Link>
                                                ))}
                                            </SpaceBetween>
                                    ) : "-"
                                },
                                    ]}
                            />
                        </SpaceBetween>
                    </Container>
                </SplitPanel>
            }
        >
            <AppContentLayout
                header_layout_props={{
                    text: "Access Groups",
                    description: "You can manage access groups and their permissions."
                }}
            >
                <SpaceBetween size="l">
                    <Table
                        renderAriaLive={({
                                             firstIndex,
                                             lastIndex,
                                             totalItemsCount
                                         }) =>
                            `Displaying items ${firstIndex} to ${lastIndex} of ${totalItemsCount}`
                        }
                        onSelectionChange={({ detail }) => {
                            setSelectedItems(detail.selectedItems);
                            setSelectedItem(detail.selectedItems[0]);
                            setSplitPanelOpen(true);
                        }}
                        selectedItems={selectedItems}
                        selectionType="multi"
                        ariaLabels={{
                            selectionGroupLabel: "Items selection",
                            allItemsSelectionLabel: ({ selectedItems }) =>
                                `${selectedItems.length} ${
                                    selectedItems.length === 1 ? "item" : "items"
                                } selected`,
                            itemSelectionLabel: ({ selectedItems }, item) =>
                                item.name
                        }}
                        columnDefinitions={[
                            {
                                id: "name",
                                header: "Name",
                                cell: e => e.name,
                                sortingField: "name",
                                isRowHeader: true
                            },
                            {
                                id: "description",
                                header: "Description",
                                cell: e => e.description,
                                sortingField: "description"
                            },
                            {
                                id: "users",
                                header: "Users",
                                cell: e => e.users.length
                            },
                            {
                                id: "created",
                                header: "Created",
                                cell: e => e.created
                            },
                            {
                                id: "indices",
                                header: "Indices",
                                cell: e => (
                                    <SpaceBetween size="xsm">
                                        {e.indices.map((index, i) => (
                                            <Link key={i} variant="info">{index}</Link>
                                        ))}
                                    </SpaceBetween>
                                ),
                            },
                            {
                                id: "dataSources",
                                header: "Data Sources",
                                cell: e => (
                                    <SpaceBetween size="xsm">
                                        {e.dataSources.map((source, i) => (
                                            <Link key={i} variant="info">{source}</Link>
                                        ))}
                                    </SpaceBetween>
                                ),
                            },
                        ]}
                        columnDisplay={[
                            { id: "name", visible: true },
                            { id: "description", visible: true },
                            { id: "users", visible: true },
                            { id: "created", visible: true },
                            { id: "indices", visible: true },
                            { id: "dataSources", visible: true },
                        ]}
                        enableKeyboardNavigation
                        items={items}
                        loadingText="Loading access groups"
                        trackBy="name"
                        empty={
                            <Box
                                margin={{ vertical: "xs" }}
                                textAlign="center"
                                color="inherit"
                            >
                                <SpaceBetween size="m">
                                    <b>No access groups</b>
                                    <Button href="/access/accessgroups/create">Create access group</Button>
                                </SpaceBetween>
                            </Box>
                        }
                        filter={
                            <TextFilter
                                filteringPlaceholder="Find access groups"
                                filteringText=""
                            />
                        }
                        header={
                            <Header
                                counter={
                                    selectedItems.length
                                        ? "(" + selectedItems.length + "/" + items.length + ")"
                                        : "(" + items.length + ")"
                                }
                                actions={
                                    <SpaceBetween
                                        direction="horizontal"
                                        size="xs"
                                    >
                                        <ButtonDropdown
                                            items={[
                                                {
                                                    text: "Delete",
                                                    id: "rm",
                                                    disabled: selectedItems.length === 0
                                                },
                                                {
                                                    text: "Edit",
                                                    id: "mv",
                                                    disabled: selectedItems.length !== 1
                                                },
                                            ]}
                                            onItemClick={({ detail }) => {
                                                if (detail.id === "rm") {
                                                    handleDelete();
                                                } else if (detail.id === "mv") {
                                                    handleEdit();
                                                }
                                            }}
                                        >
                                            Actions
                                        </ButtonDropdown>
                                        <Button variant="primary" href="/access/accessgroups/create">Create Access Group</Button>
                                    </SpaceBetween>
                                }
                            >
                                Access Groups
                            </Header>
                        }
                        pagination={
                            <Pagination currentPageIndex={1} pagesCount={2} />
                        }
                        preferences={
                            <CollectionPreferences
                                title="Preferences"
                                confirmLabel="Confirm"
                                cancelLabel="Cancel"
                                preferences={{
                                    pageSize: 10,
                                    contentDisplay: [
                                        { id: "name", visible: true },
                                        { id: "description", visible: true },
                                        { id: "users", visible: true },
                                        { id: "created", visible: true },
                                        { id: "indices", visible: true },
                                        { id: "dataSources", visible: true },
                                    ]
                                }}
                                pageSizePreference={{
                                    title: "Page size",
                                    options: [
                                        { value: 10, label: "10 access groups" },
                                        { value: 20, label: "20 access groups" }
                                    ]
                                }}
                                wrapLinesPreference={{}}
                                stripedRowsPreference={{}}
                                contentDensityPreference={{}}
                                contentDisplayPreference={{
                                    options: [
                                        {
                                            id: "name",
                                            label: "Name",
                                            alwaysVisible: true
                                        },
                                        { id: "description", label: "Description" },
                                        { id: "users", label: "Users" },
                                        { id: "created", label: "Created" },
                                        { id: "indices", label: "Indices" },
                                        { id: "dataSources", label: "Data Sources" },
                                    ]
                                }}
                                stickyColumnsPreference={{
                                    firstColumns: {
                                        title: "Stick first column(s)",
                                        description:
                                            "Keep the first column(s) visible while horizontally scrolling the table content.",
                                        options: [
                                            { label: "None", value: 0 },
                                            { label: "First column", value: 1 },
                                            { label: "First two columns", value: 2 }
                                        ]
                                    },
                                    lastColumns: {
                                        title: "Stick last column",
                                        description:
                                            "Keep the last column visible while horizontally scrolling the table content.",
                                        options: [

                                            { label: "None", value: 0 },
                                            { label: "Last column", value: 1 }
                                        ]
                                    }
                                }}
                            />
                        }
                        onRowClick={({ detail }) => {
                            if (selectedItems.includes(detail.item)) {
                                setSelectedItems(selectedItems.filter(item => item !== detail.item));
                            } else {
                                setSelectedItems([...selectedItems, detail.item]);
                            }
                            setSelectedItem(detail.item);
                            setSplitPanelOpen(true);
                        }}
                    />
                </SpaceBetween>
            </AppContentLayout>
            <Modal
                onDismiss={() => setVisible(false)}
                visible={visible}
                header="Confirm deletion"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button onClick={() => setVisible(false)} variant="link">Cancel</Button>
                            <Button onClick={confirmDelete} variant="primary">Delete</Button>
                        </SpaceBetween>
                    </Box>
                }
            >
                Are you sure you want to delete {selectedItems.length} selected item(s)?
            </Modal>
            <Modal
                onDismiss={() => setEditModalVisible(false)}
                visible={editModalVisible}
                header="Edit Access Group"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button onClick={() => setEditModalVisible(false)} variant="primary">OK</Button>
                        </SpaceBetween>
                    </Box>
                }
            >
                This feature will be supported in the future.
                Thank you for your patience.
            </Modal>
        </AppLayout>
    );
}