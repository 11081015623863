import {AppLayout, Grid, Link, SplitPanel} from '@cloudscape-design/components';
import { useState } from 'react';
import Breadcrumbs from '../navigation/Breadcrumbs';
import AppFlashbar from '../common/Flashbar';
import AppHelpPanel from '../common/HelpPanel';
import Box from "@cloudscape-design/components/box";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import * as React from "react";

export default function AppLayoutWrapper({ breadcrumbs_props, side_nav, disableContentPaddings=false, splitPanel, splitPanelOpen, setSplitPanelOpen, children }) {
    const [navigationOpen, setNavigationOpen] = useState(true);
    const [toolsOpen, setToolsOpen] = useState(false);
    const splitPanelProps = splitPanel
        ? {
            splitPanelOpen,
            splitPanel,
            onSplitPanelToggle: ({ detail }) => setSplitPanelOpen(detail.open),
        }
        : {};

    return (
        <div>
            <AppLayout
                breadcrumbs={<Breadcrumbs items={breadcrumbs_props.items}/>}
                navigationOpen={navigationOpen}
                onNavigationChange={({detail}) => setNavigationOpen(detail.open)}
                navigation={side_nav}
                // notifications={<AppFlashbar />}
                toolsOpen={toolsOpen}
                onToolsChange={({detail}) => setToolsOpen(detail.open)}
                tools={<AppHelpPanel/>}
                content={children}
                disableContentPaddings={disableContentPaddings}
                headerVariant={disableContentPaddings && 'high-contrast'}
                {...splitPanelProps}
            />

            <footer style={{
                background: "var(--color-text-body-default-at06ol, #000716)",
                borderTop: "1px solid var(--color-border-divider-default-j74lyz,#b6bec9)",
            }}>
                <Box
                    padding={{top: 'l', bottom: 'xl', right: 'xl'}}
                    data-testid="ai-ranking-model-hero-header"
                    textAlign="right"
                >
                    <div>
                        <span style={{color: "#9da0a4"}}>
                            © 2024, Searchdoc.ai, Inc. or its affiliates. All rights reserved.
                        </span> | <Link variant="primary" href="#" external={true}>
                            Privacy
                        </Link> | <Link variant="primary" href="#" external={true}>
                            Contact
                        </Link>
                    </div>
                </Box>
            </footer>
        </div>
    );
}