import BaseSideNav from "./BaseSideNavigation";
import {Box, Popover} from "@cloudscape-design/components";

export default function LanguageModelSideNav( props ) {
    return (
        <BaseSideNav
            activeHref={props.activeHref}
            header={{
                href: '/languagemodel/llm',
                text: 'Language Model',
            }}
            items={[
                {
                    type: "link", text: "LLM Setting", href: "/languagemodel/llm"
                },
                {
                    type: "link", text: "Embedding Setting", href: "/languagemodel/embedding"
                },
            ]}
        />
    );
}
