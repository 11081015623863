import AppLayout from '../../../components/layout/AppLayout';
import AppContentLayout from '../../../components/layout/ContentLayout';
import { useState } from 'react';
import SearchSideNav from "../../../components/navigation/SearchSideNavigation";
import {
    Container, ContentLayout,
    FormField, Grid,
    Header,
    KeyValuePairs,
    Link,
    RadioGroup, Select, SplitPanel,
    StatusIndicator,
    Textarea,
    Modal,
    Icon
} from "@cloudscape-design/components";
import * as PropTypes from "prop-types";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import Box from "@cloudscape-design/components/box";
import TextFilter from "@cloudscape-design/components/text-filter";
import ButtonDropdown from "@cloudscape-design/components/button-dropdown";
import Pagination from "@cloudscape-design/components/pagination";
import CollectionPreferences from "@cloudscape-design/components/collection-preferences";
import Table from "@cloudscape-design/components/table";
import * as React from "react";
import { useNavigate } from 'react-router-dom';

function InfoLink(props) {
    return null;
}

InfoLink.propTypes = {
    onFollow: PropTypes.func,
    id: PropTypes.string
};

const llmSettings = [
    'gpt-4o-long-output-ver1.2',
    'gpt-4o-mini-ver2.0',
    'GPT-4o-mini',
    'Claude-sonnet-v3.5'
];

const embeddingSettings = [
    'ada-002-embed-v1.5',
    'large-embed-v3.2',
    'titan-text-embed-v2.1'
];

function getRandomSetting(settings) {
    return settings[Math.floor(Math.random() * settings.length)];
}

// Sample data for compound words and synonyms
const sampleCompoundWords1 = `
Stockbroker
Cashflow
Bankruptcy
Spreadsheet
Stakeholder
Microeconomics
Macroeconomics
Cryptocurrency
Blockchain
Crowdfunding
Outsourcing
Downsizing
Globalization
Diversification
Industrialization
Privatization
Deregulation
Monetization
Digitalization
Internationalization
Decentralization
Commoditization
Disintermediation
Entrepreneurship
Telecommunication
Cybersecurity
Biotechnology
Nanotechnology
Ecommerce
Fintech
`.trim();

// Sample data for compound words and synonyms
const sampleCompoundWords2 = `
Deregulation
Monetization
Digitalization
Internationalization
Decentralization
Commoditization
Disintermediation
Entrepreneurship
Telecommunication
Cybersecurity
Biotechnology
Nanotechnology
Ecommerce
Fintech
Stockbroker
Cashflow
Bankruptcy
Spreadsheet
Stakeholder
Microeconomics
Macroeconomics
Cryptocurrency
Blockchain
Crowdfunding
Outsourcing
Downsizing
Globalization
Diversification
Industrialization
Privatization
`.trim();

const sampleSynonymWords1 = `
Revenue,Income,Earnings
Investment,Venture,Outlay,Expenditure
Return,Yield,Profit
Expense,Cost,Charge,Fee
Asset,Resource,Property
Liability,Debt,Obligation
Equity,Ownership,Stake
Budget,Allocation,Allowance
Forecast,Projection,Prediction
Merger,Acquisition,Takeover
Dividend,Payout,Distribution
Recession,Downturn,Slump
Growth,Expansion,Development
Loan,Credit,Advance
Interest,Yield,Return
Tax,Levy,Duty
Audit,Examination,Inspection
Inventory,Stock,Goods
Margin,Profit,Gain
Capital,Funds,Resources
Liquidity,Cash flow,Solvency
Depreciation,Devaluation,Write-down
Amortization,Repayment,Liquidation
Leverage,Debt,Borrowing
Collateral,Security,Guarantee
Volatility,Fluctuation,Instability
Diversification,Variety,Spread
Hedge,Protection,Insurance
`.trim();
const sampleSynonymWords2 = `

Interest,Yield,Return
Tax,Levy,Duty
Audit,Examination,Inspection
Inventory,Stock,Goods
Margin,Profit,Gain
Capital,Funds,Resources
Liquidity,Cash flow,Solvency
Depreciation,Devaluation,Write-down
Amortization,Repayment,Liquidation
Leverage,Debt,Borrowing
Collateral,Security,Guarantee
Volatility,Fluctuation,Instability
Diversification,Variety,Spread
Hedge,Protection,Insurance
Revenue,Income,Earnings
Investment,Venture,Outlay,Expenditure
Return,Yield,Profit
Expense,Cost,Charge,Fee
Asset,Resource,Property
Liability,Debt,Obligation
Equity,Ownership,Stake
Budget,Allocation,Allowance
Forecast,Projection,Prediction
Merger,Acquisition,Takeover
Dividend,Payout,Distribution
Inflation,Price increase,Cost of living increase
Deflation,Price decrease,Cost of living decrease
Recession,Downturn,Slump
Growth,Expansion,Development
Loan,Credit,Advance
`.trim();


export default function DictionaryAI() {
    const navigate = useNavigate();
    const [activeHref, setActiveHref] = useState('/search/dictionary/ai');

    const [selectedItem, setSelectedItem] = React.useState(null);
    const [deleteModalVisible, setDeleteModalVisible] = React.useState(false);
    const [generateModalVisible, setGenerateModalVisible] = React.useState(false);
    const [learnMoreModalVisible, setLearnMoreModalVisible] = React.useState(false);

    const [
        selectedOption,
        setSelectedOption
    ] = React.useState({ label: "HR Dataset, Jul2028", value: "1" });

    const [splitPanelOpen, setSplitPanelOpen] = useState(false);

    const [items, setItems] = React.useState([
        {
            datasource: "HR Dataset, Jul2028",
            name: "hr_dataset_jul2024_v1",
            compounds: "182 words",
            synonyms: "25 synonyms",
            created: "2024-07-26 13:48:50",
            llm: getRandomSetting(llmSettings),
            embedding: getRandomSetting(embeddingSettings),
            compound_words: sampleCompoundWords1,
            synonym_words: sampleSynonymWords1,
            status: "Training",
        },
        {
            datasource: "HR Operation dataset",
            name: "hr_operation_dataset_v1",
            compounds: "306 words",
            synonyms: "42 synonyms",
            created: "2024-07-25 11:01:02",
            llm: getRandomSetting(llmSettings),
            embedding: getRandomSetting(embeddingSettings),
            compound_words: sampleCompoundWords2,
            synonym_words: sampleSynonymWords2,
            status: "Ready",
        },
    ]);

    const handleFollow = (event) =>

    {
        if (!event.detail.external) {
            event.preventDefault();
            setActiveHref(event.detail.href);
        }
    };

    const handleDelete = () => {
        setDeleteModalVisible(true);
    };

    const confirmDelete = () => {
        setItems(items.filter(item => item !== selectedItem));
        setSelectedItem(null);
        setDeleteModalVisible(false);
    };

    const handleGenerateWithAI = () => {
        setGenerateModalVisible(true);
    };

    const handleLearnMore = () => {
        setLearnMoreModalVisible(true);
    };

    const handleCreate = () => {
        navigate(`/search/dictionary/ai/create`);
    };

    return (
        <AppLayout
            disableContentPaddings={true}
            breadcrumbs_props={{
                items:[
                    { text: 'Search', href: '/search/index/indices' },
                    { text: 'Dictionary', href: '/search/dictionary/user' },
                    { text: 'AI Dictionary', href: '/search/dictionary/ai' },
                ]
            }}
            side_nav={
                <SearchSideNav
                    activeHref={activeHref}
                    onFollow={handleFollow}>
                </SearchSideNav>
            }
        >
            <ContentLayout
                headerVariant='high-contrast'
                header={
                    <Box padding={{ top: 'xs', bottom: 'l' }} data-testid="ai-ranking-model-hero-header">
                        <Grid gridDefinition={[{ colspan: { default: 12, xs: 8, s: 9 } }, { colspan: { default: 12, xs: 4, s: 3 } }]}>
                            <div>
                                <Box variant="h1">Enhance Domain-Specific Search with AI Dictionary</Box>
                                <Box variant="p" color="text-body-secondary" margin={{ top: 'xxs', bottom: 's' }}>
                                    Revolutionize search accuracy with our AI-generated domain dictionaries. Simply provide your data source, and with one click, our AI model creates tailored tokenizers, synonyms, and compound nouns. Effortlessly handle specialized terminology and improve findability for any query in your domain.
                                </Box>
                            </div>

                            <Box margin={{ top: 'l' }}>
                                <SpaceBetween size="s">
                                    <Button variant="primary" fullWidth={true} onClick={handleLearnMore}>
                                        Learn More
                                    </Button>
                                </SpaceBetween>
                            </Box>
                        </Grid>
                    </Box>
                }
                defaultPadding={true}
                disableOverlap={true}
            >
                <Box margin={{ top: 'l' }}>
                    <AppContentLayout
                        header_layout_props={{
                            text: "AI Model",
                            description: "Powered by Learning To Rank, which allows you to train and utilize AI ranking models."
                        }}
                    >
                        <SpaceBetween size="l">
                            <Table
                                renderAriaLive={({
                                                     firstIndex,
                                                     lastIndex,
                                                     totalItemsCount
                                                 }) =>
                                    `Displaying items ${firstIndex} to ${lastIndex} of ${totalItemsCount}`
                                }
                                selectedItems={selectedItem ? [selectedItem] : []}
                                selectionType="single"
                                onSelectionChange={({ detail }) => {
                                    setSelectedItem(detail.selectedItems[0]);
                                    setSplitPanelOpen(true);
                                }}
                                onRowClick={({ detail }) => {
                                    setSelectedItem(detail.item);
                                    setSplitPanelOpen(true);
                                }}
                                columnDefinitions={[
                                    {
                                        id: "datasource",
                                        header: "Data Source",
                                        cell: e => (
                                            <SpaceBetween direction={"horizontal"} size={"xxs"}>
                                                <Link href={`#`}>{e.datasource}</Link>
                                                <Icon name="external" variant="link" />
                                            </SpaceBetween>
                                        ),
                                        sortingField: "datasource",
                                        isRowHeader: true
                                    },
                                    {
                                        id: "variable",
                                        header: "Version Name",
                                        cell: e => {
                                            const queryParams = new URLSearchParams({
                                                datasource: e.datasource,
                                                name: e.name,
                                                compounds: e.compounds,
                                                synonyms: e.synonyms,
                                                created: e.created,
                                                llm: e.llm,
                                                embedding: e.embedding,
                                                compound_words: e.compound_words,
                                                synonym_words: e.synonym_words
                                            }).toString();
                                            return <Link href={`/search/dictionary/ai/create?${queryParams}`}>{e.name}</Link>
                                        },
                                        sortingField: "name",
                                        isRowHeader: true
                                    },
                                    {
                                        id: "value",
                                        header: "Compound Nouns",
                                        cell: e => e.compounds,
                                        sortingField: "compounds"
                                    },
                                    { id: "synonyms", header: "Synonyms", cell: e => e.synonyms },
                                    {
                                        id: "created",
                                        header: "Created",
                                        cell: e => e.created
                                    },
                                    {
                                        id: "llm",
                                        header: "LLM",
                                        cell: e => (
                                            <SpaceBetween direction={"horizontal"} size={"xxs"}>
                                                <Link href={`#`}>{e.llm}</Link>
                                                <Icon name="external" variant="link" />
                                            </SpaceBetween>
                                        )
                                    },
                                    {
                                        id: "embedding",
                                        header: "Embedding",
                                        cell: e => (
                                            <SpaceBetween direction={"horizontal"} size={"xxs"}>
                                                <Link href={`#`}>{e.embedding}</Link>
                                                <Icon name="external" variant="link" />
                                            </SpaceBetween>
                                        )
                                    },
                                    {
                                        id: "status",
                                        header: "Status",
                                        cell: e => (
                                            <StatusIndicator type={e.status === "Ready" ? "success" : "in-progress"}>
                                                {e.status}
                                            </StatusIndicator>
                                        ),
                                        sortingField: "status"
                                    },
                                ]}
                                columnDisplay={[
                                    { id: "variable", visible: true },
                                    { id: "status", visible: true },
                                    { id: "value", visible: true },
                                    { id: "synonyms", visible: true },
                                    { id: "datasource", visible: true },
                                    { id: "llm", visible: true },
                                    { id: "embedding", visible: true },
                                    { id: "created", visible: true },
                                ]}
                                enableKeyboardNavigation
                                items={items}
                                loadingText="Loading resources"
                                trackBy="name"
                                empty={
                                    <Box
                                        margin={{ vertical: "xs" }}
                                        textAlign="center"
                                        color="inherit"

                                    >
                                        <SpaceBetween size="m">
                                            <b>No resources</b>
                                            <Button>Create resource</Button>
                                        </SpaceBetween>
                                    </Box>
                                }
                                filter={
                                    <TextFilter
                                        filteringPlaceholder="Find resources"
                                        filteringText=""
                                    />
                                }
                                header={
                                    <Header
                                        counter={
                                            `(${items.length})`
                                        }
                                        actions={
                                            <SpaceBetween
                                                direction="horizontal"
                                                size="xs"
                                            >
                                                <ButtonDropdown
                                                    items={[
                                                        {
                                                            text: "Delete",
                                                            id: "rm",
                                                            disabled: !selectedItem
                                                        },
                                                    ]}
                                                    onItemClick={({ detail }) => {
                                                        if (detail.id === 'rm') {
                                                            handleDelete();
                                                        }
                                                    }}
                                                >
                                                    Actions
                                                </ButtonDropdown>
                                                <Button variant="primary" onClick={handleCreate}>Create Dictionary</Button>
                                            </SpaceBetween>
                                        }
                                    >
                                        AI Dictionaries
                                    </Header>
                                }
                                pagination={
                                    <Pagination currentPageIndex={1} pagesCount={2} />
                                }
                                preferences={
                                    <CollectionPreferences
                                        title="Preferences"
                                        confirmLabel="Confirm"
                                        cancelLabel="Cancel"
                                        preferences={{
                                            pageSize: 10,
                                            contentDisplay: [
                                                { id: "variable", visible: true },
                                                { id: "value", visible: true },
                                                { id: "synonyms", visible: true },
                                                { id: "created", visible: true },
                                                { id: "llm", visible: true },
                                                { id: "embedding", visible: true },
                                                { id: "status", visible: true }
                                            ]
                                        }}
                                        pageSizePreference={{
                                            title: "Page size",
                                            options: [
                                                { value: 10, label: "10 resources" },
                                                { value: 20, label: "20 resources" }
                                            ]
                                        }}
                                        wrapLinesPreference={{}}
                                        stripedRowsPreference={{}}
                                        contentDensityPreference={{}}
                                        contentDisplayPreference={{
                                            options: [
                                                {
                                                    id: "variable",
                                                    label: "Variable name",
                                                    alwaysVisible: true
                                                },
                                                { id: "value", label: "Text value" },
                                                { id: "synonyms", label: "Synonyms" },
                                                { id: "created", label: "Created" },
                                                { id: "llm", label: "LLM" },
                                                { id: "embedding", label: "Embedding" },
                                                { id: "status", label: "Status" },
                                            ]
                                        }}
                                        stickyColumnsPreference={{
                                            firstColumns: {
                                                title: "Stick first column(s)",
                                                description:
                                                    "Keep the first column(s) visible while horizontally scrolling the table content.",
                                                options: [
                                                    { label: "None", value: 0 },
                                                    { label: "First column", value: 1 },
                                                    { label: "First two columns", value: 2 }
                                                ]
                                            },
                                            lastColumns: {
                                                title: "Stick last column",
                                                description:
                                                    "Keep the last column visible while horizontally scrolling the table content.",
                                                options: [
                                                    { label: "None", value: 0 },
                                                    { label: "Last column", value: 1 }
                                                ]
                                            }
                                        }}
                                    />
                                }
                            />
                        </SpaceBetween>
                    </AppContentLayout>
                </Box>
            </ContentLayout>
            <Modal
                onDismiss={() => setDeleteModalVisible(false)}
                visible={deleteModalVisible}
                header="Confirm deletion"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button onClick={() => setDeleteModalVisible(false)} variant="link">Cancel</Button>
                            <Button onClick={confirmDelete} variant="primary">Delete</Button>
                        </SpaceBetween>
                    </Box>
                }
            >
                Are you sure you want to delete the selected item?
            </Modal>
            <Modal
                onDismiss={() => setGenerateModalVisible(false)}
                visible={generateModalVisible}
                header="Your request has been submitted."
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">

                            <Button onClick={() => setGenerateModalVisible(false)} variant="primary">OK</Button>
                        </SpaceBetween>
                    </Box>
                }
            >
                This feature will be supported in the future.
                Thank you for your patience.
            </Modal>
            <Modal
                onDismiss={() => setLearnMoreModalVisible(false)}
                visible={learnMoreModalVisible}
                header="Learn More About AI Dictionary"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button onClick={() => setLearnMoreModalVisible(false)} variant="primary">OK</Button>
                        </SpaceBetween>
                    </Box>
                }
            >
                This feature will be supported in the future.
                Thank you for your patience.
            </Modal>
        </AppLayout>
    );
}