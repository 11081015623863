import React, { useState } from 'react';
import AppLayout from '../../../components/layout/AppLayout';
import AppContentLayout from '../../../components/layout/ContentLayout';
import AccessControlSideNav from '../../../components/navigation/AccessControlSideNavigation';
import {
    Button,
    Container,
    Form,
    FormField,
    Header,
    Input,
    SpaceBetween,
} from '@cloudscape-design/components';
import Multiselect from '@cloudscape-design/components/multiselect';

export default function AccessGroupCreate() {
    const [activeHref, setActiveHref] = useState('/access/groups/create');
    const [groupName, setGroupName] = useState('');
    const [groupDescription, setGroupDescription] = useState('');
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedIndices, setSelectedIndices] = useState([]);
    const [selectedDataSources, setSelectedDataSources] = useState([]);

    const handleFollow = (event) => {
        if (!event.detail.external) {
            event.preventDefault();
            setActiveHref(event.detail.href);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // TODO: Implement group creation logic
        console.log('Creating access group:', {
            name: groupName,
            description: groupDescription,
            users: selectedUsers,
            indices: selectedIndices,
            dataSources: selectedDataSources,
        });
    };

    return (
        <AppLayout
            breadcrumbs_props={{
                items: [
                    { text: 'Access Management', href: '/access/accessgroups' },
                    { text: 'Access Groups', href: '/access/accessgroups' },
                    { text: 'Create', href: '/access/groups/create' },
                ],
            }}
            side_nav={
                <AccessControlSideNav activeHref={activeHref} onFollow={handleFollow} />
            }
        >
            <AppContentLayout
                header_layout_props={{
                    text: 'Access Group',
                    description: 'Create a new access group and assign users to it.',
                }}
            >
                <Form
                    onSubmit={handleSubmit}
                    actions={
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="link" href="/access/accessgroups">
                                Cancel
                            </Button>
                            <Button variant="primary" type="submit">
                                Create Access Group
                            </Button>
                        </SpaceBetween>
                    }
                >
                    <Container header={<Header variant="h2">New Access Group</Header>}>
                        <SpaceBetween direction="vertical" size="l">
                            <FormField label="Name">
                                <Input
                                    value={groupName}
                                    onChange={(event) => setGroupName(event.detail.value)}
                                    placeholder="Enter a unique name for the Access Group (e.g., 'Marketing Team')"
                                />
                            </FormField>
                            <FormField label="Description">
                                <Input
                                    value={groupDescription}
                                    onChange={(event) => setGroupDescription(event.detail.value)}
                                    placeholder="Briefly describe the purpose of this Access Group (e.g., 'For marketing staff access')"
                                />
                            </FormField>
                            <FormField
                                label="Users"
                                description="Select users to assign to this access group."
                            >
                                <Multiselect
                                    selectedOptions={selectedUsers}
                                    onChange={({ detail }) => setSelectedUsers(detail.selectedOptions)}
                                    options={[
                                        {
                                            label: 'johnkim82',
                                            value: 'johnkim82@navercorp.com',
                                        },
                                        {
                                            label: 'empark',
                                            value: 'empark@navercorp.com',
                                        },
                                        {
                                            label: 'davidc',
                                            value: 'david.choi@navercorp.com',
                                        },
                                        {
                                            label: 'sarahlee1234',
                                            value: 'sarahlee1234@navercorp.com',
                                        },
                                        {
                                            label: 'm.jung',
                                            value: 'm.jung@navercorp.com',
                                        },
                                        {
                                            label: 'jennifer5678',
                                            value: 'jennifer5678@navercorp.com',
                                        },
                                        {
                                            label: 'dyoon',
                                            value: 'dyoon@navercorp.com',
                                        },
                                        {
                                            label: 'olivia_han90',
                                            value: 'olivia_han90@navercorp.com',
                                        }
                                    ]}
                                    placeholder="Select users"
                                />
                            </FormField>
                            <FormField
                                label="Indices"
                                description="Select the indices that this access group can access."
                            >
                                <Multiselect
                                    selectedOptions={selectedIndices}
                                    onChange={({ detail }) => setSelectedIndices(detail.selectedOptions)}
                                    options={[
                                        {
                                            label: 'schdc-2024072001',
                                            value: 'schdc-2024072001',
                                        },
                                        {
                                            label: 'schdc-2024071814',
                                            value: 'schdc-2024071814',
                                        },
                                        {
                                            label: 'schdc-2024071101',
                                            value: 'schdc-2024071101',
                                        },
                                        {
                                            label: 'schdc-2024062802',
                                            value: 'schdc-2024062802',
                                        },
                                        {
                                            label: 'schdc-2024062801',
                                            value: 'schdc-2024062801',
                                        },
                                    ]}
                                    placeholder="Select indices"
                                />
                            </FormField>
                            <FormField
                                label="Data Sources"
                                description="Select the data sources that this access group can access."
                            >
                                <Multiselect
                                    selectedOptions={selectedDataSources}
                                    onChange={({ detail }) => setSelectedDataSources(detail.selectedOptions)}
                                    options={[
                                        {
                                            label: 'HR Dataset, Jul2028',
                                            value: 'hr-dataset-jul2028',
                                        },
                                        {
                                            label: 'Youtube Crawl Data',
                                            value: 'youtube-crawl-data',
                                        },
                                        {
                                            label: 'HR Operation dataset',
                                            value: 'hr-operation-dataset',
                                        },
                                    ]}
                                    placeholder="Select data sources"
                                />
                            </FormField>
                        </SpaceBetween>
                    </Container>
                </Form>
            </AppContentLayout>
        </AppLayout>
    );
}