import AppLayout from '../../../components/layout/AppLayout';
import AppContentLayout from '../../../components/layout/ContentLayout';
import React, { useState } from 'react';
import ServiceSideNavigation from "../../../components/navigation/ServiceSideNavigation";
import {
    Container,
    FormField,
    Header,
    Input,
    Select,
    SpaceBetween,
    Wizard,
    Link,
    Button,
    TextContent, Modal,
} from "@cloudscape-design/components";
import {useNavigate} from "react-router-dom";
import Box from "@cloudscape-design/components/box";

export default function AppManagementCreation() {
    const navigate = useNavigate();
    const [activeHref, setActiveHref] = useState('/application/app/management/create');
    const [activeStepIndex, setActiveStepIndex] = useState(0);
    const [visible, setVisible] = React.useState(false);
    const [nameValue, setNameValue] = useState("");
    const [versionValue, setVersionValue] = useState("");
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [selectedSystemPrompt, setSelectedSystemPrompt] = useState(null);
    const [selectedNoticeMessage, setSelectedNoticeMessage] = useState(null);

    const handleFollow = (event) => {
        if (!event.detail.external) {
            event.preventDefault();
            setActiveHref(event.detail.href);
        }
    };
    const handleCreate = () => {
        setVisible(true);
    };

    const systemPromptOptions = [
        {
            label: "Default Prompt",
            value: "This is a default system prompt for general use. It can be used in various scenarios and provides a good starting point for many tasks.",
            description: "This is a default system prompt for general use. It can be used in various scenarios and provides a good starting point for many tasks."
        },
        {
            label: "Specialized Prompt for Contract",
            value: "This prompt is designed for specific tasks that require more focused instructions. It's tailored for particular use cases and may include domain-specific language.",
            description: "This prompt is designed for specific tasks that require more focused instructions. It's tailored for particular use cases and may include domain-specific language."
        },
        {
            label: "Custom Prompt - Ver1",
            value: "A custom prompt created by the user for their unique requirements. This prompt can be edited and fine-tuned as needed to achieve desired results.",
            description: "A custom prompt created by the user for their unique requirements. This prompt can be edited and fine-tuned as needed to achieve desired results."
        }
    ];

    const noticeMessageOptions = [
        {
            label: "Welcome Message",
            value: "Welcome to our application! We hope you enjoy your experience with our services and find everything you need. If you have any questions, please don't hesitate to contact our support team.",
            description: "Welcome to our application! We hope you enjoy your experience with our services and find everything you need. If you have any questions, please don't hesitate to contact our support team."
        },
        {
            label: "Maintenance Notice",
            value: "Our system will be undergoing maintenance on July 25th from 2 AM to 4 AM. During this time, some services may be unavailable. We apologize for any inconvenience this may cause.",
            description: "Our system will be undergoing maintenance on July 25th from 2 AM to 4 AM. During this time, some services may be unavailable. We apologize for any inconvenience this may cause."
        },
        {
            label: "New Feature Announcement",
            value: "We're excited to announce our latest feature: real-time collaboration! Now you can work together with your team members in real-time, boosting productivity and teamwork.",
            description: "We're excited to announce our latest feature: real-time collaboration! Now you can work together with your team members in real-time, boosting productivity and teamwork."
        }
    ];

    return (
        <AppLayout
            breadcrumbs_props={{
                items: [
                    { text: 'Application', href: '/application/observerbility/userfeedbacks' },
                    { text: 'App Management', href: '/application/app/management' },
                    { text: 'Create', href: '/application/app/management/create' },
                ]
            }}
            side_nav={
                <ServiceSideNavigation
                    activeHref={activeHref}
                    onFollow={handleFollow}>
                </ServiceSideNavigation>}
        >
            <AppContentLayout
                header_layout_props={{
                    text: "App Management",
                    description: "Configure and manage your chatbot application settings."
                }}
            >
                <Wizard
                    i18nStrings={{
                        stepNumberLabel: stepNumber => `Step ${stepNumber}`,
                        collapsedStepsLabel: (stepNumber, stepsCount) => `Step ${stepNumber} of ${stepsCount}`,
                        skipToButtonLabel: (step, stepNumber) => `Skip to ${step.title}`,
                        navigationAriaLabel: "Steps",
                        cancelButton: "Cancel",
                        previousButton: "Previous",
                        nextButton: "Next",
                        submitButton: "Create Version",
                        optional: "optional"
                    }}
                    onNavigate={({ detail }) => setActiveStepIndex(detail.requestedStepIndex)}
                    onSubmit={handleCreate}
                    onCancel={() => {
                        navigate('/application/app/management');
                    }}
                    activeStepIndex={activeStepIndex}
                    allowSkipTo
                    steps={[
                        {
                            title: "Basic Information",
                            content: (
                                <Container header={<Header variant="h2">Basic Information</Header>}>
                                    <SpaceBetween direction="vertical" size="l">
                                        <FormField label="App Name">
                                            <Input
                                                onChange={({ detail }) => setNameValue(detail.value)}
                                                value={nameValue}
                                                placeholder="Enter App Name"
                                            />
                                        </FormField>
                                        <FormField label="Version Name">
                                            <Input
                                                onChange={({ detail }) => setVersionValue(detail.value)}
                                                value={versionValue}
                                                placeholder="Enter Version Name"
                                            />
                                        </FormField>
                                        <FormField label="Base Index">
                                            <Select
                                                selectedOption={selectedIndex}
                                                onChange={({ detail }) => setSelectedIndex(detail.selectedOption)}
                                                options={[
                                                    { label: "schdc-2024072001", value: "schdc-2024072001" },
                                                    { label: "schdc-2024071814", value: "schdc-2024071814" },
                                                    { label: "schdc-2024071101", value: "schdc-2024071101" },
                                                    { label: "schdc-2024062802", value: "schdc-2024062802" },
                                                    { label: "schdc-2024062801", value: "schdc-2024062801" }
                                                ]}
                                                selectedAriaLabel="Selected"
                                                placeholder="Select Index"
                                            />
                                        </FormField>
                                    </SpaceBetween>
                                </Container>
                            )
                        },
                        {
                            title: "System Prompt",
                            content: (
                                <Container header={<Header variant="h2">System Prompt</Header>}>
                                    <SpaceBetween direction="vertical" size="l">
                                        <FormField label="System Prompt">
                                            <Select
                                                selectedOption={selectedSystemPrompt}
                                                onChange={({ detail }) => setSelectedSystemPrompt(detail.selectedOption)}
                                                options={systemPromptOptions}
                                                selectedAriaLabel="Selected"
                                                placeholder="Select System Prompt"
                                            />
                                        </FormField>
                                        {selectedSystemPrompt && (
                                            <FormField label="Prompt">
                                                <Container>
                                                    {selectedSystemPrompt.value}
                                                </Container>
                                            </FormField>
                                        )}
                                    </SpaceBetween>
                                </Container>
                            )
                        },
                        {
                            title: "Notice Board",
                            content: (
                                <Container header={<Header variant="h2">Notice Board</Header>}>
                                    <SpaceBetween direction="vertical" size="l">
                                        <FormField label="Notice Message">
                                            <Select
                                                selectedOption={selectedNoticeMessage}
                                                onChange={({ detail }) => setSelectedNoticeMessage(detail.selectedOption)}
                                                options={noticeMessageOptions}
                                                selectedAriaLabel="Selected"
                                                placeholder="Select Notice Message"
                                            />
                                        </FormField>
                                        {selectedNoticeMessage && (
                                            <FormField label="Notice">
                                                <Container>
                                                    {selectedNoticeMessage.value}
                                                </Container>
                                            </FormField>
                                        )}
                                    </SpaceBetween>
                                </Container>
                            )
                        },
                        {
                            title: "Review and Create",
                            content: (
                                <SpaceBetween size="xs">
                                    <Header variant="h3">Review your settings</Header>
                                    <Container header={<Header variant="h2">Summary</Header>}>
                                        <SpaceBetween direction="vertical" size="l">
                                            <FormField label="App Name">{nameValue}</FormField>
                                            <FormField label="Version Name">{versionValue}</FormField>
                                            <FormField label="Base Index">{selectedIndex?.label}</FormField>
                                            <FormField label="System Prompt">: {selectedSystemPrompt?.label}</FormField>
                                            {selectedSystemPrompt && (
                                                <FormField>
                                                    <Container>
                                                        {selectedSystemPrompt.value}
                                                    </Container>
                                                </FormField>
                                            )}
                                            <FormField label="Notice Message">: {selectedNoticeMessage?.label}</FormField>
                                            {selectedNoticeMessage && (
                                                <FormField>
                                                    <Container>
                                                        {selectedNoticeMessage.value}
                                                    </Container>
                                                </FormField>
                                            )}
                                        </SpaceBetween>
                                    </Container>
                                </SpaceBetween>
                            )
                        }
                    ]}
                />
                <Modal
                    onDismiss={() => setVisible(false)}
                    visible={visible}
                    header="Your request has been submitted."
                    footer={
                        <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button onClick={() => setVisible(false)} variant="primary">OK</Button>
                            </SpaceBetween>
                        </Box>
                    }
                >
                    This feature will be supported in the future.
                    Thank you for your patience.
                </Modal>
            </AppContentLayout>
        </AppLayout>
    );
}