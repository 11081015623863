import AppLayout from '../../../components/layout/AppLayout';
import AppContentLayout from '../../../components/layout/ContentLayout';
import { useState, useEffect } from 'react';
import ServiceSideNavigation from "../../../components/navigation/ServiceSideNavigation";
import {
    Container,
    Header,
    SpaceBetween,
    Button,
    Table,
    StatusIndicator,
    Link,
    KeyValuePairs,
    FormField,
    Select,
    ButtonDropdown,
    Checkbox,
    Modal,
    Box
} from "@cloudscape-design/components";
import {useNavigate} from "react-router-dom";

export default function AppManagement() {
    const navigate = useNavigate();
    const [activeHref, setActiveHref] = useState('/application/app/management');
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [visible, setVisible] = useState(false);
    const [actionModalVisible, setActionModalVisible] = useState(false);
    const [currentAction, setCurrentAction] = useState(null);
    const [currentVersion, setCurrentVersion] = useState(null);
    const [items, setItems] = useState([
        {
            version: "v12",
            index: "schdc-2024072001",
            status: "Active",
            created: "2024-07-20 10:30:00",
            updated: "2024-07-20 10:30:00",
            appName: "MyNewApp",
            systemPrompt: "Default Prompt",
            noticeMessage: "Welcome Message"
        },
        {
            version: "v11",
            index: "schdc-2024071101",
            status: "Ready",
            created: "2024-07-11 00:10:15",
            updated: "2024-07-11 00:10:15",
            appName: "ChatbotApp",
            systemPrompt: "Specialized Prompt for Contract",
            noticeMessage: "Maintenance Notice"
        },
        {
            version: "v8",
            index: "schdc-2024062801",
            status: "Ready",
            created: "2024-06-28 00:15:30",
            updated: "2024-06-30 18:45:20",
            appName: "InitialApp",
            systemPrompt: "Custom Prompt - Ver1",
            noticeMessage: "New Feature Announcement"
        }
    ]);

    useEffect(() => {
        const active = items.find(item => item.status === "Active");
        if (active) {
            setCurrentVersion(active);
        }
    }, [items]);

    const handleFollow = (event) => {
        if (!event.detail.external) {
            event.preventDefault();
            setActiveHref(event.detail.href);
        }
    };

    const handleDelete = () => {
        setVisible(true);
    };

    const confirmDelete = () => {
        setItems(items.filter(item => item.version !== selectedItem.version));
        setSelectedItem(null);
        setVisible(false);
    };

    const handleAction = (action) => {
        setCurrentAction(action);
        setActionModalVisible(true);
    };

    const confirmAction = () => {
        if (currentAction === 'activate') {
            setItems(items.map(item => ({
                ...item,
                status: item.version === selectedItem.version ? "Active" : "Ready"
            })));
        } else {
            setItems(items.map(item => ({
                ...item,
                status: item.version === selectedItem.version ? "Ready" : item.status
            })));
        }
        setSelectedItem(null);
        setActionModalVisible(false);
    };

    const handleRowClick = (item) => {
        const queryParams = new URLSearchParams(item).toString();
        navigate(`/application/app/management/edit?${queryParams}`);
    };

    return (
        <AppLayout
            breadcrumbs_props={{
                items:[
                    { text: 'Application', href: '/application/observerbility/userfeedbacks' },
                    { text: 'App Management', href: '/application/app/management' },
                ]
            }}
            side_nav={
                <ServiceSideNavigation
                    activeHref={activeHref}
                    onFollow={handleFollow}>
                </ServiceSideNavigation>}
        >
            <AppContentLayout
                header_layout_props={{
                    text: "App Management",
                    description: "Configure and manage your chatbot application settings."
                }}
            >
                <SpaceBetween size="l">
                    <Container
                        header={
                            <Header variant="h2">
                                Current Version
                            </Header>
                        }>
                        {currentVersion && (
                            <KeyValuePairs
                                columns={3}
                                items={[
                                    { label: 'Version', value: currentVersion.version },
                                    { label: 'Index', value: currentVersion.index },
                                    { label: 'Status', value: <StatusIndicator type="success">Active</StatusIndicator> },
                                    { label: 'Created', value: currentVersion.created },
                                    { label: 'Updated', value: currentVersion.updated },
                                    { label: 'App Name', value: currentVersion.appName },
                                    { label: 'System Prompt', value: currentVersion.systemPrompt },
                                    { label: 'Notice Message', value: currentVersion.noticeMessage },
                                ]}
                            />
                        )}
                    </Container>

                    <Table
                        onSelectionChange={({ detail }) => setSelectedItem(detail.selectedItems[0])}
                        selectedItems={selectedItem ? [selectedItem] : []}
                        selectionType="single"
                        columnDefinitions={[
                            {
                                id: "appName",
                                header: "App Name",
                                cell: e => {
                                    const queryParams = new URLSearchParams(e).toString();
                                    const url = `/application/app/management/edit?${queryParams}`

                                    return <Link href={url} variant={"primary"}>{e.appName}</Link>
                                },
                                sortingField: "appName"
                            },
                            {
                                id: "version",
                                header: "Version Name",
                                cell: e => {
                                    const queryParams = new URLSearchParams(e).toString();
                                    const url = `/application/app/management/edit?${queryParams}`

                                    return <Link href={url} variant={"primary"}>{e.version}</Link>
                                },
                                sortingField: "version",
                                isRowHeader: true
                            },
                            {
                                id: "status",
                                header: "Status",
                                cell: e => <StatusIndicator type={e.status === "Active" ? "success" : "info"}>{e.status}</StatusIndicator>,
                                sortingField: "status"
                            },
                            {
                                id: "index",
                                header: "Index",
                                cell: e => e.index,
                                sortingField: "index"
                            },
                            {
                                id: "systemPrompt",
                                header: "System Prompt",
                                cell: e => e.systemPrompt,
                                sortingField: "systemPrompt"
                            },
                            {
                                id: "noticeMessage",
                                header: "Notice Message",
                                cell: e => e.noticeMessage,
                                sortingField: "noticeMessage"
                            },
                            {
                                id: "created",
                                header: "Created",
                                cell: e => e.created,
                                sortingField: "created"
                            },
                            {
                                id: "updated",
                                header: "Updated",
                                cell: e => e.updated,
                                sortingField: "updated"
                            },
                        ]}
                        items={items}
                        header={
                            <Header
                                counter={
                                    selectedItem
                                        ? "(1/" + items.length + ")"
                                        : "(" + items.length + ")"
                                }
                                actions={
                                    <SpaceBetween
                                        direction="horizontal"
                                        size="xs"
                                    >
                                        <ButtonDropdown
                                            items={[
                                                {
                                                    text: "Activate",
                                                    id: "activate",
                                                    disabled: !selectedItem || selectedItem.status === "Active"
                                                },
                                                {
                                                    text: "Delete",
                                                    id: "delete",
                                                    disabled: !selectedItem
                                                },
                                            ]}
                                            onItemClick={({ detail }) => {
                                                if (detail.id === 'delete') {
                                                    handleDelete();
                                                } else if (detail.id === 'activate') {
                                                    handleAction(detail.id);
                                                }
                                            }}
                                        >
                                            Actions
                                        </ButtonDropdown>
                                        <Button variant="primary" href="/application/app/management/create">Create New App</Button>
                                    </SpaceBetween>
                                }
                            >
                                Version History
                            </Header>
                        }
                        onRowClick={({ detail }) => setSelectedItem(detail.item)}
                    />
                </SpaceBetween>
            </AppContentLayout>
            <Modal
                onDismiss={() => setVisible(false)}
                visible={visible}
                header="Confirm deletion"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button onClick={() => setVisible(false)} variant="link">Cancel</Button>
                            <Button onClick={confirmDelete} variant="primary">Delete</Button>
                        </SpaceBetween>
                    </Box>
                }
            >
                Are you sure you want to delete this item?
            </Modal>
            <Modal
                onDismiss={() => setActionModalVisible(false)}
                visible={actionModalVisible}
                header={`Confirm ${currentAction}`}
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button onClick={() => setActionModalVisible(false)} variant="link">Cancel</Button>
                            <Button onClick={confirmAction} variant="primary">Confirm</Button>
                        </SpaceBetween>
                    </Box>
                }
            >
                Are you sure you want to {currentAction} this item?
            </Modal>
        </AppLayout>
    );
}