import AppLayout from '../../../components/layout/AppLayout';
import AppContentLayout from '../../../components/layout/ContentLayout';
import { useState } from 'react';
import AccessControlSideNav from "../../../components/navigation/AccessControlSideNavigation";
import {
    Container,
    FormField,
    Header, Input,
    KeyValuePairs,
    Link,
    RadioGroup, Slider, SplitPanel,
    StatusIndicator,
    Textarea,
    Modal
} from "@cloudscape-design/components";
import * as PropTypes from "prop-types";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import Box from "@cloudscape-design/components/box";
import TextFilter from "@cloudscape-design/components/text-filter";
import ButtonDropdown from "@cloudscape-design/components/button-dropdown";
import Pagination from "@cloudscape-design/components/pagination";
import CollectionPreferences from "@cloudscape-design/components/collection-preferences";
import Table from "@cloudscape-design/components/table";
import * as React from "react";

function InfoLink(props) {
    return null;
}

InfoLink.propTypes = {
    onFollow: PropTypes.func,
    id: PropTypes.string
};

export default function Users() {
    const [activeHref, setActiveHref] = useState('/access/users');
    const [selectedItems, setSelectedItems] = React.useState([]);
    const [selectedItem, setSelectedItem] = React.useState(null);
    const [splitPanelOpen, setSplitPanelOpen] = useState(false);
    const [visible, setVisible] = React.useState(false);
    const [changeGroupModalVisible, setChangeGroupModalVisible] = React.useState(false);
    const [items, setItems] = React.useState([
        {
            name: "johnkim82",
            email: "johnkim82@navercorp.com",
            group: "-",
            authorization: <Link variant="info" href="https://aws.amazon.com/ko/cognito/">Amazon Cognito</Link>,
            created: "2024-07-20 11:32:06",
        },
        {
            name: "empark",
            email: "empark@navercorp.com",
            group: "-",
            authorization: <Link variant="info" href="https://aws.amazon.com/ko/cognito/">Amazon Cognito</Link>,
            created: "2024-07-18 12:45:24",
        },
        {
            name: "davidc",
            email: "david.choi@navercorp.com",
            group: "-",
            authorization: <Link variant="info" href="https://aws.amazon.com/ko/cognito/">Amazon Cognito</Link>,
            created: "2024-07-15 00:20:30",
        },
        {
            name: "sarahlee1234",
            email: "sarahlee1234@navercorp.com",
            group: <Link variant="info">ADMIN GROUP</Link>,
            authorization: <Link variant="info" href="https://aws.amazon.com/ko/cognito/">Amazon Cognito</Link>,
            created: "2024-07-12 09:15:00",
        },
        {
            name: "m.jung",
            email: "m.jung@navercorp.com",
            group: <Link variant="info">BD Engineering 1</Link>,
            authorization: <Link variant="info" href="https://aws.amazon.com/ko/cognito/">Amazon Cognito</Link>,
            created: "2024-07-10 14:30:45",
        },
        {
            name: "jennifer5678",
            email: "jennifer5678@navercorp.com",
            group: <Link variant="info">BD Engineering 1</Link>,
            authorization: <Link variant="info" href="https://aws.amazon.com/ko/cognito/">Amazon Cognito</Link>,
            created: "2024-07-08 16:20:10",
        },
        {
            name: "dyoon",
            email: "dyoon@navercorp.com",
            group: <Link variant="info">DevOps</Link>,
            authorization: "LDAP",
            created: "2024-07-05 11:00:30",
        },
        {
            name: "olivia_han90",
            email: "olivia_han90@navercorp.com",
            group: <Link variant="info">DevOps</Link>,
            authorization: "LDAP",
            created: "2024-07-02 13:45:20",
        },
    ]);

    const handleFollow = (event) => {
        if (!event.detail.external) {
            event.preventDefault();
            setActiveHref(event.detail.href);
        }
    };

    const handleDelete = () => {
        setItems(items.filter(item => !selectedItems.includes(item)));
        setSelectedItems([]);
        setVisible(false);
    };

    const handleSelectionChange = ({ detail }) => {
        setSelectedItems(detail.selectedItems);
        if (detail.selectedItems.length === 1) {
            setSelectedItem(detail.selectedItems[0]);
            setSplitPanelOpen(true);
        } else {
            setSelectedItem(null);
            setSplitPanelOpen(false);
        }
    };

    return (
        <AppLayout
            breadcrumbs_props={{
                items:[
                    { text: 'Access Management', href: '/access/accessgroups' },
                    { text: 'Users', href: '/access/users' },
                ]
            }}
            side_nav={
                <AccessControlSideNav
                    activeHref={activeHref}
                    onFollow={handleFollow}>
                </AccessControlSideNav>}

            splitPanelOpen={splitPanelOpen}
            setSplitPanelOpen={setSplitPanelOpen}
            splitPanel={
                <SplitPanel header={
                    selectedItem ?
                        "'" + selectedItem.name + "' selected" :
                        "No user selected"}>
                    <Container
                        header={
                            <Header
                                variant="h2"
                            >
                                User Details
                            </Header>
                        }>
                        <SpaceBetween size="l">
                            <KeyValuePairs
                                columns={2}
                                items={[
                                    {
                                        label: 'Name',
                                        value: selectedItem ? selectedItem.name : "-"
                                    },
                                    {
                                        label: 'Email',
                                        value: selectedItem ? selectedItem.email : "-"
                                    },
                                    {
                                        label: 'User Group',
                                        value: selectedItem && selectedItem.group != "-" ? <Link variant="info">{selectedItem.group}</Link> : "-"
                                    },
                                    {
                                        label: 'Authorization',
                                        value: selectedItem ? (
                                            selectedItem.authorization === 'LDAP' ?
                                                'LDAP' :
                                                <Link variant="info" href="https://aws.amazon.com/ko/cognito/">Amazon Cognito</Link>
                                        ) : "-"
                                    },
                                    {
                                        label: 'Created Time',
                                        value: selectedItem ? selectedItem.created : "-"
                                    },
                                ]}
                            />
                        </SpaceBetween>
                    </Container>
                </SplitPanel>
            }
        >
            <AppContentLayout
                header_layout_props={{
                    text: "Users",
                    description: "You can manage users and their access permissions."
                }}
            >
                <SpaceBetween size="l">
                    <Table
                        renderAriaLive={({
                                             firstIndex,
                                             lastIndex,
                                             totalItemsCount
                                         }) =>
                            `Displaying items ${firstIndex} to ${lastIndex} of ${totalItemsCount}`
                        }
                        onSelectionChange={handleSelectionChange}
                        onRowClick={({ detail }) => {
                            const clickedItem = detail.item;
                            const newSelectedItems = selectedItems.includes(clickedItem)
                                ? selectedItems.filter(item => item !== clickedItem)
                                : [...selectedItems, clickedItem];
                            setSelectedItems(newSelectedItems);
                            setSelectedItem(clickedItem);
                            setSplitPanelOpen(true);
                        }}
                        selectedItems={selectedItems}
                        selectionType="multi"
                        ariaLabels={{
                            selectionGroupLabel: "Items selection",
                            allItemsSelectionLabel: ({ selectedItems }) =>
                                `${selectedItems.length} ${
                                    selectedItems.length === 1 ? "item" : "items"
                                } selected`,
                            itemSelectionLabel: ({ selectedItems }, item) =>
                                item.name
                        }}
                        columnDefinitions={[
                            {
                                id: "name",
                                header: "Name",
                                cell: e => e.name,
                                sortingField: "name",
                                isRowHeader: true
                            },
                            {
                                id: "email",
                                header: "Email",
                                cell: e => e.email,
                                sortingField: "email"
                            },
                            { id: "group", header: "User Group", cell: e => e.group },
                            { id: "authorization", header: "Authorization", cell: e => e.authorization },
                            {
                                id: "created",
                                header: "Created",
                                cell: e => e.created
                            },
                        ]}
                        columnDisplay={[
                            { id: "name", visible: true },
                            { id: "email", visible: true },
                            { id: "group", visible: true },
                            { id: "authorization", visible: true },
                            { id: "created", visible: true },
                        ]}
                        enableKeyboardNavigation
                        items={items}
                        loadingText="Loading users"
                        trackBy="name"
                        empty={
                            <Box
                                margin={{ vertical: "xs" }}
                                textAlign="center"
                                color="inherit"
                            >
                                <SpaceBetween size="m">
                                    <b>No users</b>
                                    <Button>Create user</Button>
                                </SpaceBetween>
                            </Box>
                        }
                        filter={
                            <TextFilter
                                filteringPlaceholder="Find users"
                                filteringText=""
                            />
                        }
                        header={
                            <Header
                                counter={
                                    selectedItems.length
                                        ? "(" + selectedItems.length + "/" + items.length + ")"
                                        : "(" + items.length + ")"
                                }
                                actions={
                                    <SpaceBetween
                                        direction="horizontal"
                                        size="xs"
                                    >
                                        <ButtonDropdown
                                            items={[
                                                {
                                                    text: "Delete",
                                                    id: "rm",
                                                    disabled: selectedItems.length === 0
                                                },
                                                {
                                                    text: "Change Group",
                                                    id: "mv",
                                                    disabled: selectedItems.length === 0
                                                },
                                            ]}
                                            onItemClick={({ detail }) => {
                                                if (detail.id === "rm") {
                                                    setVisible(true);
                                                } else if (detail.id === "mv") {
                                                    setChangeGroupModalVisible(true);
                                                }
                                            }}
                                        >
                                            Actions
                                        </ButtonDropdown>
                                    </SpaceBetween>
                                }
                            >
                                Users
                            </Header>
                        }
                        pagination={
                            <Pagination currentPageIndex={1} pagesCount={2} />
                        }
                        preferences={
                            <CollectionPreferences
                                title="Preferences"
                                confirmLabel="Confirm"
                                cancelLabel="Cancel"
                                preferences={{
                                    pageSize: 10,
                                    contentDisplay: [
                                        { id: "name", visible: true },
                                        { id: "email", visible: true },
                                        { id: "group", visible: true },
                                        { id: "authorization", visible: true },
                                        { id: "created", visible: true },
                                    ]
                                }}
                                pageSizePreference={{
                                    title: "Page size",
                                    options: [
                                        { value: 10, label: "10 users" },
                                        { value: 20, label: "20 users" }
                                    ]
                                }}
                                wrapLinesPreference={{}}
                                stripedRowsPreference={{}}
                                contentDensityPreference={{}}
                                contentDisplayPreference={{
                                    options: [
                                        {
                                            id: "name",
                                            label: "Name",
                                            alwaysVisible: true
                                        },
                                        { id: "email", label: "Email" },
                                        { id: "group", label: "User Group" },
                                        { id: "authorization", label: "Authorization" },
                                        { id: "created", label: "Created" },
                                    ]
                                }}
                                stickyColumnsPreference={{
                                    firstColumns: {
                                        title: "Stick first column(s)",
                                        description:
                                            "Keep the first column(s) visible while horizontally scrolling the table content.",
                                        options: [
                                            { label: "None", value: 0 },
                                            { label: "First column", value: 1 },
                                            { label: "First two columns", value: 2 }
                                        ]
                                    },
                                    lastColumns: {
                                        title: "Stick last column",
                                        description:
                                            "Keep the last column visible while horizontally scrolling the table content.",
                                        options: [
                                            { label: "None", value: 0 },
                                            { label: "Last column", value: 1 }
                                        ]
                                    }
                                }}
                            />
                        }
                    />
                </SpaceBetween>
                <Modal
                    onDismiss={() => setVisible(false)}
                    visible={visible}
                    header="Confirm deletion"
                    footer={
                        <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button onClick={() => setVisible(false)} variant="link">Cancel</Button>
                                <Button onClick={handleDelete} variant="primary">Delete</Button>
                            </SpaceBetween>
                        </Box>
                    }
                >
                    Are you sure you want to delete {selectedItems.length} item(s)?
                </Modal>
                <Modal
                    onDismiss={() => setChangeGroupModalVisible(false)}
                    visible={changeGroupModalVisible}
                    header="Your request has been submitted."
                    footer={
                        <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button onClick={() => setChangeGroupModalVisible(false)} variant="primary">OK</Button>
                            </SpaceBetween>
                        </Box>
                    }
                >
                    This feature will be supported in the future.
                    Thank you for your patience.
                </Modal>
            </AppContentLayout>
        </AppLayout>
    );
}