import AppLayout from '../../../components/layout/AppLayout';
import AppContentLayout from '../../../components/layout/ContentLayout';
import { useState } from 'react';
import SearchSideNav from "../../../components/navigation/SearchSideNavigation";
import * as React from "react";
import Cards from "@cloudscape-design/components/cards";
import Box from "@cloudscape-design/components/box";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import TextFilter from "@cloudscape-design/components/text-filter";
import Header from "@cloudscape-design/components/header";
import Pagination from "@cloudscape-design/components/pagination";
import {Link} from "@cloudscape-design/components";
import ButtonDropdown from "@cloudscape-design/components/button-dropdown";
import CollectionPreferences from "@cloudscape-design/components/collection-preferences";
import Modal from "@cloudscape-design/components/modal";
import { useNavigate } from 'react-router-dom';

export default function DataSources() {
    const [activeHref, setActiveHref] = useState('/search/document/datasources');
    const [selectedItems, setSelectedItems] = React.useState([]);
    const [visible, setVisible] = React.useState(false);
    const [items, setItems] = React.useState([
        {
            name: "HR Dataset, Jul2028",
            alt: "First",
            description: "This is the first item",
            type: "AWS S3",
            size: "23",
            created: "2024-07-23 13:52:11",
            s3Path: "s3://hr-dataset-bucket/jul2028/"
        },
        {
            name: "Youtube Crawl Data",
            alt: "Second",
            description: "This is the second item",
            type: "AWS S3",
            size: "412",
            created: "2024-07-25 01:10:52",
            s3Path: "s3://youtube-crawl-bucket/data/"
        },
        {
            name: "HR Operation dataset",
            alt: "Third",
            description: "This is the third item",
            type: "AWS S3",
            size: "3",
            created: "2024-07-29 16:24:38",
            s3Path: "s3://hr-operation-bucket/dataset/"
        },
    ]);

    const navigate = useNavigate();

    const handleFollow = (event) => {
        if (!event.detail.external) {
            event.preventDefault();
            setActiveHref(event.detail.href);
        }
    };

    const handleDelete = () => {
        setVisible(true);
    };

    const confirmDelete = () => {
        setItems(items.filter(item => !selectedItems.includes(item)));
        setSelectedItems([]);
        setVisible(false);
    };

    const onItemClick = ({ detail }) => {
        if (detail.id === 'rm') {
            handleDelete();
        } else if (detail.id === 'edit') {
            if (selectedItems.length === 1) {
                handleNameClick(selectedItems[0]);
            }
        }
    };

    const handleNameClick = (item) => {
        navigate(`/search/document/datasources/create?name=${encodeURIComponent(item.name)}&description=${encodeURIComponent(item.description)}&type=${encodeURIComponent(item.type)}&s3Path=${encodeURIComponent(item.s3Path)}`);
    };

    return (
        <AppLayout
            breadcrumbs_props={{
                items:[
                    { text: 'Search', href: '/search/index/indices' },
                    { text: 'Document', href: '/search/document/datasources' },
                    { text: 'Data Sources', href: '/search/document/datasources' },
                ]
            }}
            side_nav={
                <SearchSideNav
                    activeHref={activeHref}
                    onFollow={handleFollow}>
                </SearchSideNav>}
        >
            <AppContentLayout>
                <Cards
                    onSelectionChange={({ detail }) =>
                        setSelectedItems(detail?.selectedItems ?? [])
                    }
                    selectedItems={selectedItems}
                    ariaLabels={{
                        itemSelectionLabel: (e, t) => `select ${t.name}`,
                        selectionGroupLabel: "Item selection"
                    }}
                    entireCardClickable
                    cardDefinition={{
                        header: item => (
                            <Link href={`/search/document/datasources/create?name=${encodeURIComponent(item.name)}&description=${encodeURIComponent(item.description)}&type=${encodeURIComponent(item.type)}&s3Path=${encodeURIComponent(item.s3Path)}`} fontSize="heading-m" variant="primary">
                                {item.name}
                            </Link>
                        ),
                        sections: [
                            {
                                id: "description",
                                header: "Description",
                                content: item => item.description
                            },
                            {
                                id: "type",
                                header: "Source Storage",
                                content: item => item.type
                            },
                            {
                                id: "size",
                                header: "Document Count",
                                content: item => item.size
                            },
                            {
                                id: "created",
                                header: "Created",
                                content: item => item.created
                            },
                            {
                                id: "s3Path",
                                header: "S3 Path",
                                content: item => item.s3Path
                            }
                        ]
                    }}
                    cardsPerRow={[
                        { cards: 1 },
                        { minWidth: 500, cards: 2 }
                    ]}
                    items={items}
                    loadingText="Loading resources"
                    selectionType="single"
                    trackBy="name"
                    visibleSections={["description", "type", "size", "created", "s3Path"]}
                    stickyHeader
                    variant="full-page"
                    empty={
                        <Box
                            margin={{ vertical: "xs" }}
                            textAlign="center"
                            color="inherit"
                        >
                            <SpaceBetween size="m">
                                <b>No resources</b>
                                <Button>Create resource</Button>
                            </SpaceBetween>
                        </Box>
                    }
                    filter={
                        <TextFilter filteringPlaceholder="Find resources" />
                    }
                    header={
                        <Header
                            variant="awsui-h1-sticky"
                            description="Set up a Data Source to create the index."
                            info={<Link variant="info">Info</Link>}
                            counter={
                                selectedItems?.length
                                    ? "(" + selectedItems.length + "/" + items.length + ")"
                                    : "(" + items.length + ")"
                            }
                            actions={
                                <SpaceBetween
                                    direction="horizontal"
                                    size="xs"
                                >
                                    <ButtonDropdown
                                        items={[
                                            {
                                                text: "Edit",
                                                id: "edit",
                                                disabled: selectedItems.length !== 1
                                            },
                                            {
                                                text: "Delete",
                                                id: "rm",
                                                disabled: selectedItems.length === 0
                                            },
                                        ]}
                                        onItemClick={onItemClick}
                                    >
                                        Actions
                                    </ButtonDropdown>
                                    <Button variant="primary" href="/search/document/datasources/create">
                                        Create Dataset Source
                                    </Button>
                                </SpaceBetween>
                            }
                        >
                            Dataset Sources
                        </Header>
                    }
                    pagination={
                        <Pagination currentPageIndex={1} pagesCount={1} />
                    }
                    preferences={
                        <CollectionPreferences
                            title="Preferences"
                            confirmLabel="Confirm"
                            cancelLabel="Cancel"
                            preferences={{
                                pageSize: 6,
                                visibleContent: [
                                    "description",
                                    "type",
                                    "size",
                                    "created",
                                    "s3Path"
                                ]
                            }}
                            pageSizePreference={{
                                title: "Page size",
                                options: [
                                    { value: 6, label: "6 resources" },
                                    { value: 12, label: "12 resources" }
                                ]
                            }}
                            visibleContentPreference={{
                                title: "Select visible content",
                                options: [
                                    {
                                        label: "Main distribution properties",
                                        options: [
                                            {
                                                id: "description",
                                                label: "Description"
                                            },
                                            { id: "type", label: "Type" },
                                            { id: "size", label: "Size" },
                                            { id: "created", label: "Created" },
                                            { id: "s3Path", label: "S3 Path" }
                                        ]
                                    }
                                ]
                            }}
                        />
                    }
                />
                <Modal
                    onDismiss={() => setVisible(false)}
                    visible={visible}
                    header="Confirm deletion"
                    footer={
                        <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button onClick={() => setVisible(false)} variant="link">Cancel</Button>
                                <Button onClick={confirmDelete} variant="primary">Delete</Button>
                            </SpaceBetween>
                        </Box>
                    }
                >
                    Are you sure you want to delete {selectedItems.length} item(s)?
                </Modal>
            </AppContentLayout>
        </AppLayout>
    );
}