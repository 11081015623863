import React, { useState } from 'react';
import AppLayout from '../../../components/layout/AppLayout';
import AppContentLayout from '../../../components/layout/ContentLayout';
import MonitoringSideNav from '../../../components/navigation/MonitoringSideNavigation';
import {
    Button,
    Container,
    Header,
    Link,
    SpaceBetween,
    Table,
    TextFilter,
    ButtonDropdown,
    Pagination,
    CollectionPreferences,
    Modal,
} from '@cloudscape-design/components';
import Box from '@cloudscape-design/components/box';

export default function PipelineSettings() {
    const [activeHref, setActiveHref] = useState('/evaluation/pipelinesettings');
    const [selectedItems, setSelectedItems] = useState([]);
    const [visible, setVisible] = useState(false);
    const [pipelines, setPipelines] = useState([
        {
            name: 'youtube-evaluation-daily',
            dataSources: 'Youtube Crawl Data',
            batchTimes: <SpaceBetween size="xxs"><box>09:00, 15:00</box></SpaceBetween>,
            created: '2024-07-20 11:32:06',
        },
        {
            name: 'hr-operation-pipeline-1',
            dataSources: 'HR Operation dataset',
            batchTimes: <SpaceBetween size="xxs"><box>12:00</box></SpaceBetween>,
            created: '2024-07-18 12:45:24',
        },
        {
            name: 'hr-all-eval-ver1.2',
            dataSources: 'HR Dataset, HR Operation dataset',
            batchTimes: <SpaceBetween size="xxs">
                <box>03:00, 06:00, 09:00</box><box>12:00, 15:00, 18:00</box><box>21:00</box>
            </SpaceBetween>,
            created: '2024-07-15 00:20:30',
        },
    ]);

    const handleFollow = (event) => {
        if (!event.detail.external) {
            event.preventDefault();
            setActiveHref(event.detail.href);
        }
    };

    const handleDelete = () => {
        const updatedPipelines = pipelines.filter(
            (pipeline) => !selectedItems.some((item) => item.name === pipeline.name)
        );
        setPipelines(updatedPipelines);
        setSelectedItems([]);
        setVisible(false);
    };

    const handleItemClick = ({ detail }) => {
        if (detail.id === 'rm') {
            setVisible(true);
        }
    };

    return (
        <AppLayout
            breadcrumbs_props={{
                items: [
                    { text: 'Evaluation', href: '/evaluation/dashboards' },
                    { text: 'Pipeline Settings', href: '/evaluation/pipelinesettings' },
                ],
            }}
            side_nav={
                <MonitoringSideNav activeHref={activeHref} onFollow={handleFollow} />
            }
        >
            <AppContentLayout
                header_layout_props={{
                    text: 'Pipeline Settings',
                    description: 'Manage evaluation pipelines.',
                }}
            >
                <SpaceBetween size="l">
                    <Table
                        renderAriaLive={({ firstIndex, lastIndex, totalItemsCount }) =>
                            `Displaying items ${firstIndex} to ${lastIndex} of ${totalItemsCount}`
                        }
                        onSelectionChange={({ detail }) => setSelectedItems(detail.selectedItems)}
                        selectedItems={selectedItems}
                        ariaLabels={{
                            selectionGroupLabel: 'Items selection',
                            allItemsSelectionLabel: ({ selectedItems }) =>
                                `${selectedItems.length} ${
                                    selectedItems.length === 1 ? 'item' : 'items'
                                } selected`,
                            itemSelectionLabel: ({ selectedItems }, item) => item.name,
                        }}
                        columnDefinitions={[
                            {
                                id: 'name',
                                header: 'Pipeline Name',
                                cell: (e) => e.name,
                                sortingField: 'name',
                                isRowHeader: true,
                            },
                            {
                                id: 'dataSources',
                                header: 'Data Sources',
                                cell: (e) => e.dataSources,
                                sortingField: 'dataSources',
                            },
                            {
                                id: 'batchTimes',
                                header: 'Batch Times',
                                cell: (e) => e.batchTimes,
                                sortingField: 'batchTimes',
                            },
                            {
                                id: 'created',
                                header: 'Created',
                                cell: (e) => e.created,
                                sortingField: 'created',
                            },
                        ]}
                        items={pipelines}
                        loadingText="Loading pipelines"
                        selectionType="multi"
                        trackBy="name"
                        empty={
                            <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
                                <SpaceBetween size="m">
                                    <b>No pipelines</b>
                                    <Button href="/evaluation/pipelinesettings/create">
                                        Create pipeline
                                    </Button>
                                </SpaceBetween>
                            </Box>
                        }
                        filter={
                            <TextFilter filteringPlaceholder="Find pipelines" filteringText="" />
                        }
                        header={
                            <Header
                                counter={
                                    selectedItems.length
                                        ? `(${selectedItems.length}/${pipelines.length})`
                                        : `(${pipelines.length})`
                                }
                                actions={
                                    <SpaceBetween direction="horizontal" size="xs">
                                        <ButtonDropdown
                                            items={[
                                                {
                                                    text: 'Delete',
                                                    id: 'rm',
                                                    disabled: selectedItems.length === 0,
                                                },
                                            ]}
                                            onItemClick={handleItemClick}
                                        >
                                            Actions
                                        </ButtonDropdown>
                                        <Button variant="primary" href="/evaluation/pipelinesettings/create">
                                            Create Pipeline
                                        </Button>
                                    </SpaceBetween>
                                }
                            >
                                Pipelines
                            </Header>
                        }
                        pagination={<Pagination currentPageIndex={1} pagesCount={2} />}
                        preferences={
                            <CollectionPreferences
                                title="Preferences"
                                confirmLabel="Confirm"
                                cancelLabel="Cancel"
                                preferences={{
                                    pageSize: 10,
                                    contentDisplay: [
                                        { id: 'name', visible: true },
                                        { id: 'dataSources', visible: true },
                                        { id: 'batchTimes', visible: true },
                                        { id: 'created', visible: true },
                                    ],
                                }}
                                pageSizePreference={{
                                    title: 'Page size',
                                    options: [
                                        { value: 10, label: '10 pipelines' },
                                        { value: 20, label: '20 pipelines' },
                                    ],
                                }}
                                wrapLinesPreference={{}}
                                stripedRowsPreference={{}}
                                contentDensityPreference={{}}
                                contentDisplayPreference={{
                                    options: [
                                        {
                                            id: 'name',
                                            label: 'Pipeline name',
                                            alwaysVisible: true,
                                        },
                                        { id: 'dataSources', label: 'Data Sources' },
                                        { id: 'batchTimes', label: 'Batch Times' },
                                        { id: 'created', label: 'Created' },
                                    ],
                                }}
                                stickyColumnsPreference={{
                                    firstColumns: {
                                        title: 'Stick first column(s)',
                                        description:
                                            'Keep the first column(s) visible while horizontally scrolling the table content.',
                                        options: [
                                            { label: 'None', value: 0 },
                                            { label: 'First column', value: 1 },
                                            { label: 'First two columns', value: 2 },
                                        ],
                                    },
                                    lastColumns: {
                                        title: 'Stick last column',
                                        description:
                                            'Keep the last column visible while horizontally scrolling the table content.',
                                        options: [
                                            { label: 'None', value: 0 },
                                            { label: 'Last column', value: 1 },
                                        ],
                                    },
                                }}
                            />
                        }
                        onRowClick={({ detail }) => {
                            if (detail.item) {
                                const newSelectedItems = selectedItems.some(item => item.name === detail.item.name)
                                    ? selectedItems.filter(item => item.name !== detail.item.name)
                                    : [...selectedItems, detail.item];
                                setSelectedItems(newSelectedItems);
                            }
                        }}
                    />
                </SpaceBetween>
            </AppContentLayout>
            <Modal
                onDismiss={() => setVisible(false)}
                visible={visible}
                header="Confirm deletion"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button onClick={() => setVisible(false)} variant="link">Cancel</Button>
                            <Button onClick={handleDelete} variant="primary">Delete</Button>
                        </SpaceBetween>
                    </Box>
                }
            >
                Are you sure you want to delete {selectedItems.length} selected item(s)?
            </Modal>
        </AppLayout>
    );
}