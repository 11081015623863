import AppLayout from '../../../../components/layout/AppLayout';
import AppContentLayout from '../../../../components/layout/ContentLayout';
import { useState, useEffect } from 'react';
import SearchSideNav from "../../../../components/navigation/SearchSideNavigation";
import {
    Container,
    FormField,
    Header, Input,
    KeyValuePairs,
    Link, Multiselect,
    RadioGroup, Slider,
    StatusIndicator,
    Textarea,
    Tiles,
    Modal
} from "@cloudscape-design/components";
import * as PropTypes from "prop-types";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import Box from "@cloudscape-design/components/box";
import TextFilter from "@cloudscape-design/components/text-filter";
import ButtonDropdown from "@cloudscape-design/components/button-dropdown";
import Pagination from "@cloudscape-design/components/pagination";
import CollectionPreferences from "@cloudscape-design/components/collection-preferences";
import Table from "@cloudscape-design/components/table";
import * as React from "react";
import { useLocation, useNavigate } from 'react-router-dom';

function InfoLink(props) {
    return null;
}

InfoLink.propTypes = {
    onFollow: PropTypes.func,
    id: PropTypes.string
};

export default function BaseModelCreation() {
    const [activeHref, setActiveHref] = useState('/search/rankingmodel/models');
    const [sliderValue, setSliderValue] = React.useState(50);
    const [nameValue, setNameValue] = React.useState("");
    const [selectedOptions, setSelectedOptions] = React.useState([]);
    const [selectedOptions2, setSelectedOptions2] = React.useState([]);
    const [searchType, setSearchType] = React.useState("embedding");
    const [visible, setVisible] = React.useState(false);

    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);

    useEffect(() => {
        setNameValue(queryParams.get('name') || "");
        setSliderValue(parseInt(queryParams.get('lexical_weight')) || 50);
        setSearchType(queryParams.get('search_type') || "embedding");

        const queryEnhancements = JSON.parse(queryParams.get('query') || "[]");
        setSelectedOptions(queryEnhancements.map(enhancement => ({
            label: enhancement,
            value: enhancement
        })));

        const documentProcessing = JSON.parse(queryParams.get('document') || "[]");
        setSelectedOptions2(documentProcessing.map(process => ({
            label: process,
            value: process
        })));
    }, [location.search]);

    const handleFollow = (event) => {
        if (!event.detail.external) {
            event.preventDefault();
            setActiveHref(event.detail.href);
        }
    };

    const handleAddNewModel = () => {
        const newModel = {
            name: nameValue,
            searchType: searchType,
            lexicalWeight: searchType === "hybrid" ? sliderValue : null,
            semanticWeight: searchType === "hybrid" ? 100 - sliderValue : null,
            queryEnhancements: selectedOptions.map(option => option.value),
            documentProcessing: selectedOptions2.map(option => option.value),
        };
        console.log("New model:", newModel);
        setVisible(true);
    };

    return (
        <AppLayout
            breadcrumbs_props={{
                items:[
                    { text: 'Search', href: '/search/index/indices' },
                    { text: 'Ranking Model', href: '/search/rankingmodel/models' },
                    { text: 'Basic Model', href: '/search/rankingmodel/models' },
                    { text: 'Create', href: '/search/rankingmodel/models' },
                ]
            }}
            side_nav={
                <SearchSideNav
                    activeHref={activeHref}
                    onFollow={handleFollow}>
                </SearchSideNav>}
        >
            <AppContentLayout
                header_layout_props={{
                    text: "Basic Model",
                    description: "You can set up and utilize ranking models based on basic features."
                }}
            >
                <SpaceBetween size="l">
                    <Container
                        header={
                            <Header
                                variant="h2"
                                actions={
                                    <SpaceBetween
                                        direction="horizontal"
                                        size="xs"
                                    >
                                        <Button variant="primary" onClick={handleAddNewModel}>Save</Button>
                                        <Button onClick={() => navigate('/search/rankingmodel/models')}>Cancel</Button>
                                    </SpaceBetween>
                                }>
                                New Ranking Model
                            </Header>
                        }>
                        <SpaceBetween size="xl">
                            <FormField
                                label={
                                    <>
                                        Model Name
                                    </>
                                }
                            >
                                <Input
                                    onChange={({ detail }) => setNameValue(detail.value)}
                                    value={nameValue}
                                    placeholder="Enter Model Name"
                                />
                            </FormField>
                            <FormField
                                label="Search Type"
                                description="Choose between Embedding Search and Hybrid Search"
                            >
                                <Tiles
                                    onChange={({ detail }) => setSearchType(detail.value)}
                                    value={searchType}
                                    items={[
                                        {
                                            label: "Embedding Search",
                                            description: "Use only semantic search",
                                            value: "embedding"
                                        },
                                        {
                                            label: "Hybrid Search",
                                            description: "Combine lexical and semantic search",
                                            value: "hybrid"
                                        }
                                    ]}
                                />
                            </FormField>
                            {searchType === "hybrid" && (
                                <>
                                    <FormField
                                        label="Lexical Weight"
                                        info={<Link variant="info">Info</Link>}
                                        description="This refers to the weight of the ranking based on words. It is important when a search should be performed based on specific words."
                                        stretch={true}
                                    >
                                        <Slider
                                            onChange={({ detail }) => setSliderValue(detail.value)}
                                            value={sliderValue}
                                            valueFormatter={value => value + "%"}
                                            max={100}
                                            min={0}
                                            step={10}
                                            tickMarks
                                        />
                                    </FormField>
                                    <FormField
                                        label="Semantic Weight"
                                        info={<Link variant="info">Info</Link>}
                                        description="This refers to the ranking importance based on content meaning and context. It focuses on understanding the search intent and conceptual relevance."
                                        constraintText="Lexical weight + Semantic weight = 100%"
                                        stretch={true}
                                    >
                                        <Slider
                                            onChange={({ detail }) => setSliderValue(100-detail.value)}
                                            value={100-sliderValue}
                                            valueFormatter={value => value + "%"}
                                            max={100}
                                            min={0}
                                            step={10}
                                            tickMarks
                                        />
                                    </FormField>
                                </>
                            )}
                            <FormField
                                label="Query Enhancement"
                                info={<Link variant="info">Info</Link>}
                                description="This refers to the ranking importance based on content meaning and context. It focuses on understanding the search intent and conceptual relevance."
                                stretch={true}
                            >
                                <Multiselect
                                    selectedOptions={selectedOptions}
                                    onChange={({ detail }) =>
                                        setSelectedOptions(detail.selectedOptions)
                                    }
                                    options={[
                                        {
                                            label: "HyDE",
                                            value: "HyDE",
                                            description: "Improving retrieval using Hypothetical Document Embeddings."
                                        },
                                        {
                                            label: "Translate To English",
                                            value: "Translate To English",
                                            description: "Translation into English from any other languages."

                                        },
                                    ]}
                                    placeholder="Select options"
                                />
                            </FormField>
                            <FormField
                                label="Document Processing"
                                info={<Link variant="info">Info</Link>}
                                description="This refers to the ranking importance based on content meaning and context. It focuses on understanding the search intent and conceptual relevance."
                                stretch={true}
                            >
                                <Multiselect
                                    selectedOptions={selectedOptions2}
                                    onChange={({ detail }) =>
                                        setSelectedOptions2(detail.selectedOptions)
                                    }
                                    options={[
                                        {
                                            label: "Parent Chunking",
                                            value: "Parent Chunk",
                                            description: "Using Parental Chunking which includes larger texts."
                                        },
                                        {
                                            label: "Translate To English",
                                            value: "Translate To English",
                                            description: "Translation into English from any other languages."
                                        },
                                    ]}
                                    placeholder="Select options"
                                />
                            </FormField>
                        </SpaceBetween>
                    </Container>
                </SpaceBetween>
                <Modal
                    onDismiss={() => setVisible(false)}
                    visible={visible}
                    header="Your request has been submitted."
                    footer={
                        <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button onClick={() => setVisible(false)} variant="primary">OK</Button>
                            </SpaceBetween>
                        </Box>
                    }
                >
                    This feature will be supported in the future.
                    Thank you for your patience.
                </Modal>
            </AppContentLayout>
        </AppLayout>
    );
}