import AppLayout from '../../../../components/layout/AppLayout';
import AppContentLayout from '../../../../components/layout/ContentLayout';
import { useState } from 'react';
import SearchSideNav from "../../../../components/navigation/SearchSideNavigation";
import {
    Container,
    FormField,
    Header, Input,
    KeyValuePairs,
    Link,
    RadioGroup, Slider, SplitPanel,
    StatusIndicator,
    Textarea,
    Modal,
    Tiles
} from "@cloudscape-design/components";
import * as PropTypes from "prop-types";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import Box from "@cloudscape-design/components/box";
import TextFilter from "@cloudscape-design/components/text-filter";
import ButtonDropdown from "@cloudscape-design/components/button-dropdown";
import Pagination from "@cloudscape-design/components/pagination";
import CollectionPreferences from "@cloudscape-design/components/collection-preferences";
import Table from "@cloudscape-design/components/table";
import * as React from "react";
import { useNavigate } from 'react-router-dom';

function InfoLink(props) {
    return null;
}

InfoLink.propTypes = {
    onFollow: PropTypes.func,
    id: PropTypes.string
};

export default function BasicModel() {
    const navigate = useNavigate();
    const [activeHref, setActiveHref] = useState('/search/rankingmodel/models');
    const [selectedItem, setSelectedItem] = React.useState(null);
    const [splitPanelOpen, setSplitPanelOpen] = useState(false);
    const [sliderValue, setSliderValue] = React.useState(70);
    const [visible, setVisible] = React.useState(false);
    const [items, setItems] = React.useState([
        {
            name: "Word boosted ver",
            lexical_weight: 70,
            semantic_weight: 30,
            alt: "Lexical: 70%, Semantic: 30%",
            query: ["HyDE", "Translate To English"],
            document: ["Parent Chunk"],
            created: "2024-07-20 11:32:06",
            search_type: "hybrid"
        },
        {
            name: "Semantic boosted version",
            lexical_weight: 30,
            semantic_weight: 70,
            alt: "Lexical: 30%, Semantic: 70%",
            query: ["HyDE"],
            document: ["Parent Chunk"],
            created: "2024-07-18 12:45:24",
            search_type: "hybrid"
        },
        {
            name: "System Generated",
            lexical_weight: 50,
            semantic_weight: 50,
            alt: "Embedding Search",
            query: [],
            document: [],
            created: "2024-07-15 00:20:30",
            search_type: "embedding"
        },
    ]);

    const handleFollow = (event) => {
        if (!event.detail.external) {
            event.preventDefault();
            setActiveHref(event.detail.href);
        }
    };

    const handleDelete = () => {
        if (selectedItem) {
            setVisible(true);
        }
    };

    const confirmDelete = () => {
        setItems(items.filter(item => item !== selectedItem));
        setSelectedItem(null);
        setVisible(false);
    };

    const handleCreateClick = () => {
        navigate('/search/rankingmodel/models/create');
    };

    const onItemClick = ({ detail }) => {
        if (detail.id === 'rm') {
            handleDelete();
        } else if (detail.id === 'edit') {
            navigateToEdit(selectedItem);
        }
    };

    const navigateToEdit = (item) => {
        if (item) {
            const queryParams = new URLSearchParams({
                name: item.name,
                lexical_weight: item.lexical_weight,
                semantic_weight: item.semantic_weight,
                query: JSON.stringify(item.query),
                document: JSON.stringify(item.document),
                search_type: item.search_type
            }).toString();
            navigate(`/search/rankingmodel/models/create?${queryParams}`);
        } else {
            navigate('/search/rankingmodel/models/create');
        }
    };

    return (
        <AppLayout
            breadcrumbs_props={{
                items:[
                    { text: 'Search', href: '/search/index/indices' },
                    { text: 'Ranking Model', href: '/search/rankingmodel/models' },
                    { text: 'Basic Model', href: '/search/rankingmodel/models' },
                ]
            }}
            side_nav={
                <SearchSideNav
                    activeHref={activeHref}
                    onFollow={handleFollow}>
                </SearchSideNav>}

        >
            <AppContentLayout
                header_layout_props={{
                    text: "Basic Model",
                    description: "You can set up and utilize ranking models based on basic features."
                }}
            >
                <SpaceBetween size="l">
                    <Table
                        renderAriaLive={({
                                             firstIndex,
                                             lastIndex,
                                             totalItemsCount
                                         }) =>
                            `Displaying items ${firstIndex}to ${lastIndex} of ${totalItemsCount}`
                        }
                        selectedItems={selectedItem ? [selectedItem] : []}
                        selectionType="single"
                        ariaLabels={{
                            selectionGroupLabel: "Items selection",
                            allItemsSelectionLabel: ({ selectedItems }) =>
                                `${selectedItems.length} ${
                                    selectedItems.length === 1 ? "item" : "items"
                                } selected`,
                            itemSelectionLabel: ({ selectedItems }, item) =>
                                item.name
                        }}
                        columnDefinitions={[
                            {
                                id: "variable",
                                header: "Model Name",
                                cell: e => {
                                    const queryParams = new URLSearchParams({
                                        name: e.name,
                                        lexical_weight: e.lexical_weight,
                                        semantic_weight: e.semantic_weight,
                                        query: JSON.stringify(e.query),
                                        document: JSON.stringify(e.document),
                                        search_type: e.search_type
                                    }).toString();

                                    return <Link href={`/search/rankingmodel/models/create?${queryParams}`}>{e.name}</Link>
                                },
                                sortingField: "name",
                                isRowHeader: true
                            },
                            {
                                id: "value",
                                header: "Hybrid Weights",
                                cell: e => e.alt,
                                sortingField: "alt"
                            },
                            { id: "lexical_weight", header: "lexical_weight", cell: e => e.lexical_weight },
                            { id: "semantic_weight", header: "semantic_weight", cell: e => e.semantic_weight },
                            { id: "query", header: "Query Enhancements", cell: e => e.query.join(", ") },
                            { id: "document", header: "Document Processing", cell: e => e.document.join(", ") },
                            {
                                id: "created",
                                header: "Created",
                                cell: e => e.created
                            },
                        ]}
                        columnDisplay={[
                            { id: "variable", visible: true },
                            { id: "value", visible: true },
                            { id: "query", visible: true },
                            { id: "document", visible: true },
                            { id: "created", visible: true },
                        ]}
                        enableKeyboardNavigation
                        items={items}
                        loadingText="Loading resources"
                        trackBy="name"
                        empty={
                            <Box
                                margin={{ vertical: "xs" }}
                                textAlign="center"
                                color="inherit"
                            >
                                <SpaceBetween size="m">
                                    <b>No models</b>
                                    <Button>Create resource</Button>
                                </SpaceBetween>
                            </Box>
                        }
                        filter={
                            <TextFilter
                                filteringPlaceholder="Find resources"
                                filteringText=""
                            />
                        }
                        header={
                            <Header
                                counter={
                                    selectedItem
                                        ? "(1/" + items.length + ")"
                                        : "(" + items.length + ")"
                                }
                                actions={
                                    <SpaceBetween
                                        direction="horizontal"
                                        size="xs"
                                    >
                                        <ButtonDropdown
                                            items={[
                                                {
                                                    text: "Edit",
                                                    id: "edit",
                                                    disabled: !selectedItem
                                                },
                                                {
                                                    text: "Delete",
                                                    id: "rm",
                                                    disabled: !selectedItem
                                                },
                                            ]}
                                            onItemClick={onItemClick}
                                        >
                                            Actions
                                        </ButtonDropdown>
                                        <Button variant="primary" onClick={handleCreateClick}>Create Model</Button>
                                    </SpaceBetween>
                                }
                            >
                                Basic Models
                            </Header>
                        }
                        pagination={
                            <Pagination currentPageIndex={1} pagesCount={1} />
                        }
                        preferences={
                            <CollectionPreferences
                                title="Preferences"
                                confirmLabel="Confirm"
                                cancelLabel="Cancel"
                                preferences={{
                                    pageSize: 10,
                                    contentDisplay: [
                                        { id: "variable", visible: true },
                                        { id: "value", visible: true },
                                        { id: "type", visible: true },
                                        { id: "created", visible: true },
                                        { id: "updated", visible: true }
                                    ]
                                }}
                                pageSizePreference={{
                                    title: "Page size",
                                    options: [
                                        { value: 10, label: "10 resources" },
                                        { value: 20, label: "20 resources" }
                                    ]
                                }}
                                wrapLinesPreference={{}}
                                stripedRowsPreference={{}}
                                contentDensityPreference={{}}
                                contentDisplayPreference={{
                                    options: [
                                        {
                                            id: "variable",
                                            label: "Variable name",
                                            alwaysVisible: true
                                        },
                                        { id: "value", label: "Text value" },
                                        { id: "type", label: "Type" },
                                        { id: "created", label: "Created" },
                                        { id: "updated", label: "Updated" },
                                    ]
                                }}
                                stickyColumnsPreference={{
                                    firstColumns: {
                                        title: "Stick first column(s)",
                                        description:
                                            "Keep the first column(s) visible while horizontally scrolling the table content.",
                                        options: [
                                            { label: "None", value: 0 },
                                            { label: "First column", value: 1 },
                                            { label: "First two columns", value: 2 }
                                        ]
                                    },
                                    lastColumns: {
                                        title: "Stick last column",
                                        description:
                                            "Keep the last column visible while horizontally scrolling the table content.",
                                        options: [
                                            { label: "None", value: 0 },
                                            { label: "Last column", value: 1 }
                                        ]
                                    }
                                }}
                            />
                        }
                        onSelectionChange={({ detail }) => {
                            setSelectedItem(detail.selectedItems[0]);
                            setSplitPanelOpen(true);
                        }}
                        onRowClick={({ detail }) => {
                            setSelectedItem(detail.item);
                            setSplitPanelOpen(true);
                        }}
                    />
                </SpaceBetween>
            </AppContentLayout>
            <Modal
                onDismiss={() => setVisible(false)}
                visible={visible}
                header="Confirm deletion"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button onClick={() => setVisible(false)} variant="link">Cancel</Button>
                            <Button onClick={confirmDelete} variant="primary">Delete</Button>
                        </SpaceBetween>
                    </Box>

                }
            >
                Are you sure you want to delete the selected item?
            </Modal>
        </AppLayout>

    );
}