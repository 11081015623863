import AppLayout from '../../../components/layout/AppLayout';
import AppContentLayout from '../../../components/layout/ContentLayout';
import { useState } from 'react';
import MonitoringSideNav from "../../../components/navigation/MonitoringSideNavigation";
import {
    Container,
    FormField,
    Header,
    SpaceBetween,
    Table,
    Button,
    TextFilter,
    CollectionPreferences,
    Pagination,
    ButtonDropdown,
    Modal,
    Box
} from "@cloudscape-design/components";
import FileUpload from "@cloudscape-design/components/file-upload";

export default function UserEvaluationDataset() {
    const [activeHref, setActiveHref] = useState('/evaluation/userdataset');
    const [selectedItems, setSelectedItems] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [visible, setVisible] = useState(false);
    const [tableItems, setTableItems] = useState([
        {
            name: "additional_eval_data.txt",
            size: "500 KB",
            uploadedAt: "2023-06-10 09:15:00",
        },
        {
            name: "eval_dataset_v2.json",
            size: "1.8 MB",
            uploadedAt: "2023-06-05 14:45:30",
        },
        {
            name: "eval_dataset_v1.csv",
            size: "2.5 MB",
            uploadedAt: "2023-06-01 10:30:00",
        },
    ]);

    const handleFollow = (event) => {
        if (!event.detail.external) {
            event.preventDefault();
            setActiveHref(event.detail.href);
        }
    };

    const handleFileChange = ({ detail }) => {
        const invalidFiles = detail.value.filter(file =>
            !['csv', 'json', 'txt'].includes(file.name.split('.').pop().toLowerCase())
        );

        if (invalidFiles.length > 0) {
            setVisible(true);
            // Remove invalid files
            const validFiles = detail.value.filter(file =>
                ['csv', 'json', 'txt'].includes(file.name.split('.').pop().toLowerCase())
            );
            setUploadedFiles(validFiles);
        } else {
            setUploadedFiles(detail.value);
        }
    };

    const handleUpload = () => {
        const newItems = uploadedFiles.map(file => {
            const now = new Date();
            const formattedDate = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')} ${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}:${String(now.getSeconds()).padStart(2, '0')}`;
            return {
                name: file.name,
                size: `${(file.size / 1024 / 1024).toFixed(2)} MB`,
                uploadedAt: formattedDate,
            };
        });
        setTableItems([...newItems, ...tableItems]);
        setUploadedFiles([]); // 업로드 후 파일 목록 초기화
    };

    return (
        <AppLayout
            breadcrumbs_props={{
                items:[
                    { text: 'Evaluation', href: '/evaluation/chatbot' },
                    { text: 'Dataset', href: '/evaluation/userdataset' },
                    { text: 'User Defined Dataset', href: '/evaluation/userdataset' },
                ]
            }}
            side_nav={
                <MonitoringSideNav
                    activeHref={activeHref}
                    onFollow={handleFollow}>
                </MonitoringSideNav>
            }
        >
            <AppContentLayout
                header_layout_props={{
                    text: "User Defined Dataset",
                    description: "Upload evaluation datasets for chatbot performance testing."
                }}
            >
                <SpaceBetween size="l">
                    <Container
                        header={
                            <Header
                                variant="h2"
                                actions={
                                    <SpaceBetween
                                        direction="horizontal"
                                        size="xs"
                                    >
                                        <Button variant="primary" onClick={handleUpload}>Upload</Button>
                                        <Button onClick={() => setUploadedFiles([])}>Cancel</Button>
                                    </SpaceBetween>
                                }>
                                File Upload
                            </Header>
                        }>
                        <FormField
                            label="Upload Evaluation Dataset files"
                            description="Upload files containing evaluation datasets. Supported file formats: CSV, JSON, TXT."
                        >
                            <FileUpload
                                onChange={handleFileChange}
                                value={uploadedFiles}
                                i18nStrings={{
                                    uploadButtonText: e =>
                                        e ? "Choose files" : "Choose file",
                                    dropzoneText: e =>
                                        e
                                            ? "Drop files to upload"
                                            : "Drop file to upload",
                                    removeFileAriaLabel: e =>
                                        `Remove file ${e + 1}`,
                                    limitShowFewer: "Show fewer files",
                                    limitShowMore: "Show more files",
                                    errorIconAriaLabel: "Error"
                                }}
                                multiple
                                acceptedFileTypes={['csv', 'json', 'txt']}
                            />
                        </FormField>
                    </Container>

                    <Table
                        onSelectionChange={({ detail }) =>
                            setSelectedItems(detail.selectedItems)
                        }
                        selectedItems={selectedItems}
                        columnDefinitions={[
                            {
                                id: "name",
                                header: "File Name",
                                cell: e => e.name,
                                sortingField: "name",
                                isRowHeader: true
                            },
                            {
                                id: "size",
                                header: "File Size",
                                cell: e => e.size,
                                sortingField: "size"
                            },
                            {
                                id: "uploadedAt",
                                header: "Uploaded At",
                                cell: e => e.uploadedAt
                            },
                        ]}
                        items={tableItems}
                        loadingText="Loading files"
                        selectionType="multi"
                        trackBy="name"
                        empty={
                            <Container
                                header={
                                    <Header variant="h2">
                                        No files uploaded
                                    </Header>
                                }>
                                <SpaceBetween direction="vertical" size="s">
                                    <Button variant="primary">
                                        Upload a file
                                    </Button>
                                    <span>
                                        Or drag and drop files to upload
                                    </span>
                                </SpaceBetween>
                            </Container>
                        }
                        filter={
                            <TextFilter
                                filteringPlaceholder="Find files"
                                filteringText=""
                            />
                        }
                        header={
                            <Header
                                counter={
                                    selectedItems.length
                                        ? `(${selectedItems.length}/${tableItems.length})`
                                        : `(${tableItems.length})`
                                }
                                actions={
                                    <SpaceBetween
                                        direction="horizontal"
                                        size="xs"
                                    >
                                        <ButtonDropdown
                                            items={[
                                                {
                                                    text: "Delete",
                                                    id: "delete",
                                                    disabled: selectedItems.length === 0
                                                },
                                            ]}
                                        >
                                            Actions
                                        </ButtonDropdown>
                                    </SpaceBetween>
                                }
                            >
                                Uploaded Files
                            </Header>
                        }
                        pagination={
                            <Pagination
                                currentPageIndex={1}
                                pagesCount={Math.ceil(tableItems.length / 10)}
                            />
                        }
                        preferences={
                            <CollectionPreferences
                                title="Preferences"
                                confirmLabel="Confirm"
                                cancelLabel="Cancel"
                                preferences={{
                                    pageSize: 10,
                                    contentDisplay: [
                                        { id: "name", visible: true },
                                        { id: "size", visible: true },
                                        { id: "uploadedAt", visible: true },
                                    ]
                                }}
                                pageSizePreference={{
                                    title: "Page size",
                                    options: [
                                        { value: 10, label: "10 files" },
                                        { value: 20, label: "20 files" }
                                    ]
                                }}
                            />
                        }
                    />
                </SpaceBetween>
            </AppContentLayout>

            <Modal
                onDismiss={() => setVisible(false)}
                visible={visible}
                header="Invalid file format"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button onClick={() => setVisible(false)} variant="primary">OK</Button>
                        </SpaceBetween>
                    </Box>
                }
            >
                Only CSV, JSON, and TXT files are supported.
                Please select files with these formats.
            </Modal>
        </AppLayout>
    );
}