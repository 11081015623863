import BaseSideNav from "./BaseSideNavigation";
import {Box, Popover} from "@cloudscape-design/components";
import {Route} from "react-router-dom";
import NoticeMessages from "../../pages/application/application/NoticeMessages";
import SystemPrompts from "../../pages/application/application/SystemPrompts";
import React from "react";

export default function ServiceSideNavigation( props ) {
    return (
        <BaseSideNav
            activeHref={props.activeHref}
            header={{
                href: '/application/chatbot',
                text: 'Application',
            }}
            items={[
                {
                    type: "section",
                    text: "Application",
                    items: [
                        {
                            type: "link", text: "Application Management", href: "/application/app/management"
                        },
                        {
                            type: "link", text: "System Prompts", href: "/application/app/systemprompts"
                        },
                        {
                            type: "link", text: "Notice Messages", href: "/application/app/notices"
                        },
                        { type: "divider" },
                        {
                            type: "link",
                            text: "Run Application",
                            href: "https://www.google.com",
                            external: true
                        },
                        {
                            type: "link",
                            text: "API Specification",
                            href: "https://chat-api-tutorial.vedro.io/docs",
                            external: true
                        },
                    ]
                }
            ]}
        />
    );
}
