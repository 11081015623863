import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppLayout from '../../../components/layout/AppLayout';
import AppContentLayout from '../../../components/layout/ContentLayout';
import SearchSideNav from "../../../components/navigation/SearchSideNavigation";
import {
    Container,
    Header,
    SpaceBetween,
    Button,
    FormField,
    Input,
    Select,
    Textarea,
    Multiselect,
    Wizard,
    TimeInput,
    ColumnLayout
} from "@cloudscape-design/components";

export default function CreateIndex() {
    const navigate = useNavigate();
    const [activeHref, setActiveHref] = useState('/search/index/indices');
    const [activeStepIndex, setActiveStepIndex] = useState(0);
    const [indexName, setIndexName] = useState('');
    const [selectedLLMSettings, setSelectedLLMSettings] = useState(null);
    const [selectedEmbeddingSettings, setSelectedEmbeddingSettings] = useState(null);
    const [selectedDictionary, setSelectedDictionary] = useState(null);
    const [selectedDataSources, setSelectedDataSources] = useState([]);
    const [autoIndexingOption, setAutoIndexingOption] = useState({ label: 'Disabled', value: 'disabled' });
    const [batchTimes, setBatchTimes] = useState(['']);

    const handleFollow = (event) => {
        if (!event.detail.external) {
            event.preventDefault();
            setActiveHref(event.detail.href);
        }
    };

    const handleIndexNameChange = (event) => {
        setIndexName(event.detail.value);
    };

    const handleLLMSettingsChange = (event) => {
        setSelectedLLMSettings(event.detail.selectedOption);
    };

    const handleEmbeddingSettingsChange = (event) => {
        setSelectedEmbeddingSettings(event.detail.selectedOption);
    };

    const handleDictionaryChange = (event) => {
        setSelectedDictionary(event.detail.selectedOption);
    };

    const handleDataSourcesChange = (event) => {
        setSelectedDataSources(event.detail.selectedOptions);
    };

    const handleAutoIndexingOptionChange = (event) => {
        setAutoIndexingOption(event.detail.selectedOption);
    };

    const handleBatchTimeChange = (index, value) => {
        const updatedTimes = [...batchTimes];
        updatedTimes[index] = value;
        setBatchTimes(updatedTimes);
    };

    const handleAddBatchTime = () => {
        setBatchTimes([...batchTimes, '']);
    };

    const handleRemoveBatchTime = (index) => {
        const updatedTimes = batchTimes.filter((_, i) => i !== index);
        setBatchTimes(updatedTimes);
    };

    const isStepValid = (stepIndex) => {
        switch (stepIndex) {
            case 0:
                return indexName.trim() !== '';
            case 1:
                return selectedLLMSettings !== null && selectedEmbeddingSettings !== null;
            case 2:
                return selectedDictionary !== null;
            case 3:
                return selectedDataSources.length > 0;
            case 4:
                return autoIndexingOption !== null;
            case 5:
                return true; // Review step is always valid
            default:
                return true;
        }
    };

    const handleSubmit = () => {
        // Handle form submission
        console.log('Form submitted');
        navigate('/search/index/indices');
    };

    return (
        <AppLayout
            breadcrumbs_props={{
                items: [
                    { text: 'Search', href: '/search/index/indices' },
                    { text: 'Search Index', href: '/search/index/indices' },
                    { text: 'Indices', href: '/search/index/indices' },
                    { text: 'Create Index', href: '/search/index/indices/create' },
                ]
            }}
            side_nav={
                <SearchSideNav
                    activeHref={activeHref}
                    onFollow={handleFollow}>
                </SearchSideNav>}
        >
            <AppContentLayout
                header_layout_props={{
                    text: "Create Index",
                    description: "Create a new index for your search application."
                }}
            >
                <Container>
                    <Wizard
                        steps={[
                            {
                                title: 'Set index name',
                                content: (
                                    <Container>
                                        <SpaceBetween direction="vertical" size="l">
                                            <FormField label="Index name">
                                                <Input
                                                    value={indexName}
                                                    onChange={handleIndexNameChange}
                                                    placeholder="Enter index name"
                                                />
                                            </FormField>
                                        </SpaceBetween>
                                    </Container>
                                )
                            },
                            {
                                title: 'Select LLM and Embedding settings',
                                content: (
                                    <Container>
                                        <SpaceBetween direction="vertical" size="l">
                                            <FormField label="LLM Settings">
                                                <Select
                                                    options={[
                                                        { label: 'GPT-4', value: 'gpt4' },
                                                        { label: 'GPT-3.5', value: 'gpt3.5' },
                                                        { label: 'Claude', value: 'claude' },
                                                    ]}
                                                    selectedOption={selectedLLMSettings}
                                                    onChange={handleLLMSettingsChange}
                                                    placeholder="Choose LLM settings"
                                                />
                                            </FormField>
                                            <FormField label="Embedding Settings">
                                                <Select
                                                    options={[
                                                        { label: 'OpenAI Ada', value: 'ada' },
                                                        { label: 'Sentence Transformers', value: 'sbert' },
                                                        { label: 'Custom Embedding', value: 'custom' },
                                                    ]}
                                                    selectedOption={selectedEmbeddingSettings}
                                                    onChange={handleEmbeddingSettingsChange}
                                                    placeholder="Choose embedding settings"
                                                />
                                            </FormField>
                                        </SpaceBetween>
                                    </Container>
                                )
                            },
                            {
                                title: 'Select dictionary',
                                content: (
                                    <Container>
                                        <SpaceBetween direction="vertical" size="l">
                                            <FormField label="Dictionary">
                                                <Select
                                                    options={[
                                                        { label: 'HR Dictionary v1', value: 'hr_v1' },
                                                        { label: 'HR Dictionary v2', value: 'hr_v2' },
                                                        { label: 'General Dictionary', value: 'general' },
                                                    ]}
                                                    selectedOption={selectedDictionary}
                                                    onChange={handleDictionaryChange}
                                                    placeholder="Choose dictionary"
                                                />
                                            </FormField>
                                        </SpaceBetween>
                                    </Container>
                                )
                            },
                            {
                                title: 'Select data sources',
                                content: (
                                    <Container>
                                        <SpaceBetween direction="vertical" size="l">
                                            <FormField label="Data Sources">
                                                <Multiselect
                                                    options={[
                                                        { label: 'HR Dataset, Jul2028', value: 'hr-dataset-jul2028' },
                                                        { label: 'Youtube Crawl Data', value: 'youtube-crawl-data' },
                                                        { label: 'HR Operation dataset', value: 'hr-operation-dataset' },
                                                    ]}
                                                    selectedOptions={selectedDataSources}
                                                    onChange={handleDataSourcesChange}
                                                    placeholder="Choose data sources"
                                                />
                                            </FormField>
                                        </SpaceBetween>
                                    </Container>
                                )
                            },
                            {
                                title: 'Indexing Pipeline Settings',
                                content: (
                                    <Container>
                                        <SpaceBetween direction="vertical" size="l">
                                            <FormField label="Daily Indexing">
                                                <Select
                                                    options={[
                                                        { label: 'Disabled', value: 'disabled' },
                                                        { label: 'Enabled', value: 'daily' },
                                                    ]}
                                                    selectedOption={autoIndexingOption}
                                                    onChange={handleAutoIndexingOptionChange}
                                                    placeholder="Choose auto indexing option"
                                                />
                                            </FormField>
                                            {autoIndexingOption.value !== 'disabled' && (
                                                <SpaceBetween size="m">
                                                    {batchTimes.map((time, index) => (
                                                        <SpaceBetween key={index} direction="horizontal" size="xs">
                                                            <TimeInput
                                                                onChange={({ detail }) => handleBatchTimeChange(index, detail.value)}
                                                                value={time}
                                                                format="hh:mm"
                                                                placeholder="hh:mm"
                                                            />
                                                            <Button
                                                                variant="icon"
                                                                iconName="remove"
                                                                onClick={() => handleRemoveBatchTime(index)}
                                                            />
                                                        </SpaceBetween>
                                                    ))}
                                                    <Button onClick={handleAddBatchTime}>
                                                        Add batch time
                                                    </Button>
                                                </SpaceBetween>
                                            )}
                                        </SpaceBetween>
                                    </Container>
                                )
                            },
                            {
                                title: 'Review and create',
                                content: (
                                    <Container>
                                        <SpaceBetween direction="vertical" size="l">
                                            <Header variant="h2">Review your index settings</Header>
                                            <ColumnLayout columns={2} variant="text-grid">
                                                <SpaceBetween size="l">
                                                    <div>
                                                        <h3>Index Name</h3>
                                                        <p>{indexName}</p>
                                                    </div>
                                                    <div>
                                                        <h3>LLM Settings</h3>
                                                        <p>{selectedLLMSettings?.label}</p>
                                                    </div>
                                                    <div>
                                                        <h3>Embedding Settings</h3>
                                                        <p>{selectedEmbeddingSettings?.label}</p>
                                                    </div>
                                                </SpaceBetween>
                                                <SpaceBetween size="l">
                                                    <div>
                                                        <h3>Dictionary</h3>
                                                        <p>{selectedDictionary?.label}</p>
                                                    </div>
                                                    <div>
                                                        <h3>Data Sources</h3>
                                                        <ul>
                                                            {selectedDataSources.map((source, index) => (
                                                                <li key={index}>{source.label}</li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                    <div>
                                                        <h3>Auto Indexing</h3>
                                                        <p>{autoIndexingOption.label}</p>
                                                        {autoIndexingOption.value !== 'disabled' && (
                                                            <div>
                                                                <h4>Batch Times</h4>
                                                                <ul>
                                                                    {batchTimes.map((time, index) => (
                                                                        <li key={index}>{time}</li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </div>
                                                </SpaceBetween>
                                            </ColumnLayout>
                                        </SpaceBetween>
                                    </Container>
                                )
                            },
                        ]}
                        i18nStrings={{
                            stepNumberLabel: stepNumber =>
                                `Step ${stepNumber}`,
                            collapsedStepsLabel: (stepNumber, stepsCount) =>
                                `Step ${stepNumber} of ${stepsCount}`,
                            cancelButton: "Cancel",
                            previousButton: "Previous",
                            nextButton: "Next",
                            submitButton: "Create index",
                            optional: "optional",
                        }}
                        onNavigate={({ detail }) => setActiveStepIndex(detail.requestedStepIndex)}
                        activeStepIndex={activeStepIndex}
                        onCancel={() => navigate('/search/index/indices')}
                        onSubmit={handleSubmit}
                        isNavigationDisabled={!isStepValid(activeStepIndex)}
                    />
                </Container>
            </AppContentLayout>
        </AppLayout>
    );
}