import AppLayout from '../../../components/layout/AppLayout';
import AppContentLayout from '../../../components/layout/ContentLayout';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import SearchSideNav from "../../../components/navigation/SearchSideNavigation";
import {
    Container,
    FormField,
    Header, Input,
    Link, RadioGroup,
    Textarea
} from "@cloudscape-design/components";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import Modal from "@cloudscape-design/components/modal";
import * as PropTypes from "prop-types";
import Box from "@cloudscape-design/components/box";

function LoadingBar(props) {
    return null;
}

LoadingBar.propTypes = {variant: PropTypes.string};

function Avatar(props) {
    return null;
}

Avatar.propTypes = {
    color: PropTypes.string,
    iconName: PropTypes.string,
    tooltipText: PropTypes.string,
    ariaLabel: PropTypes.string
};

export default function DataSourceCreation() {
    const [activeHref, setActiveHref] = useState('/search/document/datasources');
    const [nameValue, setNameValue] = useState("");
    const [descriptionValue, setDescriptionValue] = useState("");
    const [storageTypeValue, setStorageTypeValue] = useState("s3");
    const [uriValue, setUriValue] = useState("");
    const [visible, setVisible] = useState(false);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    useEffect(() => {
        setNameValue(queryParams.get('name') || "");
        setDescriptionValue(queryParams.get('description') || "");
        setStorageTypeValue("s3");
        setUriValue(queryParams.get('s3Path') || "");
    }, []);

    const handleFollow = (event) => {
        if (!event.detail.external) {
            event.preventDefault();
            setActiveHref(event.detail.href);
        }
    };

    const handleCreateDataSource = () => {
        setVisible(true);
    };

    return (
        <AppLayout
            breadcrumbs_props={{
                items:[
                    { text: 'Search', href: '/search/index/indices' },
                    { text: 'Document', href: '/search/document/datasources' },
                    { text: 'Data Sources', href: '/search/document/datasources' },
                    { text: 'Create', href: '/search/document/datasources/create' },
                ]
            }}
            side_nav={
                <SearchSideNav
                    activeHref={activeHref}
                    onFollow={handleFollow}>
                </SearchSideNav>}
        >
            <AppContentLayout
                header_layout_props={{
                    text: "Data Sources",
                    description: "Set up a Data Source to create the index."
                }}
            >
                <SpaceBetween size="l">
                    <Container
                        header={
                            <Header
                                variant="h2"
                                actions={
                                    <SpaceBetween
                                        direction="horizontal"
                                        size="xs"
                                    >
                                        <Button variant="primary" onClick={handleCreateDataSource}>
                                            Save Data Source
                                        </Button>
                                        <Button href="/search/document/datasources">Cancel</Button>
                                    </SpaceBetween>
                                }>
                                {queryParams.get('name') ? "Edit Data Source" : "New Data Source"}
                            </Header>
                        }>
                        <SpaceBetween size="xl">
                            <FormField
                                label={
                                    <>
                                        Data Source Name
                                    </>
                                }
                            >
                                <Input
                                    onChange={({ detail }) => setNameValue(detail.value)}
                                    value={nameValue}
                                    placeholder="Enter Data Source Name"
                                />
                            </FormField>
                            <FormField
                                label={
                                    <>
                                        Description
                                    </>
                                }
                            >
                                <Textarea
                                    onChange={({ detail }) => setDescriptionValue(detail.value)}
                                    value={descriptionValue}
                                    placeholder="Enter Description"
                                />
                            </FormField>
                            <FormField
                                label="Source Storage Type"
                                description="Select the storage type where the data is located."
                            >
                                <RadioGroup
                                    items={[
                                        { value: "s3", label: "AWS S3" },
                                        { value: "gcs", label: "GCP Cloud Storage", disabled: true },
                                        { value: "azure", label: "Azure Blob Storage", disabled: true }
                                    ]}
                                    value={storageTypeValue}
                                    onChange={({ detail }) => setStorageTypeValue(detail.value)}
                                />
                            </FormField>
                            <FormField
                                label={
                                    <>
                                        URI Path
                                    </>
                                }
                                info={<Link variant="info">Info</Link>}
                                description="Enter the URI path where the data is stored."
                            >
                                <Input
                                    onChange={({ detail }) => setUriValue(detail.value)}
                                    value={uriValue}
                                    placeholder="s3://bucket-name-example1/dir1/dir2"
                                />
                            </FormField>
                        </SpaceBetween>
                    </Container>
                </SpaceBetween>
                <Modal
                    onDismiss={() => setVisible(false)}
                    visible={visible}
                    header="Your request has been submitted."
                    footer={
                        <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button onClick={() => setVisible(false)} variant="primary">OK</Button>
                            </SpaceBetween>
                        </Box>
                    }
                >
                    This feature will be supported in the future.
                    Thank you for your patience.
                </Modal>
            </AppContentLayout>
        </AppLayout>
    );
}