import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import AppTopNavigation from './components/layout/TopNavigation';
import { I18nWrapper } from './utils/i18n';
import Indices from './pages/search/search_pipeline/Indices';
import FaqAnswers from "./pages/search/document/FaqAnswers";
import DataSources from "./pages/search/document/DataSources";
import BasicDictionary from "./pages/search/dictionary/BasicDictionary";
import AIDictionary from "./pages/search/dictionary/AIDictionary";
import AIModel from "./pages/search/ranking_model/ai_model/AIModel";
import AccessGroups from "./pages/access/access_group/AccessGroups";
import UserFeedbacks from "./pages/monitoring/observerbility/UserFeedbacks";
import BillingCost from "./pages/monitoring/observerbility/BillingCost";
import PipelineSettings from "./pages/monitoring/pipeline/PipelineSettings";
import AppManagement from "./pages/application/application/AppManagement";
import BaseModelCreation from "./pages/search/ranking_model/basic_model/BaseModelCreation";
import BasicModel from "./pages/search/ranking_model/basic_model/BasicModel";
import AIModelCreation from "./pages/search/ranking_model/ai_model/AIModelCreation";
import Users from "./pages/access/access_group/Users";
import AccessGroupCreate from "./pages/access/access_group/AccessGroupCreate";
import AIEvaluationDataset from "./pages/monitoring/dataset/AIEvaluationDataset";
import UserEvaluationDataset from "./pages/monitoring/dataset/UserEvaluationDataset";
import Dashboards from "./pages/monitoring/pipeline/Dashboards";
import PipelineSettingsCreate from "./pages/monitoring/pipeline/PipelineSettingsCreate";
import AppManagementCreation from "./pages/application/application/AppManagementCreate";
import DataSourceCreate from "./pages/search/document/DataSourceCreate";
import IndexCreate from "./pages/search/search_pipeline/IndexCreate";
import LLMSettings from "./pages/language_model/LLMSettings";
import LLMSettingCreate from "./pages/language_model/LLMSettingCreate";
import EmbeddingConfigurations from "./pages/language_model/EmbeddingConfigurations";
import EmbeddingConfigurationsCreate from "./pages/language_model/EmbeddingConfigurationsCreate";
import AIDictionaryCreate from "./pages/search/dictionary/AIDictionaryCreate";
import IndexEdit from "./pages/search/search_pipeline/IndexEdit";
import SystemPrompts from "./pages/application/application/SystemPrompts";
import NoticeMessages from "./pages/application/application/NoticeMessages";
import AppManagementEdit from "./pages/application/application/AppManagementEdit";
import IndexingHistory from "./pages/search/search_pipeline/IndexingHistory";
import IndexedDocument from "./pages/search/search_pipeline/IndexedDocument";
import SearchTest from "./pages/search/search_pipeline/SearchTest";

function App({ signOut, user }) {
    return (
        <Router>
            <div>
                <AppTopNavigation username={user.username} useremail={user.email} onSignOut={signOut} />
                <I18nWrapper>
                    <Routes>
                        <Route path="/" element={<Indices />} />
                        <Route path="/search/document/datasources" element={<DataSources />} />
                        <Route path="/search/document/datasources/create" element={<DataSourceCreate />} />
                        <Route path="/search/document/faqanswers" element={<FaqAnswers />} />
                        <Route path="/search/index/indices" element={<Indices />} />
                        <Route path="/search/index/indices/create" element={<IndexCreate />} />
                        <Route path="/search/index/indices/edit" element={<IndexEdit />} />
                        <Route path="/search/index/histories" element={<IndexingHistory />} />
                        <Route path="/search/index/document" element={<IndexedDocument />} />
                        <Route path="/search/index/search" element={<SearchTest />} />
                        <Route path="/search/dictionary/user" element={<BasicDictionary />} />
                        <Route path="/search/dictionary/ai" element={<AIDictionary />} />
                        <Route path="/search/dictionary/ai/create" element={<AIDictionaryCreate />} />
                        <Route path="/search/rankingmodel/models" element={<BasicModel />} />
                        <Route path="/search/rankingmodel/models/create" element={<BaseModelCreation />} />
                        <Route path="/search/rankingmodel/ai" element={<AIModel />} />
                        <Route path="/search/rankingmodel/ai/create" element={<AIModelCreation />} />
                        <Route path="/access/accessgroups" element={<AccessGroups />} />
                        <Route path="/access/accessgroups/create" element={<AccessGroupCreate />} />
                        <Route path="/access/users" element={<Users />} />
                        <Route path="/evaluation/aidataset" element={<AIEvaluationDataset />} />
                        <Route path="/evaluation/userdataset" element={<UserEvaluationDataset />} />
                        <Route path="/evaluation/pipelinesettings" element={<PipelineSettings />} />
                        <Route path="/evaluation/pipelinesettings/create" element={<PipelineSettingsCreate />} />
                        <Route path="/evaluation/dashboards" element={<Dashboards />} />
                        <Route path="/application/app/notices" element={<NoticeMessages />} />
                        <Route path="/application/app/systemprompts" element={<SystemPrompts />} />
                        <Route path="/application/observerbility/userfeedbacks" element={<UserFeedbacks />} />
                        <Route path="/application/observerbility/biilingcost" element={<BillingCost />} />
                        <Route path="/application/app/management" element={<AppManagement />} />
                        <Route path="/application/app/management/create" element={<AppManagementCreation />} />
                        <Route path="/application/app/management/edit" element={<AppManagementEdit />} />
                        <Route path="/languagemodel/llm" element={<LLMSettings />} />
                        <Route path="/languagemodel/llm/create" element={<LLMSettingCreate />} />
                        <Route path="/languagemodel/embedding" element={<EmbeddingConfigurations />} />
                        <Route path="/languagemodel/embedding/create" element={<EmbeddingConfigurationsCreate />} />
                    </Routes>
                </I18nWrapper>
            </div>
        </Router>
    );
}

export default withAuthenticator(App);