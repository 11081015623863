import AppLayout from '../../../components/layout/AppLayout';
import AppContentLayout from '../../../components/layout/ContentLayout';
import { useState } from 'react';
import {Container, Header, KeyValuePairs, Link, SpaceBetween} from "@cloudscape-design/components";
import Board from "@cloudscape-design/board-components/board";
import BoardItem from "@cloudscape-design/board-components/board-item";
import ServiceSideNavigation from "../../../components/navigation/ServiceSideNavigation";
import MonitoringSideNav from "../../../components/navigation/MonitoringSideNavigation";

const mockData = {
    "Claude3 Sonnet (us-east-1)": {
        invocations: 513,
        inputTokenCount: 2.67,
        outputTokenCount: 139,
        invocationLatency: 6.85,
        invocationClientErrors: null,
        cost: 27.1
    },
    "Claude3 Haiku (us-east-1)": {
        invocations: 19.6,
        inputTokenCount: 2.21,
        outputTokenCount: 2.12,
        invocationLatency: 1.24,
        invocationClientErrors: null,
        cost: 0.22
    },
    "Amazon Titan (us-east-1)": {
        invocations: 123,
        inputTokenCount: 2.29,
        outputTokenCount: null,
        invocationLatency: 0.97,
        invocationClientErrors: null,
        cost: 0
    },
    "Claude3 Sonnet (us-west-2)": {
        invocations: 21,
        inputTokenCount: 57.2,
        outputTokenCount: 31.8,
        invocationLatency: 26.4,
        invocationClientErrors: 1,
        cost: 42
    },
    "Claude3 Haiku (us-west-2)": {
        invocations: 80.2,
        inputTokenCount: 149,
        outputTokenCount: 36.4,
        invocationLatency: 5.19,
        invocationClientErrors: null,
        cost: 1.4
    }
};

export default function BillingCost() {
    const [activeHref, setActiveHref] = useState('/application/observerbility/biilingcost');

    const handleFollow = (event) => {
        if (!event.detail.external) {
            event.preventDefault();
            setActiveHref(event.detail.href);
        }
    };

    const models = Object.keys(mockData);

    const boardItems = models.map((model, index) => ({
        id: index.toString(),
        rowSpan: 3,
        columnSpan: 2,
        data: {
            title: model,
            content: (
                <KeyValuePairs
                    columns={2}
                    items={[
                        {
                            label: "Invocations",
                            value: <Link variant="awsui-value-large" href="#">{mockData[model].invocations}</Link>
                        },
                        {
                            label: "Input Token Count (M)",
                            value: <Link variant="awsui-value-large" href="#">{mockData[model].inputTokenCount}</Link>
                        },
                        {
                            label: "Output Token Count (K)",
                            value: <Link variant="awsui-value-large" href="#">{mockData[model].outputTokenCount}</Link>
                        },
                        {
                            label: "Invocation Latency (s)",
                            value: <Link variant="awsui-value-large" href="#">{mockData[model].invocationLatency}</Link>
                        },
                        {
                            label: "Invocation Client Errors",
                            value: <Link variant="awsui-value-large" href="#">{mockData[model].invocationClientErrors ?? "--"}</Link>
                        },
                        {
                            label: "Cost per Invocation (won)",
                            value: <Link variant="awsui-value-large" href="#">{mockData[model].cost}</Link>
                        }
                    ]}
                />
            )
        }
    }));
    const totalData = {
        invocations: models.reduce((sum, model) => sum + mockData[model].invocations, 0),
        inputTokenCount: models.reduce((sum, model) => sum + (mockData[model].inputTokenCount ?? 0), 0),
        outputTokenCount: models.reduce((sum, model) => sum + (mockData[model].outputTokenCount ?? 0), 0),
        invocationLatency: models.reduce((sum, model) => sum + (mockData[model].invocationLatency ?? 0), 0),
        bedrock: models.reduce((sum, model) => sum + (mockData[model].invocations ?? 0), 0),
        cost: models.reduce((sum, model) => sum + (mockData[model].cost ?? 0), 0)
    };
    const totalBoardItem = {
        id: models.length.toString(),
        rowSpan: 3,
        columnSpan: 4,
        data: {
            title: "Overall metrics",
            content: (
                <KeyValuePairs
                    columns={4}
                    items={[
                        {
                            label: "Total number of conversations",
                            value: <Link variant="awsui-value-large" href="#">
                                {Math.floor(totalData.invocations / 10)}
                            </Link>
                        },
                        {
                            label: "Input token count (M)",
                            value: <Link variant="awsui-value-large" href="#">{totalData.inputTokenCount.toFixed(2)}</Link>
                        },
                        {
                            label: "Total number of queries",
                            value: <Link variant="awsui-value-large" href="#">
                                {Math.floor(totalData.outputTokenCount)}</Link>
                        },
                        {
                            label: "Average latency (s)",
                            value: <Link variant="awsui-value-large" href="#">{(totalData.invocationLatency / models.length).toFixed(2)}</Link>
                        },
                        {
                            label: "Bedrock invocation count",
                            value: <Link variant="awsui-value-large" href="#">{Math.floor(totalData.bedrock)}</Link>
                        },
                        {
                            label: "Average Bedrock invocation cost (KRW)",
                            value: <Link variant="awsui-value-large" href="#">{(totalData.cost / totalData.bedrock).toFixed(2)}</Link>
                        }
                    ]}
                />
            )
        }
    };
    boardItems.unshift(totalBoardItem)
    function createAnnouncement(operationAnnouncement, conflicts, disturbed) {
        const conflictsAnnouncement =
            conflicts.length > 0
                ? `Conflicts with ${conflicts.map(c => c.data.title).join(", ")}.`
                : "";
        const disturbedAnnouncement =
            disturbed.length > 0
                ? `Disturbed ${disturbed.length} items.`
                : "";
        return [operationAnnouncement, conflictsAnnouncement, disturbedAnnouncement]
            .filter(Boolean)
            .join(" ");
    }
    return (
        <AppLayout
            breadcrumbs_props={{
                items:[
                    { text: 'Application', href: '/application/observerbility/userfeedbacks' },
                    { text: 'Observerbility', href: '/application/observerbility/userfeedbacks' },
                    { text: 'Billing & Cost', href: '/application/observerbility/biilingcost' },
                ]
            }}
            side_nav={
                <MonitoringSideNav
                    activeHref={activeHref}
                    onFollow={handleFollow}>
                </MonitoringSideNav>}
        >
            <AppContentLayout
                header_layout_props={{
                    text: "Billing and Cost",
                    description: "Overview of LLM usage and associated costs"
                }}
            >
                <Board
                    renderItem={item => (
                        <BoardItem
                            header={<Header variant="h2" description={item.data.description}>{item.data.title}</Header>}
                                i18nStrings={{
                                    dragHandleAriaLabel: "Drag handle",
                                    dragHandleAriaDescription:
                                        "Use Space or Enter to activate drag, arrow keys to move, Space or Enter to submit, or Escape to discard.",
                                    resizeHandleAriaLabel: "Resize handle",
                                    resizeHandleAriaDescription:
                                        "Use Space or Enter to activate resize, arrow keys to move, Space or Enter to submit, or Escape to discard."
                                }}
                            >
                            {item.data.content}
                        </BoardItem>
                    )}
                    items={boardItems}
                    onItemsChange={event => console.log(event.detail.items)}
                    i18nStrings={{
                        liveAnnouncementDndStarted: operationType =>
                            operationType === "resize"
                                ? "Resizing"
                                : "Dragging",
                        liveAnnouncementDndItemReordered: operation => {
                            const columns = `column ${operation.placement.x + 1}`;
                            const rows = `row ${operation.placement.y + 1}`;
                            return createAnnouncement(
                                `Item moved to ${
                                    operation.direction === "horizontal"
                                        ? columns
                                        : rows
                                }.`,
                                operation.conflicts,
                                operation.disturbed
                            );
                        },
                        liveAnnouncementDndItemResized: operation => {
                            const columnsConstraint = operation.isMinimalColumnsReached
                                ? " (minimal)"
                                : "";
                            const rowsConstraint = operation.isMinimalRowsReached
                                ? " (minimal)"
                                : "";
                            const sizeAnnouncement =
                                operation.direction === "horizontal"
                                    ? `columns ${operation.placement.width}${columnsConstraint}`
                                    : `rows ${operation.placement.height}${rowsConstraint}`;
                            return createAnnouncement(
                                `Item resized to ${sizeAnnouncement}.`,
                                operation.conflicts,
                                operation.disturbed
                            );
                        },
                        liveAnnouncementDndItemInserted: operation => {
                            const columns = `column ${operation.placement.x + 1}`;
                            const rows = `row ${operation.placement.y + 1}`;
                            return createAnnouncement(
                                `Item inserted to ${columns}, ${rows}.`,
                                operation.conflicts,
                                operation.disturbed
                            );
                        },
                        liveAnnouncementDndCommitted: operationType =>
                            `${operationType} committed`,
                        liveAnnouncementDndDiscarded: operationType =>
                            `${operationType} discarded`,
                        liveAnnouncementItemRemoved: op =>
                            createAnnouncement(
                                `Removed item ${op.item.data.title}.`,
                                [],
                                op.disturbed
                            ),
                        navigationAriaLabel: "Board navigation",
                        navigationAriaDescription:
                            "Click on non-empty item to move focus over",
                        navigationItemAriaLabel: item =>
                            item ? item.data.title : "Empty"
                    }}
                />
            </AppContentLayout>
        </AppLayout>
    );
}