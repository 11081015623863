import AppLayout from '../../../components/layout/AppLayout';
import AppContentLayout from '../../../components/layout/ContentLayout';
import { useState } from 'react';
import SearchSideNav from "../../../components/navigation/SearchSideNavigation";
import {
    Container,
    Header,
    Link,
    SpaceBetween,
    Button,
    Box,
    TextFilter,
    ButtonDropdown,
    Pagination,
    CollectionPreferences,
    Table,
    Modal
} from "@cloudscape-design/components";
import * as React from "react";
import { useNavigate } from 'react-router-dom';
import ServiceSideNavigation from "../../../components/navigation/ServiceSideNavigation";

export default function SystemPrompts() {
    const navigate = useNavigate();
    const [activeHref, setActiveHref] = useState('/application/app/systemprompts');
    const [selectedItem, setSelectedItem] = React.useState(null);
    const [visible, setVisible] = React.useState(false);
    const [createModalVisible, setCreateModalVisible] = React.useState(false);
    const [items, setItems] = React.useState([
        {
            name: "Default Prompt",
            prompt: "This is a default system prompt for general use. It can be used in various scenarios and provides a good starting point for many tasks.",
            created: "2024-07-20 11:32:06",
        },
        {
            name: "Specialized Prompt for Contract",
            prompt: "This prompt is designed for specific tasks that require more focused instructions. It's tailored for particular use cases and may include domain-specific language.",
            created: "2024-07-18 12:45:24",
        },
        {
            name: "Custom Prompt - Ver1",
            prompt: "A custom prompt created by the user for their unique requirements. This prompt can be edited and fine-tuned as needed to achieve desired results.",
            created: "2024-07-15 00:20:30",
        },
    ]);

    const handleFollow = (event) => {
        if (!event.detail.external) {
            event.preventDefault();
            setActiveHref(event.detail.href);
        }
    };

    const handleDelete = () => {
        if (selectedItem) {
            setVisible(true);
        }
    };

    const confirmDelete = () => {
        setItems(items.filter(item => item !== selectedItem));
        setSelectedItem(null);
        setVisible(false);
    };

    const handleCreateClick = () => {
        setCreateModalVisible(true);
    };

    const onItemClick = ({ detail }) => {
        if (detail.id === 'delete') {
            handleDelete();
        } else if (detail.id === 'edit') {
            navigateToEdit(selectedItem);
        }
    };

    const navigateToEdit = (item) => {
        if (item) {
            navigate(`../../..//edit/${item.name}`);
        }
    };

    const onRowClick = ({ detail }) => {
        setSelectedItem(detail.item);
    };

    return (
        <AppLayout
            breadcrumbs_props={{
                items:[
                    { text: 'Application', href: '/application' },
                    { text: 'System Prompts', href: '../../../' },
                ]
            }}
            side_nav={
                <ServiceSideNavigation
                    activeHref={activeHref}
                    onFollow={handleFollow}>
                </ServiceSideNavigation>}
        >
            <AppContentLayout
                header_layout_props={{
                    text: "System Prompts",
                    description: "Manage your system prompts here. You can create, edit, and delete prompts as needed."
                }}
            >
                <SpaceBetween size="l">
                    <Table
                        selectedItems={selectedItem ? [selectedItem] : []}
                        selectionType="single"
                        columnDefinitions={[
                            {
                                id: "name",
                                header: "Prompt Name",
                                cell: e => <Link href={`#`}>{e.name}</Link>,
                                sortingField: "name",
                                isRowHeader: true
                            },
                            {
                                id: "prompt",
                                header: "Prompt",
                                cell: e => e.prompt.length > 30 ? e.prompt.substring(0, 30) + "..." : e.prompt
                            },
                            {
                                id: "created",
                                header: "Created Time",
                                cell: e => e.created
                            },
                        ]}
                        items={items}
                        loadingText="Loading prompts"
                        trackBy="name"
                        empty={
                            <Box textAlign="center" color="inherit">
                                <SpaceBetween size="m">
                                    <b>No prompts</b>
                                    <Button onClick={handleCreateClick}>Create prompt</Button>
                                </SpaceBetween>
                            </Box>
                        }
                        filter={
                            <TextFilter
                                filteringPlaceholder="Find prompts"
                                filteringText=""
                            />
                        }
                        header={
                            <Header
                                counter={selectedItem ? `(1/${items.length})` : `(${items.length})`}
                                actions={
                                    <SpaceBetween direction="horizontal" size="xs">
                                        <ButtonDropdown
                                            items={[
                                                { text: "Edit", id: "edit", disabled: true },
                                                { text: "Delete", id: "delete", disabled: true },
                                            ]}
                                            onItemClick={onItemClick}
                                        >
                                            Actions
                                        </ButtonDropdown>
                                        <Button variant="primary" onClick={handleCreateClick}>Create Prompt</Button>
                                    </SpaceBetween>
                                }
                            >
                                System Prompts
                            </Header>
                        }
                        pagination={<Pagination currentPageIndex={1} pagesCount={1} />}
                        preferences={
                            <CollectionPreferences
                                title="Preferences"
                                confirmLabel="Confirm"
                                cancelLabel="Cancel"
                                preferences={{
                                    pageSize: 10,
                                    visibleContent: ['name', 'prompt', 'created'],
                                }}
                                pageSizePreference={{
                                    title: 'Page size',
                                    options: [
                                        { value: 10, label: '10 Prompts' },
                                        { value: 20, label: '20 Prompts' },
                                        { value: 50, label: '50 Prompts' },
                                    ],
                                }}
                                visibleContentPreference={{
                                    title: 'Select visible columns',
                                    options: [
                                        {
                                            label: 'Prompt properties',
                                            options: [
                                                { id: 'name', label: 'Prompt name' },
                                                { id: 'prompt', label: 'Prompt content' },
                                                { id: 'created', label: 'Creation date' },
                                            ],
                                        },
                                    ],
                                }}
                            />
                        }
                        onSelectionChange={({ detail }) => setSelectedItem(detail.selectedItems[0])}
                        onRowClick={onRowClick}
                    />
                </SpaceBetween>
            </AppContentLayout>
            <Modal
                onDismiss={() => setVisible(false)}
                visible={visible}
                header="Confirm deletion"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button onClick={() => setVisible(false)} variant="link">Cancel</Button>
                            <Button onClick={confirmDelete} variant="primary">Delete</Button>
                        </SpaceBetween>
                    </Box>
                }
            >
                Are you sure you want to delete the selected prompt? This action cannot be undone.
            </Modal>
            <Modal
                onDismiss={() => setCreateModalVisible(false)}
                visible={createModalVisible}
                header="Create Prompt"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button onClick={() => setCreateModalVisible(false)} variant="link">Cancel</Button>
                            <Button onClick={() => setCreateModalVisible(false)} variant="primary">OK</Button>
                        </SpaceBetween>
                    </Box>
                }
            >
                This feature will be supported in the future.
                Thank you for your patience.
            </Modal>
        </AppLayout>
    );
}