import AppLayout from '../../../components/layout/AppLayout';
import AppContentLayout from '../../../components/layout/ContentLayout';
import { useState, useEffect } from 'react';
import SearchSideNav from "../../../components/navigation/SearchSideNavigation";
import * as React from "react";
import {
    Container,
    Header,
    Select,
    Table,
    Box,
    SpaceBetween,
    Button,
    Pagination,
    TextFilter,
    CollectionPreferences
} from "@cloudscape-design/components";
import { useNavigate } from 'react-router-dom';

export default function IndexingHistory() {
    const navigate = useNavigate();
    const [activeHref, setActiveHref] = useState('/search/index/histories');
    const [items, setItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [currentPageIndex, setCurrentPageIndex] = useState(1);
    const [filteringText, setFilteringText] = useState("");
    const [pageSize, setPageSize] = useState(10);

    useEffect(() => {
        // indexOptions에서 무작위로 선택하는 함수
        const getRandomIndexName = () => {
            const randomIndex = Math.floor(Math.random() * indexOptions.length);
            return indexOptions[randomIndex].value;
        };

        // 예시 데이터 생성 부분 수정
        const exampleData = Array.from({ length: 20 }, (_, i) => ({
            id: (i + 1).toString(),
            indexName: getRandomIndexName(),
            importedDocuments: Math.floor(Math.random() * 2000) + 500,
            indexedDocuments: {
                new: Math.floor(Math.random() * 1500) + 300,
                cached: Math.floor(Math.random() * 300) + 50
            },
            failedDocuments: Math.floor(Math.random() * 100),
            indexedChunks: Math.floor(Math.random() * 10000) + 2000,
            duration: `${Math.floor(Math.random() * 60)%40}m`,
            startTime: `2024-${String(Math.floor(Math.random() * 12) + 1).padStart(2, '0')}-${String(Math.floor(Math.random() * 31) + 1).padStart(2, '0')} ${String(Math.floor(Math.random() * 24)).padStart(2, '0')}:${String(Math.floor(Math.random() * 60)).padStart(2, '0')}:${String(Math.floor(Math.random() * 60)).padStart(2, '0')}`,
            endTime: `2024-${String(Math.floor(Math.random() * 12) + 1).padStart(2, '0')}-${String(Math.floor(Math.random() * 31) + 1).padStart(2, '0')} ${String(Math.floor(Math.random() * 24)).padStart(2, '0')}:${String(Math.floor(Math.random() * 60)).padStart(2, '0')}:${String(Math.floor(Math.random() * 60)).padStart(2, '0')}`
        }));
        setItems(exampleData);
        setFilteredItems(exampleData);
    }, []);

    useEffect(() => {
        const filtered = items.filter(item =>
            item.indexName.toLowerCase().includes(filteringText.toLowerCase())
        );
        setFilteredItems(filtered);
        setCurrentPageIndex(1);
    }, [filteringText, items]);

    const handleFollow = (event) => {
        if (!event.detail.external) {
            event.preventDefault();
            setActiveHref(event.detail.href);
        }
    };

    const indexOptions = [
        { label: 'schdc-2024072001', value: 'schdc-2024072001' },
        { label: 'schdc-2024071814', value: 'schdc-2024071814' },
        { label: 'schdc-2024071101', value: 'schdc-2024071101' },
        { label: 'schdc-2024062802', value: 'schdc-2024062802' },
        { label: 'schdc-2024062801', value: 'schdc-2024062801' },
    ];

    return (
        <AppLayout
            breadcrumbs_props={{
                items: [
                    { text: 'Search', href: '/search/index/indices' },
                    { text: 'Search Index', href: '/search/index/indices' },
                    { text: 'Indexing History', href: '/search/index/histories' },
                ]
            }}
            side_nav={
                <SearchSideNav
                    activeHref={activeHref}
                    onFollow={handleFollow}>
                </SearchSideNav>}
        >
            <AppContentLayout
                header_layout_props={{
                    text: "Indexing History",
                    description: "View the history of indexing operations for your search indices."
                }}
            >
                <Container>
                    <SpaceBetween size="l">
                        <Table
                            columnDefinitions={[
                                {
                                    id: "indexName",
                                    header: "Index Name",
                                    cell: item => item.indexName,
                                    sortingField: "indexName"
                                },
                                {
                                    id: "importedDocuments",
                                    header: "Imported Documents",
                                    cell: item => item.importedDocuments,
                                    sortingField: "importedDocuments"
                                },
                                {
                                    id: "indexedDocuments",
                                    header: "Indexed Documents",
                                    cell: item => `New: ${item.indexedDocuments.new}, Cached: ${item.indexedDocuments.cached}`,
                                },
                                {
                                    id: "failedDocuments",
                                    header: "Failed Documents",
                                    cell: item => item.failedDocuments,
                                    sortingField: "failedDocuments"
                                },
                                {
                                    id: "indexedChunks",
                                    header: "Indexed Chunks",
                                    cell: item => item.indexedChunks,
                                    sortingField: "indexedChunks"
                                },
                                {
                                    id: "duration",
                                    header: "Duration",
                                    cell: item => item.duration,
                                    sortingField: "duration"
                                },
                                {
                                    id: "startTime",
                                    header: "Start Time",
                                    cell: item => item.startTime,
                                    sortingField: "startTime"
                                },
                                {
                                    id: "endTime",
                                    header: "End Time",
                                    cell: item => item.endTime,
                                    sortingField: "endTime"
                                },
                            ]}
                            items={filteredItems.slice((currentPageIndex - 1) * pageSize, currentPageIndex * pageSize)}
                            loadingText="Loading indexing history"
                            sortingDisabled
                            variant="full-page"
                            empty={
                                <Box textAlign="center" color="inherit">
                                    <b>No indexing history</b>
                                    <Box
                                        padding={{ bottom: "s" }}
                                        variant="p"
                                        color="inherit"
                                    >
                                        No indexing history to display.
                                    </Box>
                                </Box>
                            }
                            filter={
                                <TextFilter
                                    filteringPlaceholder="Find indexing operations"
                                    filteringText={filteringText}
                                    onChange={({ detail }) => setFilteringText(detail.filteringText)}
                                />
                            }
                            header={
                                <Header
                                    counter={`(${filteredItems.length})`}
                                >
                                    Histories
                                </Header>
                            }
                            pagination={
                                <Pagination
                                    currentPageIndex={currentPageIndex}
                                    onChange={({ detail }) => setCurrentPageIndex(detail.currentPageIndex)}
                                    pagesCount={Math.ceil(filteredItems.length / pageSize)}
                                />
                            }
                            preferences={
                                <CollectionPreferences
                                    title="Preferences"
                                    confirmLabel="Confirm"
                                    cancelLabel="Cancel"
                                    preferences={{
                                        pageSize: pageSize,
                                        visibleContent: [
                                            "indexName",
                                            "importedDocuments",
                                            "indexedDocuments",
                                            "failedDocuments",
                                            "indexedChunks",
                                            "duration",
                                            "startTime",
                                            "endTime"
                                        ],
                                    }}
                                    pageSizePreference={{
                                        title: "Page size",
                                        options: [
                                            { value: 10, label: "10 operations" },
                                            { value: 20, label: "20 operations" }
                                        ]
                                    }}
                                    onConfirm={({ detail }) => setPageSize(detail.pageSize)}
                                />
                            }
                        />
                    </SpaceBetween>
                </Container>
            </AppContentLayout>
        </AppLayout>
    );
}