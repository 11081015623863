import AppLayout from '../../../components/layout/AppLayout';
import AppContentLayout from '../../../components/layout/ContentLayout';
import { useState } from 'react';
import SearchSideNav from "../../../components/navigation/SearchSideNavigation";
import {
    Container,
    FormField,
    Header,
    KeyValuePairs,
    Link,
    RadioGroup,
    StatusIndicator,
    Textarea,
    Modal
} from "@cloudscape-design/components";
import * as PropTypes from "prop-types";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import Box from "@cloudscape-design/components/box";
import TextFilter from "@cloudscape-design/components/text-filter";
import ButtonDropdown from "@cloudscape-design/components/button-dropdown";
import Pagination from "@cloudscape-design/components/pagination";
import CollectionPreferences from "@cloudscape-design/components/collection-preferences";
import Table from "@cloudscape-design/components/table";
import * as React from "react";

function InfoLink(props) {
    return null;
}

InfoLink.propTypes = {
    onFollow: PropTypes.func,
    id: PropTypes.string
};

export default function BasicDictionary() {
    const [activeHref, setActiveHref] = useState('/search/dictionary/user');
    const [compoundNouns, setCompoundNouns] = useState(`
BTS
Bangtan
Bangso
BangtanSonDan
...
    `.trim());
    const [synonyms, setSynonyms] = useState(`
BTS, Bangtan, Bangso
MacbookPro, Macbook, MacLaptop
USD, UnitSoldPerDaily
...
    `.trim());

    const [selectedItem, setSelectedItem] = React.useState(null);
    const [visible, setVisible] = React.useState(false);
    const [baseVersionName, setBaseVersionName] = React.useState('v3');
    const [items, setItems] = React.useState([
        {
            name: "v3",
            alt: "32 words",
            type: "12 synonyms",
            created: "2024-07-20 11:32:06",
        },
        {
            name: "v2",
            alt: "30 words",
            type: "12 synonyms",
            created: "2024-07-20 11:30:35",
        },
        {
            name: "v1",
            alt: "26 words",
            type: "8 synonyms",
            created: "2024-07-18 12:45:24",
        },
        {
            name: "v0",
            alt: "0 words",
            type: "0 synonyms",
            created: "2024-07-15 00:20:30",
        },
    ]);

    const handleFollow = (event) => {
        if (!event.detail.external) {
            event.preventDefault();
            setActiveHref(event.detail.href);
        }
    };

    const handleDelete = () => {
        setVisible(true);
    };

    const confirmDelete = () => {
        if (selectedItem) {
            const newItems = items.filter(item => item !== selectedItem);
            setItems(newItems);
            setSelectedItem(null);
            setVisible(false);
        }
    };

    const handleItemClick = ({ detail }) => {
        if (detail.id === 'rm') {
            handleDelete();
        }
    };

    const addNewVersion = () => {
        const currentVersion = parseInt(baseVersionName.substring(1));
        const newVersion = `v${currentVersion + 1}`;
        const newItem = {
            name: newVersion,
            alt: "33 words",
            type: "15 synonyms",
            created: new Date().toISOString().split('T')[0] + ' ' + new Date().toTimeString().split(' ')[0],
        };
        setItems([newItem, ...items]);
        setBaseVersionName(newVersion);
    };

    return (
        <AppLayout
            breadcrumbs_props={{
                items:[
                    { text: 'Search', href: '/search/index/indices' },
                    { text: 'Dictionary', href: '/search/dictionary/user' },
                    { text: 'Basic Dictionary', href: '/search/dictionary/user' },
                ]
            }}
            side_nav={
                <SearchSideNav
                    activeHref={activeHref}
                    onFollow={handleFollow}>
                </SearchSideNav>}
        >
            <AppContentLayout
                header_layout_props={{
                    text: "Basic Dictionary",
                    description: "Manage your dictionary with compound nouns and synonyms."
                }}
            >
                <SpaceBetween size="l">
                    <Container
                        header={
                            <Header
                                variant="h2"
                                actions={
                                    <SpaceBetween
                                        direction="horizontal"
                                        size="xs"
                                    >
                                        <Button variant="primary" onClick={addNewVersion}>Add New Version</Button>
                                        <Button>Cancel</Button>
                                    </SpaceBetween>
                                }>
                                Term Setting
                            </Header>
                        }>
                        <SpaceBetween size="l">
                            <KeyValuePairs
                                columns={1}
                                items={[
                                    {
                                        label: 'Base Version Name',
                                        value: baseVersionName,
                                    },
                                ]}
                            />
                            <FormField
                                label={
                                    <>
                                        Compound Nouns
                                    </>
                                }
                                info={<Link variant="info">Info</Link>}
                                description="Enter compound nouns that should be treated as a single entity. This helps improve the accuracy of word segmentation and entity recognition."
                                constraintText="32 / 1000 words written."
                                stretch={true}
                            >
                                <Textarea
                                    placeholder="BTS, Bangtan, ..."
                                    value={compoundNouns}
                                    onChange={({ detail }) => setCompoundNouns(detail.value)}
                                />
                            </FormField>
                            <FormField
                                label={
                                    <>
                                        Synonyms
                                    </>
                                }
                                info={<Link variant="info">Info</Link>}
                                description="List synonyms or alternative names for entities. This enhances search capabilities and ensures consistent entity recognition across various terms."
                                constraintText="12 / 100 synonyms written."
                                stretch={true}
                            >
                                <Textarea
                                    placeholder={
                                        `BTS
Bangtan
...`
                                    }
                                    value={synonyms}
                                    onChange={({ detail }) => setSynonyms(detail.value)}
                                />
                            </FormField>
                        </SpaceBetween>
                    </Container>

                    <Table
                        renderAriaLive={({
                                             firstIndex,
                                             lastIndex,
                                             totalItemsCount
                                         }) =>
                            `Displaying items ${firstIndex} to ${lastIndex} of ${totalItemsCount}`
                        }
                        selectedItems={selectedItem ? [selectedItem] : []}
                        ariaLabels={{
                            selectionGroupLabel: "Items selection",
                            allItemsSelectionLabel: ({ selectedItems }) =>
                                `${selectedItems.length} ${
                                    selectedItems.length === 1 ? "item" : "items"
                                } selected`,
                            itemSelectionLabel: ({ selectedItems }, item) =>
                                item.name
                        }}
                        columnDefinitions={[
                            {
                                id: "variable",
                                header: "Version Name",
                                cell: e => e.name,
                                sortingField: "name",
                                isRowHeader: true
                            },
                            {
                                id: "value",
                                header: "Compound Nouns",
                                cell: e => e.alt,
                                sortingField: "alt"
                            },
                            { id: "type", header: "Synonyms", cell: e => e.type },
                            {
                                id: "created",
                                header: "Created",
                                cell: e => e.created
                            },
                        ]}
                        columnDisplay={[
                            { id: "variable", visible: true },
                            { id: "value", visible: true },
                            { id: "type", visible: true },
                            { id: "created", visible: true },
                            { id: "updated", visible: true },
                        ]}
                        enableKeyboardNavigation
                        items={items}
                        loadingText="Loading resources"
                        selectionType="single"
                        trackBy="name"
                        empty={
                            <Box
                                margin={{ vertical: "xs" }}
                                textAlign="center"
                                color="inherit"
                            >
                                <SpaceBetween size="m">
                                    <b>No resources</b>
                                    <Button>Create resource</Button>
                                </SpaceBetween>
                            </Box>
                        }
                        filter={
                            <TextFilter
                                filteringPlaceholder="Find resources"
                                filteringText=""
                            />
                        }
                        header={
                            <Header
                                counter={
                                    selectedItem
                                        ? "(1/10)"
                                        : "(10)"
                                }
                                actions={
                                    <SpaceBetween
                                        direction="horizontal"
                                        size="xs"
                                    >
                                        <ButtonDropdown
                                            items={[
                                                {
                                                    text: "Delete",
                                                    id: "rm",
                                                    disabled: !selectedItem
                                                },
                                                {
                                                    text: "Rebase as Current",
                                                    id: "mv",
                                                    disabled: !selectedItem
                                                },
                                            ]}
                                            onItemClick={handleItemClick}
                                        >
                                            Actions
                                        </ButtonDropdown>
                                    </SpaceBetween>
                                }
                            >
                                Basic Dictionaries
                            </Header>
                        }
                        pagination={
                            <Pagination currentPageIndex={1} pagesCount={2} />
                        }
                        preferences={
                            <CollectionPreferences
                                title="Preferences"
                                confirmLabel="Confirm"
                                cancelLabel="Cancel"
                                preferences={{
                                    pageSize: 10,
                                    contentDisplay: [
                                        { id: "variable", visible: true },
                                        { id: "value", visible: true },
                                        { id: "type", visible: true },
                                        { id: "created", visible: true },
                                        { id: "updated", visible: true }
                                    ]
                                }}
                                pageSizePreference={{
                                    title: "Page size",
                                    options: [
                                        { value: 10, label: "10 resources" },
                                        { value: 20, label: "20 resources" }
                                    ]
                                }}
                                wrapLinesPreference={{}}
                                stripedRowsPreference={{}}
                                contentDensityPreference={{}}
                                contentDisplayPreference={{
                                    options: [
                                        {
                                            id: "variable",
                                            label: "Variable name",
                                            alwaysVisible: true
                                        },
                                        { id: "value", label: "Text value" },
                                        { id: "type", label: "Type" },
                                        { id: "created", label: "Created" },
                                        { id: "updated", label: "Updated" },
                                    ]
                                }}
                                stickyColumnsPreference={{
                                    firstColumns: {
                                        title: "Stick first column(s)",
                                        description:
                                            "Keep the first column(s) visible while horizontally scrolling the table content.",
                                        options: [
                                            { label: "None", value: 0 },
                                            { label: "First column", value: 1 },
                                            { label: "First two columns", value: 2 }
                                        ]
                                    },
                                    lastColumns: {
                                        title: "Stick last column",
                                        description:
                                            "Keep the last column visible while horizontally scrolling the table content.",
                                        options: [
                                            { label: "None", value: 0 },
                                            { label: "Last column", value: 1 }
                                        ]
                                    }
                                }}
                            />
                        }
                        onSelectionChange={({ detail }) => {
                            setSelectedItem(detail.selectedItems[0] || null);
                        }}
                        onRowClick={({ detail }) => {
                            setSelectedItem(detail.item);
                        }}
                    />
                </SpaceBetween>
                <Modal
                    onDismiss={() => setVisible(false)}
                    visible={visible}
                    header="Confirm deletion"
                    footer={
                        <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button onClick={() => setVisible(false)} variant="link">Cancel</Button>
                                <Button onClick={confirmDelete} variant="primary">Delete</Button>
                            </SpaceBetween>
                        </Box>
                    }
                >
                    Are you sure you want to delete the selected item?
                </Modal>
            </AppContentLayout>
        </AppLayout>
    );
}