import AppLayout from '../../../components/layout/AppLayout';
import AppContentLayout from '../../../components/layout/ContentLayout';
import { useState } from 'react';
import SearchSideNav from "../../../components/navigation/SearchSideNavigation";
import * as React from "react";
import Table from "@cloudscape-design/components/table";
import Box from "@cloudscape-design/components/box";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import TextFilter from "@cloudscape-design/components/text-filter";
import Header from "@cloudscape-design/components/header";
import ButtonDropdown from "@cloudscape-design/components/button-dropdown";
import Pagination from "@cloudscape-design/components/pagination";
import CollectionPreferences from "@cloudscape-design/components/collection-preferences";
import {
    Container,
    KeyValuePairs,
    Link,
    StatusIndicator,
    Modal,
    SplitPanel,
    ProgressBar
} from "@cloudscape-design/components";
import { useNavigate } from 'react-router-dom';
import Icon from "@cloudscape-design/components/icon";

export default function Indices() {
    const navigate = useNavigate();
    const [activeHref, setActiveHref] = useState('/search/index/indices');
    const [selectedItem, setSelectedItem] = React.useState(null);
    const [splitPanelOpen, setSplitPanelOpen] = useState(false);
    const [items, setItems] = React.useState([
        {
            name: "schdc-2024072001",
            status: <SpaceBetween size="xxxxs">
                <StatusIndicator type="in-progress">In progress</StatusIndicator>
                <ProgressBar value={36}/>
            </SpaceBetween>,
            llmSettings: "gpt-4o-long-output-ver1.2",
            embeddingSettings: "ada-002-embed-v1.5",
            type: <SpaceBetween size="xxxxs">hr_dictionary_v2</SpaceBetween>,
            created: "2024-07-20 00:20:30",
            updated: "2024-07-21 14:30:45",
            dataSources: [
                {
                    label: "HR Dataset, Jul2028",
                    value: "hr-dataset-jul2028"
                },
                {
                    label: "Youtube Crawl Data",
                    value: "youtube-crawl-data"
                }
            ],
            runningApp: [

            ],
            autoPipeline: "Enabled"
        },
        {
            name: "schdc-2024071814",
            status: <StatusIndicator type="success">Run on App</StatusIndicator>,
            llmSettings: "gpt-4o-mini-ver2.0",
            embeddingSettings: "large-embed-v3.2",
            type: "v3",
            created: "2024-07-18 02:15:54",
            updated: "2024-07-21 12:03:57",
            dataSources: [
                {
                    label: "HR Operation dataset",
                    value: "hr-operation-dataset"
                }
            ],
            runningApp: [
                "v1.1.0"
            ],
            autoPipeline: "Disabled"
        },
        {
            name: "schdc-2024071101",
            status: <StatusIndicator type="info">Ready</StatusIndicator>,
            llmSettings: "GPT-4o-mini",
            embeddingSettings: "titan-text-embed-v2.1",
            type: <SpaceBetween size="xxxxs">hr_dictionary_v1</SpaceBetween>,
            created: "2024-07-11 00:10:15",
            updated: "2024-07-11 00:10:15",
            dataSources: [
                {
                    label: "HR Dataset, Jul2028",
                    value: "hr-dataset-jul2028"
                }
            ],
            runningApp: [

            ],
            autoPipeline: "Enabled"
        },
        {
            name: "schdc-2024062802",
            status: <StatusIndicator type="error">Error</StatusIndicator>,
            llmSettings: "Claude-sonnet-v3.5",
            embeddingSettings: "ada-002-embed-v1.5",
            type: "",
            created: "",
            updated: "",
            dataSources: [
            ],
            runningApp: [

            ],
            autoPipeline: "Disabled"
        },
        {
            name: "schdc-2024062801",
            status: <StatusIndicator type="success">Run on App</StatusIndicator>,
            llmSettings: "gpt-4o-long-output-ver1.2",
            embeddingSettings: "large-embed-v3.2",
            type: "v2",
            created: "2024-06-28 00:15:30",
            updated: "2024-06-30 18:45:20",
            dataSources: [
                {
                    label: "HR Operation dataset",
                    value: "hr-operation-dataset"
                },
                {
                    label: "HR Dataset, Jul2028",
                    value: "hr-dataset-jul2028"
                }
            ],
            runningApp: [
                "v1.0.0"
            ],
            autoPipeline: "Enabled"
        }
    ]);

    const [visible, setVisible] = React.useState(false);
    const [deleteConfirmVisible, setDeleteConfirmVisible] = React.useState(false);

    const handleFollow = (event) => {
        if (!event.detail.external) {
            event.preventDefault();
            setActiveHref(event.detail.href);
        }
    };

    const handleDelete = () => {
        setDeleteConfirmVisible(true);
    };

    const confirmDelete = () => {
        if (selectedItem) {
            const updatedItems = items.filter(item => item !== selectedItem);
            setItems(updatedItems);
            setSelectedItem(null);
            setDeleteConfirmVisible(false);
        }
    };

    return (
        <AppLayout
            breadcrumbs_props={{
                items: [
                    { text: 'Search', href: '/search/index/indices' },
                    { text: 'Search Index', href: '/search/index/indices' },
                    { text: 'Indices', href: '/search/index/indices' },
                ]
            }}
            side_nav={
                <SearchSideNav
                    activeHref={activeHref}
                    onFollow={handleFollow}>
                </SearchSideNav>}
            splitPanelOpen={splitPanelOpen}
            setSplitPanelOpen={setSplitPanelOpen}
            splitPanel={
                <SplitPanel header={
                    selectedItem ?
                        `1 item selected` :
                        "No items selected"
                }>
                    <Container
                        header={
                            <Header
                                variant="h2"
                            >
                                Index Details
                            </Header>
                        }>
                        <SpaceBetween size="l">
                            <KeyValuePairs
                                columns={2}
                                items={[
                                    {
                                        label: 'Index Name',
                                        value: selectedItem?.name ? (() => {
                                                    const dataSources = selectedItem.dataSources.map(ds => ds.value).join(',');
                                                    const runningApp = Array.isArray(selectedItem.runningApp)
                                                        ? selectedItem.runningApp.map(app => typeof app === 'string' ? app : app.props?.children || '').join(',')
                                                        : '';
                                                    const status = typeof selectedItem.status === 'string' ? selectedItem.status : selectedItem.status.props?.children || '';
                                                    const dictionary = typeof selectedItem.type === 'string' ? selectedItem.type : selectedItem.type.props?.children || '';
                                                    const llmSettings = typeof selectedItem.llmSettings === 'string' ? selectedItem.llmSettings : selectedItem.llmSettings.props?.children || '';
                                                    const embeddingSettings = typeof selectedItem.embeddingSettings === 'string' ? selectedItem.embeddingSettings : selectedItem.embeddingSettings.props?.children || '';
                                                    const url = `/search/index/indices/edit?name=${selectedItem.name}&status=${status}&dictionary=${dictionary}&llmSettings=${llmSettings}&embeddingSettings=${embeddingSettings}&dataSources=${dataSources}&runningApp=${runningApp}&created=${selectedItem.created}&updated=${selectedItem.updated}&autoPipeline=${selectedItem.autoPipeline}`;
                                                    return (<Link href={url} variant="primary">
                                                        {selectedItem.name}
                                                    </Link>);
                                                }
                                            )()
                                            : '-'
                                    },
                                    { label: 'Status', value: selectedItem?.status || '-' },
                                    {
                                        label: 'LLM Settings',
                                        value: selectedItem?.llmSettings ?
                                            <Link external href={`#`}>{selectedItem.llmSettings}</Link>
                                            : '-'
                                    },
                                    {
                                        label: 'Embedding Settings',
                                        value: selectedItem?.embeddingSettings ?
                                            <Link external href={`#`}>{selectedItem.embeddingSettings}</Link>
                                            : '-'
                                    },
                                    {
                                        label: 'Dictionary',
                                        value: selectedItem?.type ?
                                            <Link external href={`#`}>{selectedItem.type}</Link>
                                            : '-'
                                    },
                                    {
                                        label: 'Data Sources',
                                        value: selectedItem?.dataSources.length ?
                                            selectedItem.dataSources.map((ds, index) => (
                                                <SpaceBetween key={index} size="xs">
                                                    <Link external href={`#`}>{ds.label}</Link>
                                                </SpaceBetween>
                                            ))
                                            : '-'
                                    },
                                    {
                                        label: 'Running App',
                                        value: selectedItem?.runningApp.length ?
                                            selectedItem.runningApp.map((app, index) => (
                                                <SpaceBetween key={index} size="xs">
                                                    <Link external href={`#`}>{app}</Link>
                                                </SpaceBetween>
                                            ))
                                            : <Link external href={`/application/app/management`}>Create App</Link>
                                    },
                                    { label: 'Daily Indexing', value: selectedItem?.autoPipeline || '-' },
                                    { label: 'Created', value: selectedItem?.created || '-' },
                                    { label: 'Updated', value: selectedItem?.updated || '-' },
                                ]}
                            />
                        </SpaceBetween>
                    </Container>
                </SplitPanel>
            }
        >
            <AppContentLayout
                header_layout_props={{
                    text: "Indices",
                    description: "You can create indexes and set which indexes to drive the AI Assistant based on."
                }}
            >
                <SpaceBetween size="l">
                    <Table
                        renderAriaLive={({
                                             firstIndex,
                                             lastIndex,
                                             totalItemsCount
                                         }) =>
                            `Displaying items ${firstIndex} to ${lastIndex} of ${totalItemsCount}`
                        }
                        selectedItems={selectedItem ? [selectedItem] : []}
                        onSelectionChange={({ detail }) => {
                            setSelectedItem(detail.selectedItems[0] || null);
                            setSplitPanelOpen(!!detail.selectedItems[0]);
                        }}
                        ariaLabels={{
                            selectionGroupLabel: "Items selection",
                            allItemsSelectionLabel: ({ selectedItems }) =>
                                `${selectedItems.length} ${
                                    selectedItems.length === 1 ? "item" : "items"
                                } selected`,
                            itemSelectionLabel: ({ selectedItems }, item) =>
                                item.name
                        }}
                        columnDefinitions={[
                            {
                                id: "variable",
                                header: "Index name",
                                cell: item => {
                                    const dataSources = item.dataSources.map(ds => ds.value).join(',');
                                    const runningApp = Array.isArray(item.runningApp)
                                        ? item.runningApp.map(app => typeof app === 'string' ? app : app.props?.children || '').join(',')
                                        : '';
                                    const status = typeof item.status === 'string' ? item.status : item.status.props?.children || '';
                                    const dictionary = typeof item.type === 'string' ? item.type : item.type.props?.children || '';
                                    const llmSettings = typeof item.llmSettings === 'string' ? item.llmSettings : item.llmSettings.props?.children || '';
                                    const embeddingSettings = typeof item.embeddingSettings === 'string' ? item.embeddingSettings : item.embeddingSettings.props?.children || '';
                                    const url = `/search/index/indices/edit?name=${item.name}&status=${status}&dictionary=${dictionary}&llmSettings=${llmSettings}&embeddingSettings=${embeddingSettings}&dataSources=${dataSources}&runningApp=${runningApp}&created=${item.created}&updated=${item.updated}&autoPipeline=${item.autoPipeline}`;

                                    return <Link href={url} variant="primary">
                                        {item.name}
                                    </Link>
                                },
                                sortingField: "name",
                                isRowHeader: true
                            },
                            {
                                id: "value",
                                header: "Status",
                                cell: e => e.status,
                                sortingField: "status"
                            },
                            {
                                id: "llmSettings",
                                header: "LLM Settings",
                                cell: e => e.llmSettings
                            },
                            {
                                id: "embeddingSettings",
                                header: "Embedding Settings",
                                cell: e => e.embeddingSettings
                            },
                            {
                                id: "type",
                                header: "Dictionary",
                                cell: e => e.type
                            },
                            {
                                id: "dataSources",
                                header: "Data Sources",
                                cell: e => (
                                    <div>
                                        {e.dataSources.map((source, index) => (
                                            <div key={index}>{source.label}</div>
                                        ))}
                                    </div>
                                )
                            },
                            {
                                id: "runningApp",
                                header: "Running App",
                                cell: e => (
                                    <div>
                                        {e.runningApp.map((app, index) => (
                                            <div key={index}>{app}</div>
                                        ))}
                                    </div>
                                )
                            },
                            {
                                id: "created",
                                header: "Created",
                                cell: e => e.created
                            },
                            {
                                id: "updated",
                                header: "Updated",
                                cell: e => e.updated
                            },
                            {
                                id: "autoPipeline",
                                header: "Daily Indexing",
                                cell: e => e.autoPipeline
                            },
                        ]}
                        columnDisplay={[
                            { id: "variable", visible: true },
                            { id: "value", visible: true },
                            { id: "llmSettings", visible: true },
                            { id: "embeddingSettings", visible: true },
                            { id: "type", visible: true },
                            { id: "dataSources", visible: true },
                            { id: "runningApp", visible: true },
                            { id: "autoPipeline", visible: true },
                            { id: "created", visible: true },
                            { id: "updated", visible: true },
                        ]}
                        enableKeyboardNavigation
                        items={items}
                        loadingText="Loading resources"
                        selectionType="single"
                        trackBy="name"
                        empty={
                            <Box
                                margin={{ vertical: "xs" }}
                                textAlign="center"
                                color="inherit"
                            >
                                <SpaceBetween size="m">
                                    <b>No resources</b>
                                    <Button>Create resource</Button>
                                </SpaceBetween>
                            </Box>
                        }
                        filter={
                            <TextFilter
                                filteringPlaceholder="Find resources"
                                filteringText=""
                            />
                        }
                        header={
                            <Header
                                counter={
                                    selectedItem
                                        ? "(1/" + items.length + ")"
                                        : "(" + items.length + ")"
                                }
                                actions={
                                    <SpaceBetween
                                        direction="horizontal"
                                        size="xs"
                                    >
                                        <ButtonDropdown
                                            items={[
                                                {
                                                    text: "Delete",
                                                    id: "delete",
                                                    disabled: !selectedItem,
                                                }
                                            ]}
                                            onItemClick={({ detail }) => {
                                                if (detail.id === 'delete') {
                                                    handleDelete();
                                                }
                                            }}
                                        >
                                            Actions
                                        </ButtonDropdown>
                                        <Button variant="primary" href="/search/index/indices/create">
                                            Create Index
                                        </Button>
                                    </SpaceBetween>
                                }
                            >
                                Indices
                            </Header>
                        }
                        pagination={
                            <Pagination currentPageIndex={1} pagesCount={2} />
                        }
                        preferences={
                            <CollectionPreferences
                                title="Preferences"
                                confirmLabel="Confirm"
                                cancelLabel="Cancel"
                                preferences={{
                                    pageSize: 10,
                                    contentDisplay: [
                                        { id: "variable", visible: true },
                                        { id: "value", visible: true },
                                        { id: "llmSettings", visible: true },
                                        { id: "embeddingSettings", visible: true },
                                        { id: "type", visible: true },
                                        { id: "dataSources", visible: true },
                                        { id: "runningApp", visible: true },
                                        { id: "created", visible: true },
                                        { id: "updated", visible: true },
                                        { id: "autoPipeline", visible: true }
                                    ]
                                }}
                                pageSizePreference={{
                                    title: "Page size",
                                    options: [
                                        { value: 10, label: "10 resources" },
                                        { value: 20, label: "20 resources" }
                                    ]
                                }}
                                wrapLinesPreference={{}}
                                stripedRowsPreference={{}}
                                contentDensityPreference={{}}
                                contentDisplayPreference={{
                                    options: [
                                        {
                                            id: "variable",
                                            label: "Variable name",
                                            alwaysVisible: true
                                        },
                                        { id: "value", label: "Text value" },
                                        { id: "llmSettings", label: "LLM Settings" },
                                        { id: "embeddingSettings", label: "Embedding Settings" },
                                        { id: "type", label: "Type" },
                                        { id: "dataSources", label: "Data Sources" },
                                        { id: "runningApp", label: "Running App" },
                                        { id: "created", label: "Created" },
                                        { id: "updated", label: "Updated" },
                                        { id: "autoPipeline", label: "Auto Pipeline" },
                                    ]
                                }}
                                stickyColumnsPreference={{
                                    firstColumns: {
                                        title: "Stick first column(s)",
                                        description:
                                            "Keep the first column(s) visible while horizontally scrolling the table content.",
                                        options: [
                                            { label: "None", value: 0 },
                                            { label: "First column", value: 1 },
                                            { label: "First two columns", value: 2 }
                                        ]
                                    },
                                    lastColumns: {
                                        title: "Stick last column",
                                        description:
                                            "Keep the last column visible while horizontally scrolling the table content.",
                                        options: [
                                            { label: "None", value: 0 },
                                            { label: "Last column", value: 1 }
                                        ]
                                    }
                                }}
                            />
                        }
                        onRowClick={({ detail }) => {
                            setSelectedItem(detail.item);
                            setSplitPanelOpen(true);
                        }}
                    />
                </SpaceBetween>
                <Modal
                    onDismiss={() => setVisible(false)}
                    visible={visible}
                    header="Delete Error"
                >
                    Cannot delete indices with running applications.
                    Please stop the running applications and try again.
                </Modal>
                <Modal
                    onDismiss={() => setDeleteConfirmVisible(false)}
                    visible={deleteConfirmVisible}
                    header="Confirm deletion"
                    footer={
                        <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button onClick={() => setDeleteConfirmVisible(false)}>Cancel</Button>
                                <Button onClick={confirmDelete} variant="primary">Confirm</Button>
                            </SpaceBetween>
                        </Box>
                    }
                >
                    Are you sure you want to delete the selected item?
                </Modal>
            </AppContentLayout>
        </AppLayout>
    );
}