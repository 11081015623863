import BaseSideNav from "./BaseSideNavigation";
import {Box, Popover} from "@cloudscape-design/components";

export default function SearchSideNav( props ) {
    return (
        <BaseSideNav
            activeHref={props.activeHref}
            header={{
                href: '/search/index/indices',
                text: 'Search',
            }}
            items={[
                {
                    type: "section",
                    text: "Dataset Group",
                    items: [
                        {
                            type: "link", text: "Dataset Sources", href: "/search/document/datasources"
                        },
                        {
                            type: "link",
                            text: "FAQ & Answers",
                            href: "/search/document/faqanswers",
                            info: (
                                <Box color="text-status-info" variant="span">
                                    <Popover
                                        header="Introducing AI Dictionary"
                                        size="medium"
                                        triggerType="text"
                                        content={
                                            <>
                                                AWS can schedule events for your instances, such as reboot, stop/start, or retirement.{' '}
                                            </>
                                        }
                                        renderWithPortal={true}
                                        dismissAriaLabel="Close"
                                    >
                                        <Box
                                            variant="span"
                                            color="text-status-info"
                                            fontSize="body-s"
                                            fontWeight="bold"
                                            data-testid="new-feature-announcement-trigger"
                                        >
                                            PRO
                                        </Box>
                                    </Popover>
                                </Box>
                            ),
                        },
                    ]
                },
                {
                    type: "section",
                    text: "Search Index",
                    items: [
                        {
                            type: "link", text: "Indices", href: "/search/index/indices"
                        },
                        {
                            type: "link", text: "Indexing Histories", href: "/search/index/histories"
                        },
                    ]
                },
                {
                    type: "section",
                    text: "Search Tool",
                    items: [
                        {
                            type: "link",
                            text: "Documents",
                            href: "/search/index/document",
                        },
                        {
                            type: "link", text: "Search Test", href: "/search/index/search"
                        },
                    ]
                },
                {
                    type: "section",
                    text: "Dictionary",
                    items: [
                        {
                            type: "link", text: "Basic Dictionaries", href: "/search/dictionary/user"
                        },
                        {
                            type: 'link',
                            text: 'AI Dictionaries',
                            href: '/search/dictionary/ai',
                            info: (
                                <Box color="text-status-info" variant="span">
                                    <Popover
                                        header="Introducing AI Dictionary"
                                        size="medium"
                                        triggerType="text"
                                        content={
                                            <>
                                                AWS can schedule events for your instances, such as reboot, stop/start, or retirement.{' '}
                                            </>
                                        }
                                        renderWithPortal={true}
                                        dismissAriaLabel="Close"
                                    >
                                        <Box
                                            variant="span"
                                            color="text-status-info"
                                            fontSize="body-s"
                                            fontWeight="bold"
                                            data-testid="new-feature-announcement-trigger"
                                        >
                                            PRO
                                        </Box>
                                    </Popover>
                                </Box>
                            ),
                        },
                    ]
                },
                // {
                //     type: "section",
                //     text: "Ranking Features",
                //     items: [
                //         {
                //             type: 'link',
                //             text: 'Query Enhancement',
                //             href: '/search/rankingfeature/queryfeatures',
                //         },
                //         {
                //             type: 'link',
                //             text: 'Document Processing',
                //             href: '/search/rankingfeature/documentfeatures',
                //             info: (
                //                 <Box color="text-status-info" variant="span">
                //                     <Popover
                //                         header="Introducing AI Ranking Model"
                //                         size="medium"
                //                         triggerType="text"
                //                         content={
                //                             <>
                //                                 AWS can schedule events for your instances, such as reboot, stop/start, or retirement.{' '}
                //                             </>
                //                         }
                //                         renderWithPortal={true}
                //                         dismissAriaLabel="Close"
                //                     >
                //                         <Box
                //                             variant="span"
                //                             color="text-status-info"
                //                             fontSize="body-s"
                //                             fontWeight="bold"
                //                             data-testid="new-feature-announcement-trigger"
                //                         >
                //                             PRO
                //                         </Box>
                //                     </Popover>
                //                 </Box>
                //             ),
                //         },
                //     ]
                // },
                {
                    type: "section",
                    text: "Ranking Model",
                    items: [
                        {
                            type: "link", text: "Basic Models", href: "/search/rankingmodel/models"
                        },
                        {
                            type: 'link',
                            text: 'AI Models',
                            href: '/search/rankingmodel/ai',
                            info: (
                                <Box color="text-status-info" variant="span">
                                    <Popover
                                        header="Introducing AI Ranking Model"
                                        size="medium"
                                        triggerType="text"
                                        content={
                                            <>
                                                AWS can schedule events for your instances, such as reboot, stop/start, or retirement.{' '}
                                            </>
                                        }
                                        renderWithPortal={true}
                                        dismissAriaLabel="Close"
                                    >
                                        <Box
                                            variant="span"
                                            color="text-status-info"
                                            fontSize="body-s"
                                            fontWeight="bold"
                                            data-testid="new-feature-announcement-trigger"
                                        >
                                            PRO
                                        </Box>
                                    </Popover>
                                </Box>
                            ),
                        },
                    ]
                },
            ]}
        />
    );
}
