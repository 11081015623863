import React, { useState } from 'react';
import AppLayout from '../../../components/layout/AppLayout';
import AppContentLayout from '../../../components/layout/ContentLayout';
import MonitoringSideNav from '../../../components/navigation/MonitoringSideNavigation';
import {
    Button,
    Container,
    Form,
    FormField,
    Header,
    Input,
    SpaceBetween,
    TimeInput,
} from '@cloudscape-design/components';
import Multiselect from '@cloudscape-design/components/multiselect';

export default function PipelineSettingsCreate() {
    const [activeHref, setActiveHref] = useState('/evaluation/pipelinesettings');
    const [pipelineName, setPipelineName] = useState('');
    const [selectedDataSources, setSelectedDataSources] = useState([]);
    const [batchTimes, setBatchTimes] = useState(['']);

    const handleFollow = (event) => {
        if (!event.detail.external) {
            event.preventDefault();
            setActiveHref(event.detail.href);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // TODO: Implement pipeline creation logic
        console.log('Creating pipeline:', {
            name: pipelineName,
            dataSources: selectedDataSources,
            batchTimes: batchTimes,
        });
    };

    const handleAddBatchTime = () => {
        setBatchTimes([...batchTimes, '']);
    };

    const handleRemoveBatchTime = (index) => {
        const updatedTimes = [...batchTimes];
        if (updatedTimes.length > 1) {
            updatedTimes.splice(index, 1);
            setBatchTimes(updatedTimes);
        }
    };

    const handleBatchTimeChange = (index, value) => {
        const updatedTimes = [...batchTimes];
        updatedTimes[index] = value;
        setBatchTimes(updatedTimes);
    };

    return (
        <AppLayout
            breadcrumbs_props={{
                items: [
                    { text: 'Evaluation', href: '/evaluation/dashboards' },
                    { text: 'Pipeline Settings', href: '/evaluation/pipelinesettings' },
                    { text: 'Create', href: '/evaluation/pipelinesettings' },
                ],
            }}
            side_nav={
                <MonitoringSideNav activeHref={activeHref} onFollow={handleFollow} />
            }
        >
            <AppContentLayout
                header_layout_props={{
                    text: 'Pipeline Settings',
                    description: 'Configure a new evaluation pipeline.',
                }}
            >
                <Form
                    onSubmit={handleSubmit}
                    actions={
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button href="/evaluation/pipelinesettings">
                                Cancel
                            </Button>
                            <Button variant="primary" type="submit">
                                Create
                            </Button>
                        </SpaceBetween>
                    }
                >
                    <Container header={<Header variant="h2">New Pipeline</Header>}>
                        <SpaceBetween direction="vertical" size="l">
                            <FormField label="Pipeline Name">
                                <Input
                                    value={pipelineName}
                                    onChange={(event) => setPipelineName(event.detail.value)}
                                    placeholder="Enter a unique name for the pipeline"
                                />
                            </FormField>
                            <FormField
                                label="Data Sources"
                                description="Select the data sources for this pipeline."
                            >
                                <Multiselect
                                    selectedOptions={selectedDataSources}
                                    onChange={({ detail }) => setSelectedDataSources(detail.selectedOptions)}
                                    options={[
                                        {
                                            label: 'HR Dataset, Jul2028',
                                            value: 'hr-dataset-jul2028',
                                        },
                                        {
                                            label: 'Youtube Crawl Data',
                                            value: 'youtube-crawl-data',
                                        },
                                        {
                                            label: 'HR Operation dataset',
                                            value: 'hr-operation-dataset',
                                        },
                                    ]}
                                    placeholder="Select data sources"
                                />
                            </FormField>
                            <FormField
                                label="Daily Batch Times"
                                description="Select the times at which the pipeline should run daily."
                            >
                                <SpaceBetween size="m">
                                    {batchTimes.map((time, index) => (
                                        <SpaceBetween key={index} direction="horizontal" size="xs">
                                            <TimeInput
                                                onChange={({ detail }) => handleBatchTimeChange(index, detail.value)}
                                                value={time}
                                                format="hh:mm"
                                                placeholder="hh:mm"
                                            />
                                            <Button
                                                variant="icon"
                                                iconName="remove"
                                                onClick={() => handleRemoveBatchTime(index)}
                                            />
                                        </SpaceBetween>
                                    ))}
                                    <Button onClick={handleAddBatchTime}>
                                        Add batch time
                                    </Button>
                                </SpaceBetween>
                            </FormField>
                        </SpaceBetween>
                    </Container>
                </Form>
            </AppContentLayout>
        </AppLayout>
    );
}