import React, { useState } from 'react';
import TopNavigation from "@cloudscape-design/components/top-navigation";
import { Badge, Link, Modal, Box, SpaceBetween, Button } from "@cloudscape-design/components";

export default function AppTopNavigation({ username, useremail, onSignOut }) {
    const [visible, setVisible] = useState(false);

    const handleItemClick = (e) => {
        if (e.detail.id === 'signout') {
            onSignOut();
        } else {
            setVisible(true);
        }
    };

    return (
        <>
            <TopNavigation
                identity={{
                    href: "/search/index/indices",
                    logo: {
                        src: "/logo.png",
                        alt: "Searchdoc.ai"
                    }
                }}
                utilities={[
                    {
                        type: "button",
                        text: "Search",
                        variant: "link",
                        href: "/search/index/indices",
                    },
                    {
                        type: "button",
                        text: "Access Group",
                        variant: "link",
                        href: "/access/accessgroups",
                    },
                    {
                        type: "button",
                        text: "Language Model",
                        variant: "link",
                        href: "/languagemodel/llm",
                    },
                    {
                        type: "button",
                        text: "Monitoring",
                        variant: "link",
                        href: "/evaluation/dashboards",
                    },
                    {
                        type: "button",
                        text: "Application",
                        variant: "link",
                        href: "/application/app/management",
                    },
                    {
                        type: "menu-dropdown",
                        iconName: "settings",
                        ariaLabel: "Settings",
                        title: "Settings",
                        items: [
                            {
                                id: "region-setting",
                                text: "Region Settings"
                            },
                            {
                                id: "language-setting",
                                text: "Language settings"
                            }
                        ],
                        onItemClick: handleItemClick
                    },
                    {
                        type: "menu-dropdown",
                        text: username,
                        description: useremail,
                        iconName: "user-profile",
                        onItemClick: handleItemClick,
                        items: [
                            { id: "profile", text: "Profile" },
                            { id: "preferences", text: "Preferences" },
                            {
                                id: "support-group",
                                text: "Support",
                                items: [
                                    {
                                        id: "documentation",
                                        text: "Documentation",
                                        href: "#",
                                        external: true,
                                        externalIconAriaLabel: " (opens in new tab)"
                                    },
                                    {
                                        id: "Support",
                                        text: "Support",
                                        href: "#",
                                        external: true,
                                        externalIconAriaLabel: " (opens in new tab)"
                                    }
                                ]
                            },
                            { id: "signout", text: "Sign out" }
                        ]
                    }
                ]}
            />
            <Modal
                onDismiss={() => setVisible(false)}
                visible={visible}
                header="Your request has been submitted."
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button onClick={() => setVisible(false)} variant="primary">OK</Button>
                        </SpaceBetween>
                    </Box>
                }
            >
                This feature will be supported in the future.
                Thank you for your patience.
            </Modal>
        </>
    );
}