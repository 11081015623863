import { I18nProvider } from '@cloudscape-design/components/i18n';
import messages from '@cloudscape-design/components/i18n/messages/all.en';

export const LOCALE = 'en';

export function I18nWrapper({ children }) {
    return (
        <I18nProvider locale={LOCALE} messages={[messages]}>
            {children}
        </I18nProvider>
    );
}