import AppLayout from '../../../components/layout/AppLayout';
import AppContentLayout from '../../../components/layout/ContentLayout';
import { useState, useEffect } from 'react';
import SearchSideNav from "../../../components/navigation/SearchSideNavigation";
import {
    Container,
    FormField,
    Header,
    Input,
    SpaceBetween,
    Button,
    Textarea,
    Multiselect,
    Modal,
    Link,
    Select,
    TimeInput
} from "@cloudscape-design/components";
import Box from "@cloudscape-design/components/box";
import { useLocation } from 'react-router-dom';

export default function IndexEdit() {
    const [activeHref, setActiveHref] = useState('/search/index/indices');
    const [nameValue, setNameValue] = useState('');
    const [descriptionValue, setDescriptionValue] = useState('');
    const [selectedDictionary, setSelectedDictionary] = useState(null);
    const [selectedDataSources, setSelectedDataSources] = useState([]);
    const [visible, setVisible] = useState(false);
    const [selectedLLMSetting, setSelectedLLMSetting] = useState(null);
    const [selectedEmbeddingSetting, setSelectedEmbeddingSetting] = useState(null);
    const [autoIndexingOption, setAutoIndexingOption] = useState({ label: 'Disabled', value: 'disabled' });
    const [batchTimes, setBatchTimes] = useState(['']);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    useEffect(() => {
        const name = queryParams.get('name');
        const dictionary = queryParams.get('dictionary');
        const llmSettings = queryParams.get('llmSettings');
        const embeddingSettings = queryParams.get('embeddingSettings');
        const dataSources = queryParams.get('dataSources');
        const created = queryParams.get('created' || "");
        const updated = queryParams.get('updated' || "");
        const autoIndexing = queryParams.get('autoIndexing');
        const batchTimesParam = queryParams.get('batchTimes');

        if (name) setNameValue(name);
        if (dictionary) setSelectedDictionary({ label: dictionary, value: dictionary });
        if (llmSettings) setSelectedLLMSetting({ label: llmSettings, value: llmSettings });
        if (embeddingSettings) setSelectedEmbeddingSetting({ label: embeddingSettings, value: embeddingSettings });
        if (dataSources) setSelectedDataSources(dataSources.split(',').map(ds => ({ label: ds, value: ds })));
        if (autoIndexing) setAutoIndexingOption({ label: autoIndexing === 'disabled' ? 'Disabled' : 'Enabled', value: autoIndexing });
        if (batchTimesParam) setBatchTimes(batchTimesParam.split(','));
    }, []);

    const handleFollow = (event) => {
        if (!event.detail.external) {
            event.preventDefault();
            setActiveHref(event.detail.href);
        }
    };

    const handleCreateIndex = () => {
        setVisible(true);
    };

    const handleAutoIndexingOptionChange = (event) => {
        setAutoIndexingOption(event.detail.selectedOption);
    };

    const handleBatchTimeChange = (index, value) => {
        const updatedTimes = [...batchTimes];
        updatedTimes[index] = value;
        setBatchTimes(updatedTimes);
    };

    const handleAddBatchTime = () => {
        setBatchTimes([...batchTimes, '']);
    };

    const handleRemoveBatchTime = (index) => {
        const updatedTimes = batchTimes.filter((_, i) => i !== index);
        setBatchTimes(updatedTimes);
    };

    return (
        <AppLayout
            breadcrumbs_props={{
                items:[
                    { text: 'Search', href: '/search/index/indices' },
                    { text: 'Search Index', href: '/search/index/indices' },
                    { text: 'Indices', href: '/search/index/indices' },
                    { text: 'Edit', href: '/search/index/indices/edit' },
                ]
            }}
            side_nav={
                <SearchSideNav
                    activeHref={activeHref}
                    onFollow={handleFollow}>
                </SearchSideNav>}
        >
            <AppContentLayout
                header_layout_props={{
                    text: "Indices",
                    description: "Edit a new index for your search service."
                }}
            >
                <SpaceBetween size="l">
                    <Container
                        header={
                            <Header
                                variant="h2"
                                actions={
                                    <SpaceBetween
                                        direction="horizontal"
                                        size="xs"
                                    >
                                        <Button variant="primary" onClick={handleCreateIndex}>Save</Button>
                                        <Button href="/search/index/indices">Cancel</Button>
                                    </SpaceBetween>
                                }>
                                {queryParams.get('name') ? "Edit Index" : "New Index"}
                            </Header>
                        }>
                        <SpaceBetween size="l">
                            <FormField
                                label="Index Name"
                                description="Enter a unique name for the index."
                            >
                                <Input
                                    value={nameValue}
                                    onChange={({ detail }) => setNameValue(detail.value)}
                                    placeholder="Enter index name"
                                />
                            </FormField>
                            <FormField
                                label="Description"
                                description="Provide a description for the index."
                            >
                                <Textarea
                                    value={descriptionValue}
                                    onChange={({ detail }) => setDescriptionValue(detail.value)}
                                    placeholder="Enter description"
                                />
                            </FormField>
                            <FormField
                                label="LLM Settings"
                                description="Select the LLM setting to use for the index."
                            >
                                <Select
                                    selectedOption={selectedLLMSetting}
                                    onChange={({ detail }) =>
                                        setSelectedLLMSetting(detail.selectedOption)
                                    }
                                    options={[
                                        { label: "gpt-4o-long-output-ver1.2", value: "gpt-4o-long-output-ver1.2" },
                                        { label: "gpt-4o-mini-ver2.0", value: "gpt-4o-mini-ver2.0" },
                                        { label: "GPT-4o-mini", value: "GPT-4o-mini" },
                                        { label: "Claude-sonnet-v3.5", value: "Claude-sonnet-v3.5" }
                                    ]}
                                    placeholder="Select LLM setting"
                                />
                            </FormField>
                            <FormField
                                label="Embedding Settings"
                                description="Select the embedding setting to use for the index."
                            >
                                <Select
                                    selectedOption={selectedEmbeddingSetting}
                                    onChange={({ detail }) =>
                                        setSelectedEmbeddingSetting(detail.selectedOption)
                                    }
                                    options={[
                                        { label: "ada-002-embed-v1.5", value: "ada-002-embed-v1.5" },
                                        { label: "large-embed-v3.2", value: "large-embed-v3.2" },
                                        { label: "titan-text-embed-v2.1", value: "titan-text-embed-v2.1" }
                                    ]}
                                    placeholder="Select embedding setting"
                                />
                            </FormField>
                            <FormField
                                label="Dictionary"
                                description="Select the dictionary version to use for the index."
                            >
                                <Select
                                    selectedOption={selectedDictionary}
                                    onChange={({ detail }) =>
                                        setSelectedDictionary(detail.selectedOption)
                                    }
                                    options={[
                                        {
                                            label: "hr_dictionary_v2 (AI Dictionary)",
                                            value: "hr_dictionary_v2",
                                        },
                                        {
                                            label: "hr_dictionary_v1 (AI Dictionary)",
                                            value: "hr_dictionary_v1",
                                        },
                                        {
                                            label: "v3",
                                            value: "v3",
                                        },
                                        {
                                            label: "v2",
                                            value: "v2",
                                        },
                                        {
                                            label: "v1",
                                            value: "v1",
                                        },
                                        {
                                            label: "v0",
                                            value: "v0",
                                        }
                                    ]}
                                    placeholder="Select dictionary version"
                                />
                            </FormField>
                            <FormField
                                label="Data Sources"
                                description="Select the data sources to include in the index."
                            >
                                <Multiselect
                                    selectedOptions={selectedDataSources}
                                    onChange={({ detail }) =>
                                        setSelectedDataSources(detail.selectedOptions)
                                    }
                                    options={[
                                        {
                                            label: "HR Dataset, Jul2028",
                                            value: "hr-dataset-jul2028"
                                        },
                                        {
                                            label: "Youtube Crawl Data",
                                            value: "youtube-crawl-data"
                                        },
                                        {
                                            label: "HR Operation dataset",
                                            value: "hr-operation-dataset"
                                        }
                                    ]}
                                    placeholder="Select data sources"
                                />
                            </FormField>
                            <FormField label="Daily Indexing">
                                <Select
                                    options={[
                                        { label: 'Disabled', value: 'disabled' },
                                        { label: 'Enabled', value: 'daily' },
                                    ]}
                                    selectedOption={autoIndexingOption}
                                    onChange={handleAutoIndexingOptionChange}
                                    placeholder="Choose auto indexing option"
                                />
                            </FormField>
                            {autoIndexingOption.value !== 'disabled' && (
                                <SpaceBetween size="m">
                                    {batchTimes.map((time, index) => (
                                        <SpaceBetween key={index} direction="horizontal" size="xs">
                                            <TimeInput
                                                onChange={({ detail }) => handleBatchTimeChange(index, detail.value)}
                                                value={time}
                                                format="hh:mm"
                                                placeholder="hh:mm"
                                            />
                                            <Button
                                                variant="icon"
                                                iconName="remove"
                                                onClick={() => handleRemoveBatchTime(index)}
                                            />
                                        </SpaceBetween>
                                    ))}
                                    <Button onClick={handleAddBatchTime}>
                                        Add batch time
                                    </Button>
                                </SpaceBetween>
                            )}
                        </SpaceBetween>
                    </Container>
                </SpaceBetween>
                <Modal
                    onDismiss={() => setVisible(false)}
                    visible={visible}
                    header="Your request has been submitted."
                    footer={
                        <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button onClick={() => setVisible(false)} variant="primary">OK</Button>
                            </SpaceBetween>
                        </Box>
                    }
                >
                    This feature will be supported in the future.
                    Thank you for your patience.
                </Modal>
            </AppContentLayout>
        </AppLayout>
    );
}