import AppLayout from '../../../components/layout/AppLayout';
import AppContentLayout from '../../../components/layout/ContentLayout';
import { useState } from 'react';
import SearchSideNav from "../../../components/navigation/SearchSideNavigation";
import {
    Container,
    FormField,
    Header,
    Link,
    SpaceBetween,
    Table,
    Button,
    TextFilter,
    CollectionPreferences,
    Pagination,
    ButtonDropdown,
    Modal,
    Box,
} from "@cloudscape-design/components";
import FileUpload from "@cloudscape-design/components/file-upload";

export default function FaqAnswers() {
    const [activeHref, setActiveHref] = useState('/search/document/faqanswers');
    const [selectedItems, setSelectedItems] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [tableItems, setTableItems] = useState([
        {
            name: "additional_faq.txt",
            size: "0.5 MB",
            uploadedAt: "2024-07-10 09:15:00",
        },
        {
            name: "faq_v2.json",
            size: "1.8 MB",
            uploadedAt: "2024-07-05 14:45:30",
        },
        {
            name: "faq_v1.csv",
            size: "2.5 MB",
            uploadedAt: "2024-07-01 10:30:00",
        },
    ]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

    const handleFollow = (event) => {
        if (!event.detail.external) {
            event.preventDefault();
            setActiveHref(event.detail.href);
        }
    };

    const handleUpload = () => {
        const newItems = uploadedFiles.map(file => {
            const now = new Date();
            const formattedDate = now.toISOString().split('T')[0];
            const formattedTime = now.toTimeString().split(' ')[0];
            return {
                name: file.name,
                size: `${(file.size / 1024 / 1024).toFixed(1)} MB`,
                uploadedAt: `${formattedDate} ${formattedTime}`,
            };
        });
        setTableItems([...newItems, ...tableItems]);
        setUploadedFiles([]);
    };

    const handleCancel = () => {
        setUploadedFiles([]);
    };

    const validateFileExtension = (file) => {
        const validExtensions = ['csv', 'txt', 'json', 'pdf', 'docx', 'pptx', 'xlsx'];
        const extension = file.name.split('.').pop().toLowerCase();
        return validExtensions.includes(extension);
    };

    const handleFileChange = ({ detail }) => {
        const invalidFiles = detail.value.filter(file => !validateFileExtension(file));
        if (invalidFiles.length > 0) {
            setModalMessage(`Invalid file type. Please upload only PDF, DOCX, PPTX, XLSX, CSV, TXT, JSON.`);
            setIsModalVisible(true);
            setUploadedFiles([]);
        } else {
            setUploadedFiles(detail.value);
        }
    };

    const handleDelete = () => {
        setTableItems(tableItems.filter(item => !selectedItems.includes(item)));
        setSelectedItems([]);
        setIsDeleteModalVisible(false);
    };

    const handleItemClick = ({ detail }) => {
        if (detail.id === 'delete') {
            setIsDeleteModalVisible(true);
        }
    };

    return (
        <AppLayout
            breadcrumbs_props={{
                items:[
                    { text: 'Search', href: '/search/index/indices' },
                    { text: 'Document', href: '/search/document/datasources' },
                    { text: 'FAQ & Answers', href: '/search/document/faqanswers' },
                ]
            }}
            side_nav={
                <SearchSideNav
                    activeHref={activeHref}
                    onFollow={handleFollow}>
                </SearchSideNav>}
        >
            <AppContentLayout
                header_layout_props={{
                    text: "FAQ & Answers",
                    description: "Manage your FAQ & Answers by uploading files."
                }}
            >
                <SpaceBetween size="l">
                    <Container
                        header={
                            <Header
                                variant="h2"
                                actions={
                                    <SpaceBetween
                                        direction="horizontal"
                                        size="xs"
                                    >
                                        <Button variant="primary" onClick={handleUpload}>Upload</Button>
                                        <Button onClick={handleCancel}>Cancel</Button>
                                    </SpaceBetween>
                                }>
                                File Upload
                            </Header>
                        }>
                        <FormField
                            label="Upload FAQ & Answers files"
                            description="Upload files containing FAQ questions and answers. Supported file formats: PDF, DOCX, PPTX, XLSX, CSV, TXT, JSON"
                        >
                            <FileUpload
                                onChange={handleFileChange}
                                value={uploadedFiles}
                                i18nStrings={{
                                    uploadButtonText: e =>
                                        e ? "Choose files" : "Choose file",
                                    dropzoneText: e =>
                                        e
                                            ? "Drop files to upload"
                                            : "Drop file to upload",
                                    removeFileAriaLabel: e =>
                                        `Remove file ${e + 1}`,
                                    limitShowFewer: "Show fewer files",
                                    limitShowMore: "Show more files",
                                    errorIconAriaLabel: "Error"
                                }}
                                multiple
                                constraintText="Supported file formats: PDF, DOCX, PPTX, XLSX, CSV, TXT, JSON"
                            />
                        </FormField>
                    </Container>

                    <Table
                        onSelectionChange={({ detail }) =>
                            setSelectedItems(detail.selectedItems)
                        }
                        selectedItems={selectedItems}
                        columnDefinitions={[
                            {
                                id: "name",
                                header: "File Name",
                                cell: e => e.name,
                                isRowHeader: true
                            },
                            {
                                id: "size",
                                header: "File Size",
                                cell: e => e.size,
                            },
                            {
                                id: "uploadedAt",
                                header: "Uploaded At",
                                cell: e => e.uploadedAt,
                            },
                        ]}
                        items={tableItems}
                        loadingText="Loading files"
                        selectionType="multi"
                        trackBy="name"
                        empty={
                            <Container
                                header={
                                    <Header variant="h2">
                                        No files uploaded
                                    </Header>
                                }>
                                <SpaceBetween direction="vertical" size="s">
                                    <Link variant="primary" href="#">
                                        Upload a file
                                    </Link>
                                    <span>
                                        Or drag and drop files to upload
                                    </span>
                                </SpaceBetween>
                            </Container>
                        }
                        filter={
                            <TextFilter
                                filteringPlaceholder="Find files"
                                filteringText=""
                            />
                        }
                        header={
                            <Header
                                counter={
                                    selectedItems.length
                                        ? `(${selectedItems.length}/${tableItems.length})`
                                        : `(${tableItems.length})`
                                }
                                actions={
                                    <SpaceBetween
                                        direction="horizontal"
                                        size="xs"
                                    >
                                        <ButtonDropdown
                                            items={[
                                                {
                                                    text: "Delete",
                                                    id: "delete",
                                                    disabled: selectedItems.length === 0
                                                },
                                            ]}
                                            onItemClick={handleItemClick}
                                        >
                                            Actions
                                        </ButtonDropdown>
                                    </SpaceBetween>
                                }
                            >
                                Uploaded Files
                            </Header>
                        }
                        pagination={
                            <Pagination
                                currentPageIndex={1}
                                pagesCount={Math.ceil(tableItems.length / 10)}
                            />
                        }
                        preferences={
                            <CollectionPreferences
                                title="Preferences"
                                confirmLabel="Confirm"
                                cancelLabel="Cancel"
                                preferences={{
                                    pageSize: 10,
                                    contentDisplay: [
                                        { id: "name", visible: true },
                                        { id: "size", visible: true },
                                        { id: "uploadedAt", visible: true },
                                    ]
                                }}
                                pageSizePreference={{
                                    title: "Page size",
                                    options: [
                                        { value: 10, label: "10 files" },
                                        { value: 20, label: "20 files" }
                                    ]
                                }}
                            />
                        }
                        onRowClick={({ detail }) => {
                            if (selectedItems.includes(detail.item)) {
                                setSelectedItems(selectedItems.filter(item => item !== detail.item));
                            } else {
                                setSelectedItems([...selectedItems, detail.item]);
                            }
                        }}
                    />
                </SpaceBetween>

                <Modal
                    onDismiss={() => setIsModalVisible(false)}
                    visible={isModalVisible}
                    footer={
                        <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button onClick={() => setIsModalVisible(false)} variant="primary">OK</Button>
                            </SpaceBetween>
                        </Box>
                    }
                    header="File Upload Error"
                >
                    {modalMessage}
                </Modal>

                <Modal
                    onDismiss={() => setIsDeleteModalVisible(false)}
                    visible={isDeleteModalVisible}
                    footer={
                        <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button onClick={() => setIsDeleteModalVisible(false)}>Cancel</Button>
                                <Button onClick={handleDelete} variant="primary">Delete</Button>
                            </SpaceBetween>
                        </Box>
                    }
                    header="Confirm deletion"
                >
                    Are you sure you want to delete {selectedItems.length} item(s)?
                </Modal>
            </AppContentLayout>
        </AppLayout>
    );
}