import React, {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import AppLayout from '../../components/layout/AppLayout';
import AppContentLayout from '../../components/layout/ContentLayout';
import LanguageModelSideNav from "../../components/navigation/LanguageModelSideNavigation";
import {
    Box,
    Button,
    ButtonDropdown,
    CollectionPreferences,
    Container,
    Header,
    KeyValuePairs,
    Modal,
    Pagination,
    SpaceBetween,
    SplitPanel,
    Table,
    TextFilter
} from "@cloudscape-design/components";

export default function LLMSettings() {
    const navigate = useNavigate();
    const [activeHref, setActiveHref] = useState('/languagemodel/llm');
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [splitPanelOpen, setSplitPanelOpen] = useState(false);
    const [visible, setVisible] = useState(false);
    const [items, setItems] = useState([
        {
            modelSettingName: "gpt-4o-long-output-ver1.2",
            hostingEnvironment: "OpenAI API",
            modelVendor: "OpenAI",
            modelName: "GPT-4o",
            created: "2024-07-23 09:15:45",
        },
        {
            modelSettingName: "gpt-4o-mini-ver2.0",
            hostingEnvironment: "OpenAI API",
            modelVendor: "OpenAI",
            modelName: "GPT-4o-mini",
            created: "2024-07-22 09:15:45",
        },
        {
            modelSettingName: "claude-sonnet-ver7",
            hostingEnvironment: "AWS Hosted",
            modelVendor: "Anthropic",
            modelName: "Claude-sonnet-v3.5",
            created: "2024-07-20 11:32:06",
        },
        {
            modelSettingName: "claude-haiku-ver3.1",
            hostingEnvironment: "Anthropic API",
            modelVendor: "Anthropic",
            modelName: "Claude-haiku-v3.0",
            created: "2024-07-15 00:20:30",
        },
    ]);

    const handleFollow = (event) => {
        if (!event.detail.external) {
            event.preventDefault();
            setActiveHref(event.detail.href);
        }
    };

    const handleDelete = () => {
        if (selectedItems.length > 0) {
            setVisible(true);
        }
    };

    const confirmDelete = () => {
        setItems(items.filter(item => !selectedItems.includes(item)));
        setSelectedItems([]);
        setVisible(false);
    };

    const handleCreateOrEdit = (item) => {
        const queryParams = new URLSearchParams({
            modelSettingName: item ? item.modelSettingName : '',
            hostingEnvironment: item ? item.hostingEnvironment : '',
            modelVendor: item ? item.modelVendor : '',
            modelName: item ? item.modelName : '',
            created: item ? item.created : ''
        }).toString();
        navigate(`/languagemodel/llm/create?${queryParams}`);
    };

    return (
        <AppLayout
            breadcrumbs_props={{
                items:[
                    { text: 'Language Model', href: '/languagemodel/llm' },
                    { text: 'LLM Setting', href: '/languagemodel/llm' },
                ]
            }}
            side_nav={
                <LanguageModelSideNav
                    activeHref={activeHref}
                    onFollow={handleFollow}>
                </LanguageModelSideNav>}

            splitPanelOpen={splitPanelOpen}
            setSplitPanelOpen={setSplitPanelOpen}
            splitPanel={
                <SplitPanel header={
                    selectedItem ?
                        "'" + selectedItem.modelSettingName + "' selected" :
                        "No model selected"}>
                    <Container>
                        <SpaceBetween size="l">
                            <KeyValuePairs
                                columns={2}
                                items={[
                                    { label: 'Model Setting Name', value: selectedItem ? selectedItem.modelSettingName : "-" },
                                    { label: 'Hosting Environment', value: selectedItem ? selectedItem.hostingEnvironment : "-" },
                                    { label: 'Model Vendor', value: selectedItem ? selectedItem.modelVendor : "-" },
                                    { label: 'Model Name', value: selectedItem ? selectedItem.modelName : "-" },
                                    { label: 'Created Time', value: selectedItem ? selectedItem.created : "-" },
                                ]}
                            />
                        </SpaceBetween>
                    </Container>
                </SplitPanel>
            }
        >
            <AppContentLayout
                header_layout_props={{
                    text: "LLM Setting",
                    description: "Select and manage Large Language Models (LLM) or Smaller Language Models (SLM) for your search applications."
                }}
            >
                <SpaceBetween size="l">
                    <Table
                        renderAriaLive={({
                                             firstIndex,
                                             lastIndex,
                                             totalItemsCount
                                         }) =>
                            `Displaying items ${firstIndex} to ${lastIndex} of ${totalItemsCount}`
                        }
                        onSelectionChange={({ detail }) => {
                            setSelectedItems(detail.selectedItems);
                            setSelectedItem(detail.selectedItems[0]);
                            setSplitPanelOpen(true);
                        }}
                        selectedItems={selectedItems}
                        selectionType="multi"
                        ariaLabels={{
                            selectionGroupLabel: "Items selection",
                            allItemsSelectionLabel: ({ selectedItems }) =>
                                `${selectedItems.length} ${
                                    selectedItems.length === 1 ? "item" : "items"
                                } selected`,
                            itemSelectionLabel: ({ selectedItems }, item) =>
                                item.modelSettingName
                        }}
                        columnDefinitions={[
                            {
                                id: "modelSettingName",
                                header: "Model Setting Name",
                                cell: e => (
                                    <Link
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handleCreateOrEdit(e);
                                        }}
                                        href="#"
                                    >
                                        {e.modelSettingName}
                                    </Link>
                                ),
                                sortingField: "modelSettingName",
                                isRowHeader: true
                            },
                            {
                                id: "hostingEnvironment",
                                header: "Hosting Environment",
                                cell: e => e.hostingEnvironment,
                                sortingField: "hostingEnvironment"
                            },
                            {
                                id: "modelVendor",
                                header: "Model Vendor",
                                cell: e => e.modelVendor,
                                sortingField: "modelVendor"
                            },
                            {
                                id: "modelName",
                                header: "Model Name",
                                cell: e => e.modelName,
                                sortingField: "modelName"
                            },
                            {
                                id: "created",
                                header: "Created",
                                cell: e => e.created
                            },
                        ]}
                        columnDisplay={[
                            { id: "modelSettingName", visible: true },
                            { id: "hostingEnvironment", visible: true },
                            { id: "modelVendor", visible: true },
                            { id: "modelName", visible: true },
                            { id: "created", visible: true },
                        ]}
                        enableKeyboardNavigation
                        items={items}
                        loadingText="Loading resources"
                        trackBy="modelSettingName"
                        empty={
                            <Box
                                margin={{ vertical: "xs" }}
                                textAlign="center"
                                color="inherit"
                            >
                                <SpaceBetween size="m">
                                    <b>No models</b>
                                    <Button onClick={() => handleCreateOrEdit()}>Create resource</Button>
                                </SpaceBetween>
                            </Box>
                        }
                        filter={
                            <TextFilter
                                filteringPlaceholder="Find resources"
                                filteringText=""
                            />
                        }
                        header={
                            <Header
                                counter={
                                    selectedItems.length
                                        ? "(" + selectedItems.length + "/" + items.length + ")"
                                        : "(" + items.length + ")"
                                }
                                actions={
                                    <SpaceBetween
                                        direction="horizontal"
                                        size="xs"
                                    >
                                        <ButtonDropdown
                                            items={[
                                                {
                                                    text: "Delete",
                                                    id: "rm",
                                                    disabled: selectedItems.length === 0
                                                },
                                            ]}
                                            onItemClick={({ detail }) => {
                                                if (detail.id === "rm") {
                                                    handleDelete();
                                                }
                                            }}
                                        >
                                            Actions
                                        </ButtonDropdown>
                                        <Button variant="primary" onClick={() => handleCreateOrEdit()}>Create Model</Button>
                                    </SpaceBetween>
                                }
                            >
                                Models
                            </Header>
                        }
                        pagination={
                            <Pagination currentPageIndex={1} pagesCount={1} />
                        }
                        preferences={
                            <CollectionPreferences
                                title="Preferences"
                                confirmLabel="Confirm"
                                cancelLabel="Cancel"
                                preferences={{
                                    pageSize: 10,
                                    contentDisplay: [
                                        { id: "modelSettingName", visible: true },
                                        { id: "hostingEnvironment", visible: true },
                                        { id: "modelVendor", visible: true },
                                        { id: "modelName", visible: true },
                                        { id: "created", visible: true }
                                    ]
                                }}
                                pageSizePreference={{
                                    title: "Page size",
                                    options: [
                                        { value: 10, label: "10 resources" },
                                        { value: 20, label: "20 resources" }
                                    ]
                                }}
                                wrapLinesPreference={{}}
                                stripedRowsPreference={{}}
                                contentDensityPreference={{}}
                                contentDisplayPreference={{
                                    options: [
                                        { id: "modelSettingName", label: "Model Setting Name", alwaysVisible: true },
                                        { id: "hostingEnvironment", label: "Hosting Environment" },
                                        { id: "modelVendor", label: "Model Vendor" },
                                        { id: "modelName", label: "Model Name" },
                                        { id: "created", label: "Created" },
                                    ]
                                }}
                                stickyColumnsPreference={{
                                    firstColumns: {
                                        title: "Stick first column(s)",
                                        description:
                                            "Keep the first column(s) visible while horizontally scrolling the table content.",
                                        options: [
                                            { label: "None", value: 0 },
                                            { label: "First column", value: 1 },
                                            { label: "First two columns", value: 2 }
                                        ]
                                    },
                                    lastColumns: {
                                        title: "Stick last column",
                                        description:
                                            "Keep the last column visible while horizontally scrolling the table content.",
                                        options: [
                                            { label: "None", value: 0 },
                                            { label: "Last column", value: 1 }
                                        ]
                                    }
                                }}
                            />
                        }
                        onRowClick={({ detail }) => {
                            const clickedItem = detail.item;
                            setSelectedItems(prevItems => {
                                if (prevItems.includes(clickedItem)) {
                                    return prevItems.filter(item => item !== clickedItem);
                                } else {
                                    return [...prevItems, clickedItem];
                                }
                            });
                            setSelectedItem(clickedItem);
                            setSplitPanelOpen(true);
                        }}
                    />
                </SpaceBetween>
            </AppContentLayout>
            <Modal
                onDismiss={() => setVisible(false)}
                visible={visible}
                header="Confirm deletion"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button onClick={() => setVisible(false)} variant="link">Cancel</Button>
                            <Button onClick={confirmDelete} variant="primary">Delete</Button>
                        </SpaceBetween>
                    </Box>

                }
            >
                Are you sure you want to delete {selectedItems.length} item(s)?
            </Modal>
        </AppLayout>
    );
}