import AppLayout from '../../../components/layout/AppLayout';
import AppContentLayout from '../../../components/layout/ContentLayout';
import { useState } from 'react';
import SearchSideNav from "../../../components/navigation/SearchSideNavigation";
import {
    Container,
    FormField,
    Header,
    Input,
    KeyValuePairs,
    Link,
    RadioGroup,
    Slider,
    SplitPanel,
    StatusIndicator,
    Textarea,
    Modal,
    Tiles
} from "@cloudscape-design/components";
import * as PropTypes from "prop-types";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import Box from "@cloudscape-design/components/box";
import TextFilter from "@cloudscape-design/components/text-filter";
import ButtonDropdown from "@cloudscape-design/components/button-dropdown";
import Pagination from "@cloudscape-design/components/pagination";
import CollectionPreferences from "@cloudscape-design/components/collection-preferences";
import Table from "@cloudscape-design/components/table";
import * as React from "react";
import { useNavigate } from 'react-router-dom';
import ServiceSideNavigation from "../../../components/navigation/ServiceSideNavigation";

function InfoLink(props) {
    return null;
}

InfoLink.propTypes = {
    onFollow: PropTypes.func,
    id: PropTypes.string
};

export default function NoticeMessages() {
    const navigate = useNavigate();
    const [activeHref, setActiveHref] = useState('/application/app/notices');
    const [selectedItem, setSelectedItem] = React.useState(null);
    const [splitPanelOpen, setSplitPanelOpen] = useState(false);
    const [visible, setVisible] = React.useState(false);
    const [visible2, setVisible2] = React.useState(false);
    const [items, setItems] = React.useState([
        {
            name: "Welcome Message",
            message: "Welcome to our application! We hope you enjoy your experience with our services and find everything you need. If you have any questions, please don't hesitate to contact our support team.",
            created: "2024-07-20 11:32:06"
        },
        {
            name: "Maintenance Notice",
            message: "Our system will be undergoing maintenance on July 25th from 2 AM to 4 AM. During this time, some services may be unavailable. We apologize for any inconvenience this may cause.",
            created: "2024-07-18 12:45:24"
        },
        {
            name: "New Feature Announcement",
            message: "We're excited to announce our latest feature: real-time collaboration! Now you can work together with your team members in real-time, boosting productivity and teamwork.",
            created: "2024-07-15 00:20:30"
        },
    ]);

    const handleFollow = (event) => {
        if (!event.detail.external) {
            event.preventDefault();
            setActiveHref(event.detail.href);
        }
    };

    const handleDelete = () => {
        if (selectedItem) {
            setVisible(true);
        }
    };

    const confirmDelete = () => {
        setItems(items.filter(item => item !== selectedItem));
        setSelectedItem(null);
        setVisible(false);
    };

    const handleCreateClick = () => {
        setVisible2(true);
    };

    const onItemClick = ({ detail }) => {
        if (detail.id === 'delete') {
            handleDelete();
        } else if (detail.id === 'edit') {
            // Navigate to edit page
            // navigateToEdit(selectedItem);
        }
    };

    const onRowClick = ({ detail }) => {
        setSelectedItem(detail.item);
    };

    return (
        <AppLayout
            breadcrumbs_props={{
                items: [
                    { text: 'Application', href: '/application' },
                    { text: 'App', href: '/application/app' },
                    { text: 'Notices', href: '/application/app/notices' },
                ]
            }}
            side_nav={
                <ServiceSideNavigation
                    activeHref={activeHref}
                    onFollow={handleFollow}>
                </ServiceSideNavigation>}
        >
            <AppContentLayout
                header_layout_props={{
                    text: "Notice Messages",
                    description: "Manage application notice messages here."
                }}
            >
                <SpaceBetween size="l">
                    <Table
                        columnDefinitions={[
                            {
                                id: "name",
                                header: "Message Name",
                                cell: e => <Link href="#">{e.name}</Link>,
                                sortingField: "name",
                                isRowHeader: true
                            },
                            {
                                id: "message",
                                header: "Notice Message",
                                cell: e => e.message.length > 30 ? e.message.substring(0, 30) + "..." : e.message,
                                sortingField: "message"
                            },
                            {
                                id: "created",
                                header: "Created Time",
                                cell: e => e.created,
                                sortingField: "created"
                            },
                        ]}
                        items={items}
                        loadingText="Loading notice messages"
                        selectionType="single"
                        trackBy="name"
                        selectedItems={selectedItem ? [selectedItem] : []}
                        onSelectionChange={({ detail }) => setSelectedItem(detail.selectedItems[0])}
                        onRowClick={onRowClick}
                        empty={
                            <Box
                                margin={{ vertical: "xs" }}
                                textAlign="center"
                                color="inherit"
                            >
                                <SpaceBetween size="m">
                                    <b>No notice messages</b>
                                    <Button onClick={handleCreateClick}>Create notice message</Button>
                                </SpaceBetween>
                            </Box>
                        }
                        filter={
                            <TextFilter
                                filteringPlaceholder="Find notice messages"
                                filteringText=""
                            />
                        }
                        header={
                            <Header
                                counter={
                                    selectedItem
                                        ? "(1/" + items.length + ")"
                                        : "(" + items.length + ")"
                                }
                                actions={
                                    <SpaceBetween
                                        direction="horizontal"
                                        size="xs"
                                    >
                                        <ButtonDropdown
                                            items={[
                                                {
                                                    text: "Edit",
                                                    id: "edit",
                                                    disabled: true
                                                },
                                                {
                                                    text: "Delete",
                                                    id: "delete",
                                                    disabled: true
                                                },
                                            ]}
                                            onItemClick={onItemClick}
                                        >
                                            Actions
                                        </ButtonDropdown>
                                        <Button variant="primary" onClick={handleCreateClick}>Create Notice</Button>
                                    </SpaceBetween>
                                }
                            >
                                Notice Messages
                            </Header>
                        }
                        pagination={
                            <Pagination currentPageIndex={1} pagesCount={1} />
                        }
                        preferences={
                            <CollectionPreferences
                                title="Preferences"
                                confirmLabel="Confirm"
                                cancelLabel="Cancel"
                                preferences={{
                                    pageSize: 10,
                                    visibleContent: ["name", "message", "created"]
                                }}
                                pageSizePreference={{
                                    title: "Page size",
                                    options: [
                                        { value: 10, label: "10 messages" },
                                        { value: 20, label: "20 messages" }
                                    ]
                                }}
                            />
                        }
                    />
                </SpaceBetween>
            </AppContentLayout>
            <Modal
                onDismiss={() => setVisible(false)}
                visible={visible}
                header="Confirm deletion"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button onClick={() => setVisible(false)} variant="link">Cancel</Button>
                            <Button onClick={confirmDelete} variant="primary">Delete</Button>
                        </SpaceBetween>
                    </Box>
                }
            >
                Are you sure you want to delete the selected notice message?
            </Modal>
            <Modal
                onDismiss={() => setVisible2(false)}
                visible={visible2}
                header="Your request has been submitted."
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button onClick={() => setVisible2(false)} variant="primary">OK</Button>
                        </SpaceBetween>
                    </Box>
                }
            >
                This feature will be supported in the future.
                Thank you for your patience.
            </Modal>
        </AppLayout>
    );
}