import AppLayout from '../../../components/layout/AppLayout';
import AppContentLayout from '../../../components/layout/ContentLayout';
import {useState, useEffect} from 'react';
import SearchSideNav from "../../../components/navigation/SearchSideNavigation";
import * as React from "react";
import Select from "@cloudscape-design/components/select";
import Table from "@cloudscape-design/components/table";
import Box from "@cloudscape-design/components/box";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import TextFilter from "@cloudscape-design/components/text-filter";
import Header from "@cloudscape-design/components/header";
import Pagination from "@cloudscape-design/components/pagination";
import Cards from "@cloudscape-design/components/cards";
import {
    ColumnLayout,
    Container,
    ExpandableSection,
    Link,
    RadioGroup,
    Textarea,
    TextContent
} from "@cloudscape-design/components";
import * as PropTypes from "prop-types";

function CodeView(props) {
    return null;
}

CodeView.propTypes = {content: PropTypes.string};
export default function IndexedDocument() {
    const [activeHref, setActiveHref] = useState('/search/index/document');
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [selectedPage, setSelectedPage] = useState(1);
    const [selectedChunk, setSelectedChunk] = useState(null);

    const [indices, setIndices] = useState([
        {
            id: "1",
            label: "schdc-2024072001",
            documents: [
                {
                    id: "file-1",
                    fileName: "Employee_Handbook_2024.pdf",
                    pageCount: 3,
                    chunkCount: 12,
                    pages: [
                        {
                            id: "1",
                            chunks: [
                                {
                                    id: "1",
                                    text: "Section 1: Introduction\n\nWelcome to [Company Name]. This Employee Handbook is designed to provide you with information about the company's policies, procedures, and practices. It is important that you read, understand, and comply with all provisions of the handbook.",
                                    featureCount: 3,
                                    features: [
                                        {
                                            name: "Chunk Text (mandatory)",
                                            value: "Section 1: Introduction\n\nWelcome to [Company Name]. This Employee Handbook is designed to provide you with information about the company's policies, procedures, and practices. It is important that you read, understand, and comply with all provisions of the handbook."
                                        },
                                        {
                                            name: "Section Title",
                                            value: "Introduction"
                                        },
                                        {
                                            name: "Keywords",
                                            value: "Employee Handbook, policies, procedures, practices"
                                        }
                                    ]
                                },
                                {
                                    id: "2",
                                    text: "3.2 Compensation and Benefits\n\nThe company offers a comprehensive compensation and benefits package to eligible employees. This includes competitive salaries, health insurance, retirement plans, and paid time off. Specific details about your benefits will be provided during your onboarding process.",
                                    featureCount: 4,
                                    features: [
                                        {
                                            name: "Chunk Text (mandatory)",
                                            value: "3.2 Compensation and Benefits\n\nThe company offers a comprehensive compensation and benefits package to eligible employees. This includes competitive salaries, health insurance, retirement plans, and paid time off. Specific details about your benefits will be provided during your onboarding process."
                                        },
                                        {
                                            name: "Section Title",
                                            value: "Compensation and Benefits"
                                        },
                                        {
                                            name: "Keywords",
                                            value: "compensation, benefits, salaries, health insurance, retirement plans, paid time off"
                                        },
                                        {
                                            name: "Chunk Text Summary",
                                            value: "The company provides a comprehensive compensation and benefits package, including competitive salaries and various benefits. Details are provided during onboarding."
                                        }
                                    ]
                                },
                                {
                                    id: "3",
                                    text: "7. Confidentiality and Data Protection\n\nAll employees must maintain the confidentiality of proprietary information and trade secrets. This includes customer data, financial information, and any other sensitive information you may come across during your employment. Failure to comply with this policy may result in disciplinary action.",
                                    featureCount: 3,
                                    features: [
                                        {
                                            name: "Chunk Text (mandatory)",
                                            value: "7. Confidentiality and Data Protection\n\nAll employees must maintain the confidentiality of proprietary information and trade secrets. This includes customer data, financial information, and any other sensitive information you may come across during your employment. Failure to comply with this policy may result in disciplinary action."
                                        },
                                        {
                                            name: "Section Title",
                                            value: "Confidentiality and Data Protection"
                                        },
                                        {
                                            name: "Sentiment Analysis",
                                            value: "Neutral with a slight negative tone due to the mention of disciplinary action"
                                        }
                                    ]
                                },
                                {
                                    id: "4",
                                    text: "4.1 Work Hours\n\nStandard work hours are from 9:00 AM to 5:00 PM, Monday through Friday. Some positions may require different schedules, which will be communicated during the hiring process.",
                                    featureCount: 2,
                                    features: [
                                        {
                                            name: "Chunk Text (mandatory)",
                                            value: "4.1 Work Hours\n\nStandard work hours are from 9:00 AM to 5:00 PM, Monday through Friday. Some positions may require different schedules, which will be communicated during the hiring process."
                                        },
                                        {
                                            name: "Section Title",
                                            value: "Work Hours"
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            id: "2",
                            chunks: [
                                {
                                    id: "5",
                                    text: "5.2 Performance Reviews\n\nAnnual performance reviews will be conducted to evaluate employee performance, set goals, and discuss career development opportunities.",
                                    featureCount: 3,
                                    features: [
                                        {
                                            name: "Chunk Text (mandatory)",
                                            value: "5.2 Performance Reviews\n\nAnnual performance reviews will be conducted to evaluate employee performance, set goals, and discuss career development opportunities."
                                        },
                                        {
                                            name: "Section Title",
                                            value: "Performance Reviews"
                                        },
                                        {
                                            name: "Keywords",
                                            value: "performance reviews, employee performance, goals, career development"
                                        }
                                    ]
                                },
                                {
                                    id: "6",
                                    text: "2.3 Equal Employment Opportunity\n\n[Company Name] is an equal opportunity employer. We do not discriminate based on race, color, religion, gender, sexual orientation, national origin, age, disability, or any other protected characteristic.",
                                    featureCount: 4,
                                    features: [
                                        {
                                            name: "Chunk Text (mandatory)",
                                            value: "2.3 Equal Employment Opportunity\n\n[Company Name] is an equal opportunity employer. We do not discriminate based on race, color, religion, gender, sexual orientation, national origin, age, disability, or any other protected characteristic."
                                        },
                                        {
                                            name: "Section Title",
                                            value: "Equal Employment Opportunity"
                                        },
                                        {
                                            name: "Keywords",
                                            value: "equal opportunity, non-discrimination, protected characteristics"
                                        },
                                        {
                                            name: "Sentiment Analysis",
                                            value: "Positive, emphasizing fairness and inclusivity"
                                        }
                                    ]
                                },
                                {
                                    id: "7",
                                    text: "6.1 Dress Code\n\nEmployees are expected to maintain a professional appearance appropriate for their role and work environment. Specific dress code guidelines may vary by department.",
                                    featureCount: 2,
                                    features: [
                                        {
                                            name: "Chunk Text (mandatory)",
                                            value: "6.1 Dress Code\n\nEmployees are expected to maintain a professional appearance appropriate for their role and work environment. Specific dress code guidelines may vary by department."
                                        },
                                        {
                                            name: "Section Title",
                                            value: "Dress Code"
                                        }
                                    ]
                                },
                                {
                                    id: "8",
                                    text: "8.3 Use of Company Resources\n\nCompany resources, including computers, phones, and office supplies, should be used primarily for business purposes. Limited personal use is permitted as long as it does not interfere with job performance.",
                                    featureCount: 3,
                                    features: [
                                        {
                                            name: "Chunk Text (mandatory)",
                                            value: "8.3 Use of Company Resources\n\nCompany resources, including computers, phones, and office supplies, should be used primarily for business purposes. Limited personal use is permitted as long as it does not interfere with job performance."
                                        },
                                        {
                                            name: "Section Title",
                                            value: "Use of Company Resources"
                                        },
                                        {
                                            name: "Keywords",
                                            value: "company resources, business purposes, personal use, job performance"
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            id: "3",
                            chunks: [
                                {
                                    id: "9",
                                    text: "9.2 Grievance Procedure\n\nIf an employee has a work-related concern or complaint, they should first discuss it with their immediate supervisor. If the issue remains unresolved, they may escalate it to HR.",
                                    featureCount: 3,
                                    features: [
                                        {
                                            name: "Chunk Text (mandatory)",
                                            value: "9.2 Grievance Procedure\n\nIf an employee has a work-related concern or complaint, they should first discuss it with their immediate supervisor. If the issue remains unresolved, they may escalate it to HR."
                                        },
                                        {
                                            name: "Section Title",
                                            value: "Grievance Procedure"
                                        },
                                        {
                                            name: "Chunk Text Summary",
                                            value: "Employees should address work-related concerns with their supervisor first, then escalate to HR if unresolved."
                                        }
                                    ]
                                },
                                {
                                    id: "10",
                                    text: "10.1 Safety Policies\n\nAll employees are responsible for maintaining a safe work environment. Report any safety hazards or concerns to your supervisor or the designated safety officer immediately.",
                                    featureCount: 2,
                                    features: [
                                        {
                                            name: "Chunk Text (mandatory)",
                                            value: "10.1 Safety Policies\n\nAll employees are responsible for maintaining a safe work environment. Report any safety hazards or concerns to your supervisor or the designated safety officer immediately."
                                        },
                                        {
                                            name: "Section Title",
                                            value: "Safety Policies"
                                        }
                                    ]
                                },
                                {
                                    id: "11",
                                    text: "11.4 Travel Policy\n\nEmployees who travel for business purposes must comply with the company's travel policy, including guidelines for expenses, accommodations, and transportation.",
                                    featureCount: 2,
                                    features: [
                                        {
                                            name: "Chunk Text (mandatory)",
                                            value: "11.4 Travel Policy\n\nEmployees who travel for business purposes must comply with the company's travel policy, including guidelines for expenses, accommodations, and transportation."
                                        },
                                        {
                                            name: "Section Title",
                                            value: "Travel Policy"
                                        }
                                    ]
                                },
                                {
                                    id: "12",
                                    text: "12.2 Termination Procedures\n\nUpon termination of employment, employees must return all company property and participate in an exit interview with HR to discuss final pay and benefits information.",
                                    featureCount: 3,
                                    features: [
                                        {
                                            name: "Chunk Text (mandatory)",
                                            value: "12.2 Termination Procedures\n\nUpon termination of employment, employees must return all company property and participate in an exit interview with HR to discuss final pay and benefits information."
                                        },
                                        {
                                            name: "Section Title",
                                            value: "Termination Procedures"
                                        },
                                        {
                                            name: "Keywords",
                                            value: "termination, company property, exit interview, final pay, benefits"
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    id: "file-2",
                    fileName: "Vendor_Contract_ABC_Corp.docx",
                    pageCount: 5,
                    chunkCount: 8,
                    pages: [
                        {
                            id: "1",
                            chunks: [
                                {
                                    id: "1",
                                    text: "Article 5: Payment Terms\n\n5.1 The Client agrees to pay the Vendor the total sum of [Amount] for the services outlined in this agreement.\n5.2 Payments will be made in the following installments:\n a) 30% upon signing of this contract\n b) 40% upon completion of milestone 2\n c) 30% upon final delivery and acceptance",
                                    featureCount: 4,
                                    features: [
                                        {
                                            name: "Chunk Text (mandatory)",
                                            value: "Article 5: Payment Terms\n\n5.1 The Client agrees to pay the Vendor the total sum of [Amount] for the services outlined in this agreement.\n5.2 Payments will be made in the following installments:\n a) 30% upon signing of this contract\n b) 40% upon completion of milestone 2\n c) 30% upon final delivery and acceptance"
                                        },
                                        {
                                            name: "Section Title",
                                            value: "Payment Terms"
                                        },
                                        {
                                            name: "Keywords",
                                            value: "payment, installments, contract signing, milestone, final delivery"
                                        },
                                        {
                                            name: "Chunk Text Summary",
                                            value: "The client will pay the vendor in three installments: 30% at signing, 40% at milestone 2, and 30% upon final delivery and acceptance."
                                        }
                                    ]
                                },
                                {
                                    id: "2",
                                    text: "Article 6: Intellectual Property\n\n6.1 All intellectual property rights in the deliverables shall be vested in and be the exclusive property of the Client upon payment of all fees due under this Agreement.\n6.2 The Vendor warrants that the deliverables will not infringe upon any third-party intellectual property rights.",
                                    featureCount: 3,
                                    features: [
                                        {
                                            name: "Chunk Text (mandatory)",
                                            value: "Article 6: Intellectual Property\n\n6.1 All intellectual property rights in the deliverables shall be vested in and be the exclusive property of the Client upon payment of all fees due under this Agreement.\n6.2 The Vendor warrants that the deliverables will not infringe upon any third-party intellectual property rights."
                                        },
                                        {
                                            name: "Section Title",
                                            value: "Intellectual Property"
                                        },
                                        {
                                            name: "Keywords",
                                            value: "intellectual property rights, deliverables, exclusive property, non-infringement"
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            id: "2",
                            chunks: [
                                {
                                    id: "3",
                                    text: "Article 1: Scope of Work\n\n1.1 The Vendor agrees to provide the following services to the Client: [List of services]\n1.2 The scope of work may be modified only by written agreement of both parties.",
                                    featureCount: 3,
                                    features: [
                                        {
                                            name: "Chunk Text (mandatory)",
                                            value: "Article 1: Scope of Work\n\n1.1 The Vendor agrees to provide the following services to the Client: [List of services]\n1.2 The scope of work may be modified only by written agreement of both parties."
                                        },
                                        {
                                            name: "Section Title",
                                            value: "Scope of Work"
                                        },
                                        {
                                            name: "Chunk Text Summary",
                                            value: "The vendor will provide specified services to the client. Any changes to the scope require written agreement from both parties."
                                        }
                                    ]
                                },
                                {
                                    id: "4",
                                    text: "Article 2: Timeline\n\n2.1 The project will commence on [Start Date] and is expected to be completed by [End Date].\n2.2 The Vendor shall provide regular progress updates to the Client on a weekly basis.",
                                    featureCount: 2,
                                    features: [
                                        {
                                            name: "Chunk Text (mandatory)",
                                            value: "Article 2: Timeline\n\n2.1 The project will commence on [Start Date] and is expected to be completed by [End Date].\n2.2 The Vendor shall provide regular progress updates to the Client on a weekly basis."
                                        },
                                        {
                                            name: "Section Title",
                                            value: "Timeline"
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            id: "3",
                            chunks: [
                                {
                                    id: "5",
                                    text: "Article 3: Client Responsibilities\n\n3.1 The Client agrees to provide the Vendor with all necessary information, access, and resources required to complete the project.\n3.2 The Client shall designate a primary point of contact for the duration of the project.",
                                    featureCount: 3,
                                    features: [
                                        {
                                            name: "Chunk Text (mandatory)",
                                            value: "Article 3: Client Responsibilities\n\n3.1 The Client agrees to provide the Vendor with all necessary information, access, and resources required to complete the project.\n3.2 The Client shall designate a primary point of contact for the duration of the project."
                                        },
                                        {
                                            name: "Section Title",
                                            value: "Client Responsibilities"
                                        },
                                        {
                                            name: "Keywords",
                                            value: "client responsibilities, information, access, resources, point of contact"
                                        }
                                    ]
                                },
                                {
                                    id: "6",
                                    text: "Article 4: Confidentiality\n\n4.1 Both parties agree to keep confidential all information exchanged in relation to this project.\n4.2 This confidentiality obligation shall survive the termination of this agreement.",
                                    featureCount: 2,
                                    features: [
                                        {
                                            name: "Chunk Text (mandatory)",
                                            value: "Article 4: Confidentiality\n\n4.1 Both parties agree to keep confidential all information exchanged in relation to this project.\n4.2 This confidentiality obligation shall survive the termination of this agreement."
                                        },
                                        {
                                            name: "Section Title",
                                            value: "Confidentiality"
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            id: "4",
                            chunks: [
                                {
                                    id: "7",
                                    text: "Article 7: Termination\n\n7.1 Either party may terminate this agreement with 30 days written notice.\n7.2 In the event of termination, the Client shall pay the Vendor for all work completed up to the date of termination.",
                                    featureCount: 3,
                                    features: [
                                        {
                                            name: "Chunk Text (mandatory)",
                                            value: "Article 7: Termination\n\n7.1 Either party may terminate this agreement with 30 days written notice.\n7.2 In the event of termination, the Client shall pay the Vendor for all work completed up to the date of termination."
                                        },
                                        {
                                            name: "Section Title",
                                            value: "Termination"
                                        },
                                        {
                                            name: "Chunk Text Summary",
                                            value: "Either party can terminate the agreement with 30 days notice. The client must pay for work completed up to the termination date."
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            id: "5",
                            chunks: [
                                {
                                    id: "8",
                                    text: "Article 8: Governing Law\n\n8.1 This agreement shall be governed by and construed in accordance with the laws of [Jurisdiction].\n8.2 Any disputes arising from this agreement shall be resolved through arbitration in [City, State].",
                                    featureCount: 3,
                                    features: [
                                        {
                                            name: "Chunk Text (mandatory)",
                                            value: "Article 8: Governing Law\n\n8.1 This agreement shall be governed by and construed in accordance with the laws of [Jurisdiction].\n8.2 Any disputes arising from this agreement shall be resolved through arbitration in [City, State]."
                                        },
                                        {
                                            name: "Section Title",
                                            value: "Governing Law"
                                        },
                                        {
                                            name: "Keywords",
                                            value: "governing law, jurisdiction, disputes, arbitration"
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            id: "2",
            label: "schdc-2024071814",
            documents: [
                {
                    id: "file-1",
                    fileName: "Office_Supplies_Inventory_Q2.xlsx",
                    pageCount: 3,
                    chunkCount: 5,
                    pages: [
                        {
                            id: "1",
                            chunks: [
                                {
                                    id: "1",
                                    text: "Inventory List - Office Supplies\n\n1. Printer Paper: 50 reams\n2. Ballpoint Pens: 200 (100 black, 50 blue, 50 red)\n3. Staplers: 25\n4. Staples: 50 boxes\n5. Highlighters: 100 (assorted colors)\n6. Notebooks: 75 (spiral bound, A4 size)",
                                    featureCount: 3,
                                    features: [
                                        {
                                            name: "Chunk Text (mandatory)",
                                            value: "Inventory List - Office Supplies\n\n1. Printer Paper: 50 reams\n2. Ballpoint Pens: 200 (100 black, 50 blue, 50 red)\n3. Staplers: 25\n4. Staples: 50 boxes\n5. Highlighters: 100 (assorted colors)\n6. Notebooks: 75 (spiral bound, A4 size)"
                                        },
                                        {
                                            name: "Section Title",
                                            value: "Inventory List - Office Supplies"
                                        },
                                        {
                                            name: "Keywords",
                                            value: "printer paper, ballpoint pens, staplers, staples, highlighters, notebooks"
                                        }
                                    ]
                                },
                                {
                                    id: "2",
                                    text: "Supply Usage Report - Q2\n\nPrinter Paper: 30 reams used\nBallpoint Pens: 75 used\nStaples: 15 boxes used\nHighlighters: 40 used\nNotebooks: 25 used",
                                    featureCount: 2,
                                    features: [
                                        {
                                            name: "Chunk Text (mandatory)",
                                            value: "Supply Usage Report - Q2\n\nPrinter Paper: 30 reams used\nBallpoint Pens: 75 used\nStaples: 15 boxes used\nHighlighters: 40 used\nNotebooks: 25 used"
                                        },
                                        {
                                            name: "Section Title",
                                            value: "Supply Usage Report - Q2"
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            id: "2",
                            chunks: [
                                {
                                    id: "3",
                                    text: "Reorder Alert\n\nThe following items are below the minimum stock level and need to be reordered:\n1. Printer Paper\n2. Staples\n3. Highlighters",
                                    featureCount: 2,
                                    features: [
                                        {
                                            name: "Chunk Text (mandatory)",
                                            value: "Reorder Alert\n\nThe following items are below the minimum stock level and need to be reordered:\n1. Printer Paper\n2. Staples\n3. Highlighters"
                                        },
                                        {
                                            name: "Section Title",
                                            value: "Reorder Alert"
                                        }
                                    ]
                                },
                                {
                                    id: "4",
                                    text: "Cost Analysis\n\nTotal spend on office supplies in Q2: 2,500\nHighest expense category: Printer Paper (800)\nLowest expense category: Staplers (50)",
                                    featureCount: 3,
                                    features: [
                                        {
                                            name: "Chunk Text (mandatory)",
                                            value: "Cost Analysis\n\nTotal spend on office supplies in Q2: 2,500\nHighest expense category: Printer Paper (800)\nLowest expense category: Staplers (50)"
                                        },
                                        {
                                            name: "Section Title",
                                            value: "Cost Analysis"
                                        },
                                        {
                                            name: "Chunk Text Summary",
                                            value: "Q2 office supplies spend was 2,500. Printer paper was the highest expense at 800, while staplers were the lowest at 50."
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            id: "3",
                            chunks: [
                                {
                                    id: "5",
                                    text: "Supplier Information\n\nMain Office Supply Vendor: OfficeMax\nContact: John Doe (john.doe@officemax.com)\nAccount Number: 12345-ABC\nPreferred shipping method: Ground",
                                    featureCount: 2,
                                    features: [
                                        {
                                            name: "Chunk Text (mandatory)",
                                            value: "Supplier Information\n\nMain Office Supply Vendor: OfficeMax\nContact: John Doe (john.doe@officemax.com)\nAccount Number: 12345-ABC\nPreferred shipping method: Ground"
                                        },
                                        {
                                            name: "Section Title",
                                            value: "Supplier Information"
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    id: "file-2",
                    fileName: "Recruitment_Process_Guidelines.pdf",
                    pageCount: 2,
                    chunkCount: 5,
                    pages: [
                        {
                            id: "1",
                            chunks: [
                                {
                                    id: "1",
                                    text: "1. Job Posting\n\nAll open positions must be posted on the company website and relevant job boards for a minimum of two weeks before the application deadline. The job posting should include a clear job description, required qualifications, and instructions on how to apply.",
                                    featureCount: 3,
                                    features: [
                                        {
                                            name: "Chunk Text (mandatory)",
                                            value: "1. Job Posting\n\nAll open positions must be posted on the company website and relevant job boards for a minimum of two weeks before the application deadline. The job posting should include a clear job description, required qualifications, and instructions on how to apply."
                                        },
                                        {
                                            name: "Section Title",
                                            value: "Job Posting"
                                        },
                                        {
                                            name: "Keywords",
                                            value: "job posting, company website, job boards, job description, qualifications, application instructions"
                                        }
                                    ]
                                },
                                {
                                    id: "2",
                                    text: "3. Interview Process\n\nThe interview process typically consists of three stages:\n1. Initial phone screening\n2. Technical or skills assessment\n3. In-person or video interview with the hiring manager and team\n\nEach stage should be documented using the standard evaluation forms provided by HR.",
                                    featureCount: 3,
                                    features: [
                                        {
                                            name: "Chunk Text (mandatory)",
                                            value: "3. Interview Process\n\nThe interview process typically consists of three stages:\n1. Initial phone screening\n2. Technical or skills assessment\n3. In-person or video interview with the hiring manager and team\n\nEach stage should be documented using the standard evaluation forms provided by HR."
                                        },
                                        {
                                            name: "Section Title",
                                            value: "Interview Process"
                                        },
                                        {
                                            name: "Chunk Text Summary",
                                            value: "The interview process has three stages: phone screening, skills assessment, and in-person/video interview. All stages must be documented using HR-provided forms."
                                        }
                                    ]
                                },
                                {
                                    id: "3",
                                    text: "2. Application Review\n\nHR will conduct an initial screening of all applications to ensure candidates meet the minimum qualifications. Qualified candidates will be shortlisted and their resumes forwarded to the hiring manager for review.",
                                    featureCount: 2,
                                    features: [
                                        {
                                            name: "Chunk Text (mandatory)",
                                            value: "2. Application Review\n\nHR will conduct an initial screening of all applications to ensure candidates meet the minimum qualifications. Qualified candidates will be shortlisted and their resumes forwarded to the hiring manager for review."
                                        },
                                        {
                                            name: "Section Title",
                                            value: "Application Review"
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            id: "2",
                            chunks: [
                                {
                                    id: "4",
                                    text: "4. Reference Checks\n\nAt least two professional references must be checked for the final candidate before extending a job offer. Reference checks should be conducted by HR using the standard reference check form.",
                                    featureCount: 2,
                                    features: [
                                        {
                                            name: "Chunk Text (mandatory)",
                                            value: "4. Reference Checks\n\nAt least two professional references must be checked for the final candidate before extending a job offer. Reference checks should be conducted by HR using the standard reference check form."
                                        },
                                        {
                                            name: "Section Title",
                                            value: "Reference Checks"
                                        }
                                    ]
                                },
                                {
                                    id: "5",
                                    text: "5. Job Offer\n\nOnce a final candidate is selected, HR will prepare the job offer letter including details on salary, benefits, start date, and any other relevant terms of employment. The offer must be approved by the department head before being sent to the candidate.",
                                    featureCount: 3,
                                    features: [
                                        {
                                            name: "Chunk Text (mandatory)",
                                            value: "5. Job Offer\n\nOnce a final candidate is selected, HR will prepare the job offer letter including details on salary, benefits, start date, and any other relevant terms of employment. The offer must be approved by the department head before being sent to the candidate."
                                        },
                                        {
                                            name: "Section Title",
                                            value: "Job Offer"
                                        },
                                        {
                                            name: "Keywords",
                                            value: "job offer, salary, benefits, start date, employment terms, department head approval"
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            id: "3",
            label: "schdc-2024071101",
            documents: [
                {
                    id: "file-1",
                    fileName: "NDA_Template.docx",
                    pageCount: 3,
                    chunkCount: 8,
                    pages: [
                        {
                            id: "1",
                            chunks: [
                                {
                                    id: "1",
                                    text: "1. Definition of Confidential Information\n\n'Confidential Information' means any information disclosed by one party (the 'Disclosing Party') to the other party (the 'Receiving Party'), either directly or indirectly, in writing, orally or by inspection of tangible objects, which is designated as 'Confidential,' 'Proprietary' or some similar designation.",
                                    featureCount: 3,
                                    features: [
                                        {
                                            name: "Chunk Text (mandatory)",
                                            value: "1. Definition of Confidential Information\n\n'Confidential Information' means any information disclosed by one party (the 'Disclosing Party') to the other party (the 'Receiving Party'), either directly or indirectly, in writing, orally or by inspection of tangible objects, which is designated as 'Confidential,' 'Proprietary' or some similar designation."
                                        },
                                        {
                                            name: "Section Title",
                                            value: "Definition of Confidential Information"
                                        },
                                        {
                                            name: "Keywords",
                                            value: "confidential information, disclosing party, receiving party, proprietary"
                                        }
                                    ]
                                },
                                {
                                    id: "2",
                                    text: "4. Term\n\nThe obligations and restrictions of this Agreement shall be effective for a period of five (5) years from the Effective Date, regardless of any earlier termination of the business relationship between the parties.",
                                    featureCount: 2,
                                    features: [
                                        {
                                            name: "Chunk Text (mandatory)",
                                            value: "4. Term\n\nThe obligations and restrictions of this Agreement shall be effective for a period of five (5) years from the Effective Date, regardless of any earlier termination of the business relationship between the parties."
                                        },
                                        {
                                            name: "Section Title",
                                            value: "Term"
                                        }
                                    ]
                                },
                                {
                                    id: "3",
                                    text: "2. Non-Disclosure\n\nThe Receiving Party agrees not to use any Confidential Information for any purpose except to evaluate and engage in discussions concerning a potential business relationship between the parties.",
                                    featureCount: 2,
                                    features: [
                                        {
                                            name: "Chunk Text (mandatory)",
                                            value: "2. Non-Disclosure\n\nThe Receiving Party agrees not to use any Confidential Information for any purpose except to evaluate and engage in discussions concerning a potential business relationship between the parties."
                                        },
                                        {
                                            name: "Section Title",
                                            value: "Non-Disclosure"
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            id: "2",
                            chunks: [
                                {
                                    id: "4",
                                    text: "3. Exceptions\n\nThe Receiving Party shall not be obligated under this Agreement with respect to information that: (a) was already known to the Receiving Party at the time of disclosure; (b) becomes publicly known through no wrongful act of the Receiving Party.",
                                    featureCount: 3,
                                    features: [
                                        {
                                            name: "Chunk Text (mandatory)",
                                            value: "3. Exceptions\n\nThe Receiving Party shall not be obligated under this Agreement with respect to information that: (a) was already known to the Receiving Party at the time of disclosure; (b) becomes publicly known through no wrongful act of the Receiving Party."
                                        },
                                        {
                                            name: "Section Title",
                                            value: "Exceptions"
                                        },
                                        {
                                            name: "Chunk Text Summary",
                                            value: "The agreement doesn't apply to information already known to the receiving party or that becomes public without their fault."
                                        }
                                    ]
                                },
                                {
                                    id: "5",
                                    text: "5. Return of Materials\n\nAll documents and other tangible objects containing or representing Confidential Information and all copies thereof which are in the possession of the Receiving Party shall be and remain the property of the Disclosing Party and shall be promptly returned to the Disclosing Party upon request.",
                                    featureCount: 3,
                                    features: [
                                        {
                                            name: "Chunk Text (mandatory)",
                                            value: "5. Return of Materials\n\nAll documents and other tangible objects containing or representing Confidential Information and all copies thereof which are in the possession of the Receiving Party shall be and remain the property of the Disclosing Party and shall be promptly returned to the Disclosing Party upon request."

                                        },
                                        {
                                            name: "Section Title",
                                            value: "Return of Materials"
                                        },
                                        {
                                            name: "Keywords",
                                            value: "documents, tangible objects, confidential information, property, return"
                                        }
                                    ]
                                },
                                {
                                    id: "6",
                                    text: "6. No Rights Granted\n\nNothing in this Agreement shall be construed as granting any rights under any patent, copyright or other intellectual property right of the Disclosing Party, nor shall this Agreement grant the Receiving Party any rights in or to the Disclosing Party's Confidential Information.",
                                    featureCount: 3,
                                    features: [
                                        {
                                            name: "Chunk Text (mandatory)",
                                            value: "6. No Rights Granted\n\nNothing in this Agreement shall be construed as granting any rights under any patent, copyright or other intellectual property right of the Disclosing Party, nor shall this Agreement grant the Receiving Party any rights in or to the Disclosing Party's Confidential Information."
                                        },
                                        {
                                            name: "Section Title",
                                            value: "No Rights Granted"
                                        },
                                        {
                                            name: "Keywords",
                                            value: "rights, patent, copyright, intellectual property, confidential information"
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            id: "3",
                            chunks: [
                                {
                                    id: "7",
                                    text: "7. Governing Law\n\nThis Agreement shall be governed by and construed in accordance with the laws of [State/Country], without giving effect to any choice of law or conflict of law provisions.",
                                    featureCount: 2,
                                    features: [
                                        {
                                            name: "Chunk Text (mandatory)",
                                            value: "7. Governing Law\n\nThis Agreement shall be governed by and construed in accordance with the laws of [State/Country], without giving effect to any choice of law or conflict of law provisions."
                                        },
                                        {
                                            name: "Section Title",
                                            value: "Governing Law"
                                        }
                                    ]
                                },
                                {
                                    id: "8",
                                    text: "8. Entire Agreement\n\nThis Agreement constitutes the entire agreement between the parties with respect to the subject matter hereof and supersedes all prior agreements, understandings and negotiations, both written and oral, between the parties with respect to the subject matter hereof.",
                                    featureCount: 3,
                                    features: [
                                        {
                                            name: "Chunk Text (mandatory)",
                                            value: "8. Entire Agreement\n\nThis Agreement constitutes the entire agreement between the parties with respect to the subject matter hereof and supersedes all prior agreements, understandings and negotiations, both written and oral, between the parties with respect to the subject matter hereof."
                                        },
                                        {
                                            name: "Section Title",
                                            value: "Entire Agreement"
                                        },
                                        {
                                            name: "Chunk Text Summary",
                                            value: "This agreement is the complete understanding between parties and replaces all previous agreements on this subject."
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ]);

    const handleFollow = (event) => {
        if (!event.detail.external) {
            event.preventDefault();
            setActiveHref(event.detail.href);
        }
    };

    const handleIndexChange = ({detail}) => {
        setSelectedIndex(detail.selectedOption);
        setSelectedDocument(null);
        setSelectedChunk(null);
    };

    const handleDocumentSelect = (item) => {
        setSelectedDocument(item);
        setSelectedPage(1);
        setSelectedChunk(null);
    };

    const handlePageChange = (pageNumber) => {
        setSelectedPage(pageNumber);
        setSelectedChunk(null);
    };

    const handleChunkSelect = (chunk) => {
        setSelectedChunk(chunk);
    };

    const documentsToShow = selectedIndex ? indices.find(index => index.id === selectedIndex.value)?.documents || [] : [];
    const pagesToShow = selectedDocument ? selectedDocument.pages : [];
    const chunksToShow = selectedDocument && selectedPage ? selectedDocument.pages[selectedPage - 1]?.chunks || [] : [];

    return (
        <AppLayout
            breadcrumbs_props={{
                items: [
                    {text: 'Search', href: '/search/index/indices'},
                    {text: 'Search Tool', href: '/search/index/document'},
                    {text: 'Documents', href: '/search/index/document'},
                ]
            }}
            side_nav={
                <SearchSideNav
                    activeHref={activeHref}
                    onFollow={handleFollow}>
                </SearchSideNav>
            }
        >
            <AppContentLayout
                header_layout_props={{
                    text: "Documents",
                    description: "View and search indexed documents"
                }}
            >
                <SpaceBetween size="l">
                    <Container>
                        <SpaceBetween size="l">
                            <Header variant="h2">Select Index</Header>
                            <Select
                                selectedOption={selectedIndex}
                                onChange={handleIndexChange}
                                options={indices.map(index => ({label: index.label, value: index.id}))}
                                placeholder="Choose an index"
                            />
                            {!selectedIndex &&
                                <TextContent>
                                    <small>
                                        Select an index, you can choose the desired file and document for analysis
                                    </small>
                                </TextContent>
                            }
                            {selectedIndex && (
                                <Table
                                    onSelectionChange={({detail}) =>
                                        handleDocumentSelect(detail.selectedItems[0])
                                    }
                                    selectedItems={selectedDocument ? [selectedDocument] : []}
                                    onRowClick={({ detail }) => handleDocumentSelect(detail.item)}
                                    ariaLabels={{
                                        selectionGroupLabel: "Items selection",
                                        allItemsSelectionLabel: ({selectedItems}) =>
                                            `${selectedItems.length} ${
                                                selectedItems.length === 1 ? "item" : "items"
                                            } selected`,
                                        itemSelectionLabel: ({selectedItems}, item) =>
                                            item.fileName
                                    }}
                                    columnDefinitions={[
                                        {
                                            id: "fileName",
                                            header: "File Name",
                                            cell: item => item.fileName,
                                            sortingField: "fileName"
                                        },
                                        {
                                            id: "pageCount",
                                            header: "Page Count",
                                            cell: item => item.pageCount
                                        },
                                        {
                                            id: "chunkCount",
                                            header: "Chunk Count",
                                            cell: item => item.chunkCount
                                        }
                                    ]}
                                    items={documentsToShow}
                                    selectionType="single"
                                    trackBy="id"
                                    variant="embedded"
                                    empty={
                                        <Box textAlign="center" color="inherit">
                                            <b>No documents</b>
                                            <Box padding={{bottom: "s"}} variant="p" color="inherit">
                                                No documents to display.
                                            </Box>
                                        </Box>
                                    }
                                    filter={
                                        <TextFilter
                                            filteringPlaceholder="Find documents"
                                            filteringText=""
                                        />
                                    }
                                    header={
                                        <Header counter={`(${documentsToShow.length})`}>
                                            {selectedIndex ? `Documents - ${selectedIndex.label}` : 'Documents'}
                                        </Header>
                                    }
                                />
                            )}
                        </SpaceBetween>
                    </Container>

                    <ColumnLayout columns={selectedChunk ? 2 : 1}>
                        {selectedDocument && (
                            <Container>
                                <SpaceBetween size="m">
                                    <Header variant="h2">{`Pages - ${selectedDocument.fileName}`}</Header>
                                    <SpaceBetween size="m">
                                        <Box>
                                            <Pagination
                                                currentPageIndex={selectedPage}
                                                onChange={({detail}) => handlePageChange(detail.currentPageIndex)}
                                                pagesCount={selectedDocument.pageCount}
                                            />
                                        </Box>
                                        <Cards
                                            onSelectionChange={({detail}) =>
                                                handleChunkSelect(detail.selectedItems[0])
                                            }
                                            selectedItems={selectedChunk ? [selectedChunk] : []}
                                            entireCardClickable
                                            ariaLabels={{
                                                itemSelectionLabel: (e, t) => `select ${t.id}`,
                                                selectionGroupLabel: "Chunk selection"
                                            }}
                                            cardDefinition={{
                                                header: item => `Chunk ${item.id}`,
                                                sections: [
                                                    {
                                                        id: "description",
                                                        header: "Chunk Text (Mandetory)",
                                                        content: item => item.text.substring(0, 100) + "..."
                                                    },
                                                    {
                                                        id: "type",
                                                        header: "Ranking Feature",
                                                        content: item => item.featureCount + ' Features'
                                                    }
                                                ]
                                            }}
                                            cardsPerRow={[
                                                {cards: 1},
                                                {minWidth: 500, cards: 2}
                                            ]}
                                            items={chunksToShow}
                                            loadingText="Loading chunks"
                                            selectionType="single"
                                            trackBy="id"
                                            empty={
                                                <Box textAlign="center" color="inherit">
                                                    <b>No chunks</b>
                                                    <Box padding={{bottom: "s"}} variant="p" color="inherit">
                                                        No chunks to display.
                                                    </Box>
                                                </Box>
                                            }
                                        />
                                    </SpaceBetween>
                                </SpaceBetween>
                            </Container>
                        )}

                        {selectedChunk && (
                            <ExpandableSection
                                defaultExpanded
                                variant="container"
                                headerText={`Chunk ${selectedChunk.id} - Details`}
                            >
                                <SpaceBetween size="m">
                                    {selectedChunk.features.map((feature, index) => (
                                        <Container
                                            key={index}
                                            header={<Header variant="h4">Ranking Feature {index+1} - {feature.name}</Header>}
                                            >
                                            {feature.value}
                                        </Container>
                                    ))}

                                    <Container
                                        header={<Header variant="h4">Anchor Text</Header>}
                                    >
                                        <SpaceBetween size="m">
                                            <Textarea
                                                rows={5}
                                                value={""}
                                                placeholder="This field is for adding textual information that is not in the body-chunk. It is used as if it were text in the original body, and is factored into the ranking."
                                            />
                                            <Button variant="primary">Apply</Button>
                                        </SpaceBetween>
                                    </Container>
                                </SpaceBetween>

                            </ExpandableSection>
                        )}
                    </ColumnLayout>
                </SpaceBetween>
            </AppContentLayout>
        </AppLayout>
    );
}