import AppLayout from '../../../../components/layout/AppLayout';
import AppContentLayout from '../../../../components/layout/ContentLayout';
import * as React from 'react';
import {useState, useEffect} from 'react';
import SearchSideNav from "../../../../components/navigation/SearchSideNavigation";
import {Container, FormField, Header, Input, Select, Tiles, Multiselect, Modal} from "@cloudscape-design/components";
import * as PropTypes from "prop-types";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import { useLocation } from 'react-router-dom';
import Box from "@cloudscape-design/components/box";

function InfoLink(props) {
    return null;
}

InfoLink.propTypes = {
    onFollow: PropTypes.func,
    id: PropTypes.string
};

export default function AIModelCreation() {
    const [activeHref, setActiveHref] = useState('/search/rankingmodel/ai/create');
    const [nameValue, setNameValue] = React.useState("");
    const [selectedOption, setSelectedOption] = React.useState(null);
    const [selectedTile, setSelectedTile] = React.useState("provided");
    const [selectedTextFeatures, setSelectedTextFeatures] = React.useState(['Chunk Text (mandatory)']);
    const [selectedEmbeddingFeatures, setSelectedEmbeddingFeatures] = React.useState(['Chunk Text Embedding (mandatory)']);
    const [visible, setVisible] = React.useState(false);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    useEffect(() => {
        setNameValue(queryParams.get('name') || "");
        const datasource = queryParams.get('datasource');
        if (datasource) {
            setSelectedOption({
                label: datasource,
                value: datasource,
                description: ""
            });
        }
        const modelType = queryParams.get('modelType');
        if (modelType === "Customize Model") {
            setSelectedTile("customized");
        }
        const textFeatures = queryParams.get('textFeatures');
        if (textFeatures) {
            const textFeaturesArray = textFeatures.split(',').map(feature => feature.trim());
            setSelectedTextFeatures(textFeaturesArray);
        }
        const embeddingFeatures = queryParams.get('embeddingFeatures');
        if (embeddingFeatures) {
            const embeddingFeaturesArray = embeddingFeatures.split(',').map(feature => feature.trim());
            setSelectedEmbeddingFeatures(embeddingFeaturesArray);
        }
    }, []);

    const handleFollow = (event) => {
        if (!event.detail.external) {
            event.preventDefault();
            setActiveHref(event.detail.href);
        }
    };

    const handleAddNewModel = () => {
        setVisible(true);
    };

    const providedModels = [
        {
            label: "SEARCHDOC-E-COMMERCE-V1.0",
            value: "1",
            description: "created based on features specific to e-commerce operations. Includes functionalities for product catalogs, order processing, and customer management."
        },
        {
            label: "SEARCHDOC-COMMON-V1.0",
            value: "1",
            description: "created based on features that you'll use a lot in your day-to-day work. Can be utilized based on universal datasets."
        },
        {
            label: "SEARCHDOC-HR-V1.1",
            value: "2",
            description: "created based on a dataset from internal HR. Reflects featuresets specialized for HR information."
        },
        {
            label: "SEARCHDOC-HR-V1.0",
            value: "3",
            description: "created based on a dataset from internal HR. Reflects featuresets specialized for HR information."
        },
    ];

    const customizedTextModels = [
        {
            label: "Basic Text Features",
            options: [
                {
                    label: 'Chunk Text (mandatory)',
                    value: 'Chunk Text (mandatory)',
                    description: 'Text - Original text content',
                },
                {
                    label: 'Chunk Text English Translation',
                    value: 'Chunk Text English Translation',
                    description: 'Text - Translates the document content to English',
                },
                {
                    label: 'Chunk Text Summary',
                    value: 'Chunk Text Summary',
                    description: 'Text - Generates an inline summary of the document content',
                },
                {
                    label: 'Keywords',
                    value: 'Keywords',
                    description: 'Text - Extracts key keywords from the document',
                },
            ]
        },
        {
            label: "Document Structure",
            options: [
                {
                    label: 'Section Title',
                    value: 'Section Title',
                    description: 'Text - Extracts or generates titles for document sections',
                },
                {
                    label: 'Topic Modeling',
                    value: 'Topic Modeling',
                    description: 'Text - Identifies and classifies main topics in the document',
                },
                {
                    label: 'Each Page Summary',
                    value: 'Each Page Summary',
                    description: 'Text - Generates an overall summary of the document page',
                },
                {
                    label: 'Document First-10 Lines Summary',
                    value: 'Document First-10 Lines Summary',
                    description: '',
                },
                {
                    label: 'Document First-3 Pages Summary',
                    value: 'Document First-3 Pages Summary',
                    description: '',
                },
                {
                    label: 'Document Title Structure',
                    value: 'Document Title Structure',
                    description: 'Text - Analyzes structural elements of the document (titles, paragraphs, lists, etc.)',
                },
                {
                    label: 'Sentiment Analysis',
                    value: 'Sentiment Analysis',
                    description: 'Text - Analyzes and evaluates the sentiment of the document content',
                },
            ]
        },
        {
            label: "E-Commerce",
            options: [
                {
                    label: 'Entity Recognition',
                    value: 'Entity Recognition',
                    description: 'Text - Identifies important entities (people, places, organizations, etc.) in the document',
                },
                {
                    label: 'Product Name Recognition',
                    value: 'Product Name Recognition',
                    description: '',
                },
                {
                    label: 'Price Recognition',
                    value: 'Price Recognition',
                    description: '',
                },
                {
                    label: 'Product Category Recognition',
                    value: 'Product Category Recognition',
                    description: '',
                },
            ]
        },
        {
            label: "Image Related Analysis",
            options: [
                {
                    label: 'Image Analysis',
                    value: 'Image Analysis',
                    description: 'Text - Analyzes content and features of images in the document',
                },
                {
                    label: 'Chunk Text OCR',
                    value: 'Chunk Text OCR',
                    description: 'Text - Recognizes and extracts text from images',
                },
            ]
        },
    ];

    const customizedEmbeddingModels = [
        {
            label: "Basic Embeddings",
            options: [
                {
                    label: 'Chunk Text Embedding (mandatory)',
                    value: 'Chunk Text Embedding (mandatory)',
                    description: 'Embeddings - Embeddings for the original text',
                },
                {
                    label: 'Chunk Text Summary Embedding',
                    value: 'Chunk Text Summary Embedding',
                    description: 'Embeddings - Embeddings for the inline summary',
                },
                {
                    label: 'Chunk Text English Translation',
                    value: 'Chunk Text English Translation',
                    description: 'Embeddings - Embeddings for the English translated text',
                },
            ]
        },
        {
            label: "Advanced Analysis Embeddings",
            options: [
                {
                    label: 'Topic Modeling Embedding',
                    value: 'Topic Modeling Embedding',
                    description: 'Embeddings - Embeddings for the topic modeling results',
                },
                {
                    label: 'Sentiment Analysis Embedding',
                    value: 'Sentiment Analysis Embedding',
                    description: 'Embeddings - Embeddings for sentiment analysis results',
                },
                {
                    label: 'Entity Recognition Embedding',
                    value: 'Entity Recognition Embedding',
                    description: 'Embeddings - Embeddings for recognized entities',
                },
            ]
        },
    ];

    return (
        <AppLayout
            breadcrumbs_props={{
                items: [
                    {text: 'Search', href: '/search/index/indices'},
                    {text: 'Ranking Model', href: '/search/rankingmodel/models'},
                    {text: 'AI Model', href: '/search/rankingmodel/ai'},
                    {text: 'Create', href: '/search/rankingmodel/ai/create'},
                ]
            }}
            side_nav={
                <SearchSideNav
                    activeHref={activeHref}
                    onFollow={handleFollow}>
                </SearchSideNav>}
        >
            <AppContentLayout
                header_layout_props={{
                    text: "AI Model",
                    description: "Powered by Learning To Rank, which allows you to train and utilize AI ranking models."
                }}
            >
                <SpaceBetween size="l">
                    <Container
                        header={
                            <Header
                                variant="h2"
                                actions={
                                    <SpaceBetween
                                        direction="horizontal"
                                        size="xs"
                                    >
                                        <Button variant="primary" onClick={handleAddNewModel}>Save</Button>
                                        <Button href="/search/rankingmodel/ai">Cancel</Button>
                                    </SpaceBetween>
                                }>
                                {queryParams.get('name') ? "Edit Ranking Model" : "New Ranking Model"}
                            </Header>
                        }>
                        <SpaceBetween size="xl">
                            <FormField
                                label={
                                    <>
                                        Model Name
                                    </>
                                }
                            >
                                <Input
                                    onChange={({detail}) => setNameValue(detail.value)}
                                    value={nameValue}
                                    placeholder="Enter Model Name"
                                />
                            </FormField>
                            <FormField
                                label="Model Type"
                            >
                                <Tiles
                                    onChange={({ detail }) => setSelectedTile(detail.value)}
                                    value={selectedTile}
                                    items={[
                                        {
                                            label: "Provided Models",
                                            description: "Use pre-trained models",
                                            value: "provided"
                                        },
                                        {
                                            label: "Customize Model",
                                            description: "Create your own model",
                                            value: "customized"
                                        }
                                    ]}
                                />
                            </FormField>
                            {selectedTile === "provided" ? (
                                <FormField
                                    label="AI Ranking Model"
                                    constraintText={selectedOption ? "'" + selectedOption.label + "' Model has been " + selectedOption.description : ""}
                                >
                                    <Select
                                        selectedOption={selectedOption}
                                        onChange={({detail}) =>
                                            setSelectedOption(detail.selectedOption)
                                        }
                                        options={providedModels}
                                        selectedAriaLabel="Selected"
                                        placeholder="Supported AI Model"
                                    />
                                </FormField>
                            ) : (
                                <>
                                    <FormField
                                        label="Provided Text Features"
                                    >
                                        <Multiselect
                                            selectedOptions={selectedTextFeatures.map(feature => ({
                                                label: feature,
                                                value: feature
                                            }))}
                                            onChange={({detail}) =>
                                                setSelectedTextFeatures([
                                                    'Chunk Text (mandatory)',
                                                    ...detail.selectedOptions
                                                        .filter(option => option.value !== 'Chunk Text (mandatory)')
                                                        .map(option => option.value)
                                                ])
                                            }
                                            options={customizedTextModels}
                                            selectedAriaLabel="Selected"
                                            placeholder="Select Provided Text Features"
                                        />
                                    </FormField>
                                    <FormField
                                        label="Provided Embedding Features"
                                    >
                                        <Multiselect
                                            selectedOptions={selectedEmbeddingFeatures.map(feature => ({
                                                label: feature,
                                                value: feature
                                            }))}
                                            onChange={({detail}) =>
                                                setSelectedEmbeddingFeatures([
                                                    'Chunk Text Embedding (mandatory)',
                                                    ...detail.selectedOptions
                                                        .filter(option => option.value !== 'Chunk Text Embedding (mandatory)')
                                                        .map(option => option.value)
                                                ])
                                            }
                                            options={customizedEmbeddingModels}
                                            selectedAriaLabel="Selected"
                                            placeholder="Select Provided Embedding Features"
                                        />
                                    </FormField>
                                </>
                            )}
                        </SpaceBetween>
                    </Container>
                </SpaceBetween>
                <Modal
                    onDismiss={() => setVisible(false)}
                    visible={visible}
                    header="Your request has been submitted."
                    footer={
                        <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button onClick={() => setVisible(false)} variant="primary">OK</Button>
                            </SpaceBetween>
                        </Box>
                    }
                >
                    This feature will be supported in the future.
                    Thank you for your patience.
                </Modal>
            </AppContentLayout>
        </AppLayout>
    );
}